import { Button, Tabs } from 'antd';
import './airdrop.scss'
import { connect as reducxConnect } from 'react-redux'
import { useState } from 'react';
import tw from '../../../assets/image/airdrop/twitter.png'
import normal from '../../../assets/image/airdrop/normal.png'
import orange from '../../../assets/image/airdrop/orange.png'
import purple from '../../../assets/image/airdrop/purple.png'
import green from '../../../assets/image/airdrop/green.png'

import normal_active from '../../../assets/image/airdrop/normal_active.png'
import orange_active from '../../../assets/image/airdrop/orange_active.png'
import purple_active from '../../../assets/image/airdrop/purple_active.png'
import green_active from '../../../assets/image/airdrop/green_active.png'

import arrow from '../../../assets/image/airdrop/arrowb.svg'

import Modal from '../../../components/common/Modal'


const packageList = [
    {
        image: normal,
        image_active: normal_active,
        condition: 'Invite 5 friends',
        text: <span>Invite 5 friends to follow Harwell on Twitter and enter Discord to get 1  <span className="fwb">normal Care package</span></span>
    },
    {
        image: orange,
        image_active: orange_active,
        condition: 'Invite 10 friends',
        text: <span>Invite 10 friends to follow Harwell Twitter and enter Discord to receive an  <span className="fwb">orange Care package </span></span>
    },
    {
        image: purple,
        image_active: purple_active,
        condition: 'Invite 20 friends',
        text: <span>Invite 20 friends to follow Harwell on Twitter and enter Discord to get 1  <span className="fwb">normal Care package</span></span>
    },
    {
        image: green,
        image_active: green_active,
        condition: 'Invite 40 friends',
        text: <span>Invite 40 friends to follow Harwell Twitter and enter Discord to receive a  <span className="fwb">green Care package</span></span>
    },
]
const Airdrop1 = (props) => {
    let [tabvalue, setTabvalue] = useState('1')
    let [activeIndex, setActiveIndex] = useState(0)
    let [showDetail, setShowDetail] = useState(false)
    return (
        <div className='airdrop-item w100'>
            <div className="middle-content p-t-60 p-b-150">
                <div className="fz-36 fwb ta">$HARW Token Airdrop 2</div>
                <div className="fz-20 ta text-content p-t-24">
                Airdrop2 first round 
                    <span className='fwb'> Aptos Monkeys, Boys Adventure, Pontem Space Pirates, Bruh Bears, Aptos Dragons, Nevermores, MAVRIK, Lemur Lounge, Aptos Toad Overload </span>
                    , NFT Holder can randomly draw 1 Harwell care package
                </div>
                <div className="flex flex-center flex-column w100">
                    <div className="airdrop-btn fz-18 fwb ta pointer m-t-48">
                    I ‘m NFT Holder
                    </div>
                    <div className="ta w100 fz-24 fwb m-t-100">
                    $HARE Token AIRDROP 2 Rule Description
                    </div>
                    <div className="ta text-content fz-20 p-t-20">
                    The blue label NFT Holder of Aptos ecology can randomly draw 1 Harwell care package (the total transaction volume of NFT is greater than 10,000 APT)
                    </div>

                    <div className="care-items flex m-t-36">
                        {
                            packageList.map((item, index) => {
                                return (
                                    <div className='flex flex-start'>
                                        <div className="care-item flex-1 flex flex-center flex-column" onClick={()=> {
                                        setActiveIndex(index);
                                        setShowDetail(true)
                                    }}>
                                        <img src={item.image} alt="" className='care-image' />
                                        <div className="w100 fz-20 ta p-l-50 p-r-50 p-t-13 fwb">
                                              {item.condition}
                                        </div>
                                        <div className="w100 fz-16 ta p-l-50 p-r-50 p-t-13">
                                              {item.text}
                                        </div>
                                    </div>
                                    {
                                        index < packageList.length -1 && <img src={arrow} className="arrow-rotate m-t-90"></img>
                                    }
                                      
                                    </div>
                                    
                                )
                            })
                        }

                    </div>

                </div>

            </div>
            <Modal isVisible={showDetail} title="Normal Care package" onClose={() => setShowDetail(false)}>
                  <div className="flex flex-column flex-center w100 m-t-20">

                  <img src={packageList[activeIndex].image_active} alt="" className='care-modal-image' />
                  </div>
                   <div className='ta fz-20 fwb m-t-26 '>
                     {packageList[activeIndex].condition}
                   </div>
                   <div className='fz-16 ta m-t-8'>
                     {packageList[activeIndex].text}
                   </div>
                   <div className="hr m-t-24 m-b-24"></div>
                   <div className="flex flex-between">
                     <span className="c236 fz-13">Get date</span>
                     <span className="c2b fz-13">February 25, 2023</span>
                   </div>

                   <div className="flex flex-between m-t-14">
                     <span className="c236 fz-13">Balance</span>
                     <span className="c2b fz-13">0.2745 APT</span>
                   </div>
                   <Button className=" color w100 modal-btn fz-16 fwb m-t-25" onClick={() => setShowDetail(false)}>
                     I see
                   </Button>
            </Modal>
        </div>
    )
}
export default reducxConnect(
    (state, props) => {
        return { ...state, ...props }
    }
)(
    Airdrop1
);