import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { getAddress } from '../../../contract'
import aptoslogo from '../../../assets/image/token/APT.svg'
import { useSubmitTransiction } from '../../../methods/submit'
import './transfer.scss'
import { testInput, toUnit } from '../../../lib/util'

export default ({ selectList, onClose }) => {
  let [reciver, setReciver] = useState('')
  let [list, setList] = useState(selectList)
  let [loading, setLoading] = useState(false)
  let [allprice, setAllprice] = useState(0)
  console.log(selectList)
  const { submitTransiction } = useSubmitTransiction()
  const batchList = () => {
    if (loading) {
      return
    }
    setLoading(true)
    let payload = {
      type: "script_function_payload",
      function: `${getAddress().marketplace.address}::batch_list_script`,
      type_arguments: [],
      arguments: [
        list.map(item => item.creator_address),
        list.map(item => item.collection_name),
        list.map(item => item.name),
        list.map(item => item.largest_property_version),
        list.map(item => toUnit(item.price))
      ],
    };
    console.log(payload)
    submitTransiction(payload, () => {
      setLoading(false)
      onClose()
    }, () => {
      setLoading(false)
    })
  }
  const setPriceByIndex = (price, index) => {
    let list_price= [...selectList]
    list_price[index].price = price
    setList(list_price)
  }
  const applyAll =()=> {
    let list_price= [...selectList]
    console.log(allprice)
    list_price = list_price.map(item => {
      item.price = allprice
      return item
    })
    setList(list_price)
  }

  useEffect(() => {
    setList(selectList)
  } , [selectList])
  return (
    <div className="p-t-10 w100">
      {
        selectList.length >1 && <div className="price-area flex bdr-6">
        <input type="number" placeholder='0' className="com_input  tr flex-1" onChange={(e)=>{
          if(testInput(e.target.value)) {
            return 
          } else {
            setAllprice(e.target.value)
          }
        }}/>
        <span className='fz-14 fwb c2b flex flex-center'>
          <img src={aptoslogo} alt="apt" className='aptlogo m-r-5' />
          APT
        </span>
        <Button className='color my-button m-l-10' onClick={applyAll}>Apply to all</Button>
      </div>
      }
      
      <div className="tr fz-14 c236 p-b-5 m-t-5"><span className='fwb'>{selectList.length}</span> NFTs selected</div>
      <div className="w100 p-t-10 p-b-10 list-nft-box">
        {
          list?.map((item, index) => {
            return (
              <div className='m-b-5 flex'>
                <img src={item.uri} alt={item.name} className="list-img" />
                <span className='flex flex-column m-l-5 flex-1'>
                  <span className='fz-12  c236 oneline-only'>{item.name}</span>
                  <div className="price-area flex bdr-6">
                    <input type="number" placeholder='0' className="com_input flex-1 tr" value={item.price||''} onInput={(e) => {
                      if(testInput(e.target.value)) {
                        return
                      } else {
                        setPriceByIndex(e.target.value, index)
                      }
                    }} />
                    <span className='fz-14 fwb c2b flex flex-center'>
                      <img src={aptoslogo} alt="apt" className='aptlogo m-r-5' />
                      APT
                    </span>
                  </div>
                </span>
              </div>
            )
          })
        }
      </div>
      <Button loading={loading} className="w100 color my-button transfer-btn large-btn m-t-20" disabled={list.some(item => !item.price)} onClick={batchList}>Confirm List</Button>
    </div>)
}