import './index.scss'
import {Button, Radio, Select, Tabs} from 'antd'
import Project from '../../components/project'
import { NavLink } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react';
import { PropertySafetyFilled, SearchOutlined } from '@ant-design/icons';
import MySelect from '../../components/common/MySelect';
import Stake from './components/stake'
import Item from 'antd/lib/list/Item';
import copper from '../../assets/image/stake/cards/copper.png'
import silver from '../../assets/image/stake/cards/silver.png'
import gold from '../../assets/image/stake/cards/gold.png'
import platinum from '../../assets/image/stake/cards/platinum.png'
import diamond from '../../assets/image/stake/cards/diamond.png'
import love from '../../assets/image/stake/love.png'
import angry from '../../assets/image/stake/angry.png'
import stake from '../../assets/image/stake/stake.png'
import mystake from '../../assets/image/stake/mystake.png'
import infoleft from '../../assets/image/stake/info-icon-left.png'
import inforight from '../../assets/image/stake/info-icon-right.png'
import levelleft from '../../assets/image/stake/level-icon-left.png'
import levelright from '../../assets/image/stake/level-icon-right.png'
import { getModuleStore, getUserStore } from '../../methods/staking';
import { addPoint, findAddressByName, formatTime, formatTimeAddDate, fromUnit, hexToString, numFormat, OpenNotification } from '../../lib/util';
import {connect as reducxConnect} from 'react-redux'
import { explorerUrl, getAddress } from '../../contract';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useSubmitTransiction } from '../../methods/submit';
import { setUserStore } from '../../store';
  const iconLevel = {
    'bronze': copper,
    'silver': silver,
    'gold': gold,
    'platinum': platinum,
    'diamond': diamond
  }
  const levelOptions = [
    {
      label: 'copper card',
      value: '1',
      color: 'linear-gradient(360deg, #A3592D 0%, #DCAB6A 100%)',
      icon: copper,
      hex: '0x62726f6e7a65'
    },
    {
      label: 'silver card',
      value: '2',
      color: 'linear-gradient(180deg, #E3E6E9 0%, #B8B8BE 100%)',
      icon: silver,
      hex: '0x73696c766572'
    },
    {
      label: 'gold card',
      value: '3',
      color: 'linear-gradient(360deg, #E4B42B 0%, #F1D844 100%)',
      icon: gold,
      hex: '0x676f6c64'
    },
    {
      label: 'platinum card',
      value: '4',
      color: 'linear-gradient(360deg, #BBD7DE 0%, #BECDEA 100%)',
      icon: platinum,
      hex: '0x706c6174696e756d'
    },
    {
      label: 'diamond card',
      value: '5',
      color: 'linear-gradient(360deg, #4A4BAF 0%, #B47CDC 100%)',
      icon: diamond,
      hex: '0x6469616d6f6e64'
    },
  ];
const StakeIndex = (props) => {
    const [globalStore, setGlobalStore] = useState({})
    const [userStore, setUserStores] = useState({})
    const [stakeNum, setStakeNum] = useState(0);
    const [stakeList, setStakeList] = useState([]);
    const [refresh, setRefresh] = useState(1);
    const { signAndSubmitTransaction } = useWallet()
    const [unstakeLoading, setUnstakeLoading] = useState(false)
    const {submitTransiction} = useSubmitTransiction()
    useEffect(async () => {
      if(props.account) {
        let {data:userStore} = await getUserStore(props.account)
        let stakeNum = 0
        console.log(userStore)
        setUserStores(userStore)
        props.dispatch(setUserStore(userStore))
        userStore.depositions.map(item => {
          stakeNum += item.amount*1
        })
        setStakeList(userStore.depositions)
        setStakeNum(fromUnit(stakeNum))
      }
    }, [props.account, refresh])
    useEffect(async () => {
      let {data: moduleStore} = await getModuleStore()
      // console.log(moduleStore)
      setGlobalStore(moduleStore)
    }, [refresh])

    const Unstake = async (sequence) => {
      setUnstakeLoading(true)
      let payload = {
        type: "script_function_payload",
        function: `${getAddress().grade.grade.address}::withdraw`,
        type_arguments: [findAddressByName('HARW')],
        arguments: [sequence]
      };

      submitTransiction(payload, () => {
        setRefresh(value => value + 1)
        setUnstakeLoading(false)
      }, () => {
        setUnstakeLoading(false)
      }, 'UnStake Succeed')
      
    }
    return (
      <div className="stake">
        <div className="middle-content">
          {/* staking-area */}
          <div className="stake-area flex flex-between p-t-50">
            <div className="stake-left flex-1">
              <Tabs defaultActiveKey="1" className='my-tab ' onChange={() => setRefresh(value => value + 1)}>
                <Tabs.TabPane tab={
                  <div>
                    <img src={stake} alt="" className='tab-icon'/>
                    <span className='c23 fz-16 fwb m-l-8'>Get Quota</span>
                  </div>
                  } key="1">
                  <Stake levels={globalStore?.levels} option={levelOptions} success={()=>setRefresh(refresh+1)}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={
                  <div>
                    <img src={mystake} alt="" className='tab-icon'/>
                    <span className='c23 fz-16 fwb m-l-8'>MyStake</span>
                  </div>
                } key="2">
                  <div className="p-l-40 p-r-40">
                    <table  className='stake-table m-t-14'>
                      <tr>
                          <th>Staked amount</th>
                          <th>Lock duration</th>
                          <th>Stake date</th>
                          <th>Unlock date</th>
                          <th>Operation</th>
                      </tr>
                      {
                        stakeList.map(el => (
                          <tr key={el} >
                            <td>{fromUnit(el.amount)} HARW</td>
                            <td>{el.lock_units}days</td>
                            <td>{formatTime(el.deposit_time)}</td>
                            <td>{formatTimeAddDate(el.deposit_time, el.lock_units)}</td>
                            <td>
                                <Button loading={unstakeLoading} type="link" className='operation' onClick={() => Unstake(el.sequence)}>Unstake</Button>
                            </td>
                          </tr>
                        ))
                      }
                      {
                        stakeList.length == 0 && <tr>
                          <td colSpan={5}>
                            <div className='p-20 c05'>
                              No Data
                            </div>
                          </td>
                        </tr>
                      }
                    </table>
                    <div className="fz-14 c236 ta w100 m-t-30">Note: After the unstake, the corresponding level will also disappear along with it</div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
            <div className="stake-right">
                <div className="stake-info-bar total-staked m-b-30">
                  <img src={love} alt="" className='love' />
                    <div className="stake-info-bar-title fz-14 c236">Total Staked</div>
                    <div className="stake-info-bar-content fz-30 fwb">{fromUnit(globalStore?.total_depositions)} HARW</div>
                </div>
                <div className="stake-info-bar m-b-30">
                    <div className="stake-info-bar-title fz-14 c236">Stakers</div>
                    <div className="stake-info-bar-content fz-30 fwb">{globalStore.total_depositors} Peoples</div>
                  <img src={angry} alt="" className='angry' />

                </div>
                {
                props.account && (
                  <div className="stake-info-myinfo">
                  <div className="my-info-title fz-24 fwb w100 flex flex-center flex-middle">
                    <img src={infoleft} alt="" className='my-info-icon'/>
                    My Information
                    <img src={inforight} alt="" className='my-info-icon'/>
                  </div>
                  <div className="p-t-20 p-l-26 p-r-26 p-b-80">
                    <div className="stake-info-bar-title fz-14 c236">Wallet address</div>
                    <div className="stake-info-bar-content fz-26 m-b-15 fwb">{addPoint(props.account)}</div>
                    <div className="stake-info-bar-title fz-14 c236">Current Level</div>
                    {
                      (userStore?.level && (userStore?.level?.vec[0]?.name)) ?
                    <div className="stake-info-bar-content fz-26 m-b-15 flex flex-center fwb">
                      <img src={levelOptions.find(item => item.hex == userStore?.level?.vec[0]?.name).icon} alt="" className='card-icon m-r-8'/>
                      {hexToString(userStore?.level?.vec[0]?.name||'') } card
                    </div>:<div className="stake-info-bar-content fz-26 m-b-15 flex flex-center fwb">--</div>
                    }
                    
                    <div className="stake-info-bar-title fz-14 c236">Staked</div>
                    <div className="stake-info-bar-content fz-26 fwb">{numFormat(stakeNum)} HARW</div>
                  </div>
                </div>
                )
                }
              
            </div>
          </div>
        {/* staking-area */}
        {/* level-area */}
        <div className="level-area w100">
          <div className="ta fz-24 fwb level-title">
          <img src={levelleft} alt="" className='level-info-icon m-r-24'/>
            User level description
            <img src={levelright} alt="" className='level-info-icon m-l-24'/>
            </div>
          <div className="flex w100 flex-between m-t-30 level-wrap">
            {
              globalStore.levels?.map((item, index) => {
                return (
                  <div className="card-item flex flex-column flex-center" key={item?.name}>
                        <div className="card-title w100 ta fwb m-t-8">· {('Staking requirement').toUpperCase( )} ·</div>
                        <img src={levelOptions[index].icon} alt="" className="card-img" />
                        <div className='fz-12 c236 m-t-15'>Lock at least </div>
                        <div className="fz-30 fwb ">{fromUnit(item.weight)}</div>
                        <div className="fz-16 fwb harw">Weight</div>
                        <div className="card-btn copper fz-16 ta m-t-20" style={{background: levelOptions[index].color}}>{hexToString(item?.name).toLocaleUpperCase()}</div>
                  </div>
                )
              })
            }
          </div>
          <div className="desc w100">
          <div className="fz-16 fwb">How to calculate weight?</div>
            <div className="fz-14 m-t-10 c236">Weight = stake amount * lock duration</div>

            <div className="fz-16 fwb m-t-20">Why do I need to get a level?</div>
            <div className="fz-14 m-t-10 c236">Different projects will have different IDO limits for different levels. The higher the level, the more IDOs that can participate.</div>
          </div>





            
        </div>
        {/* level-area */}
        </div>
      </div>
    )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  StakeIndex
);