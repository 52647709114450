import { useState } from 'react';
import { connect as reducxConnect } from 'react-redux';
import { Button, Table } from 'antd';
import icon1 from '../../../assets/image/reward/reward-icon-1.svg';
function RewardFarmList(props) {
  const columns = [
    {
      title: 'Pool Name',
      dataIndex: 'poolName',
      key: 'poolName',
      width:'25%',
    },
    {
      title: 'Stake position',
      dataIndex: 'stakePosition',
      key: 'stakePosition',
      width:'25%',
      render: (text) => <span>{text} HARW</span>,
    },
    {
      title: 'Remain Reward Amount',
      dataIndex: 'remainRewardAmount',
      key: 'remainRewardAmount',
      width:'25%',
      render: (text) => <span>{text} HARW</span>,
    },
    {
      title: 'Operation',
      key: 'operation',
      width:'25%',
      render: (_, record) => (
        <Button type="link" danger onClick={() => claim()}>
          <span style={{textDecoration:'underline'}}>Claim</span>
        </Button>
      ),
    },
  ];
  const [tableData, setTableData] = useState([
    {
      key:1,
      poolName: 'HARW',
      stakePosition: '200000',
      remainRewardAmount: '10100',
    },
  ]);
  const claim = () => {};
  return (
    <div className="reward-list reward-farm-list">
      <div className="header-wrapper">
        <img src={icon1} className="logo" alt="" style={{padding: '7px'}}/>
        <div className="content-wrapper">
          <div className="fwb">Farm</div>
          <div>
            <span>Remain reward: </span>
            <span className="fwb">20300 HARW</span>
          </div>
        </div>
      </div>
      <Table columns={columns} dataSource={tableData} pagination={false} className='reward-table'/>
    </div>
  );
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(RewardFarmList);
