import './Swap.scss'
import LeftLogo from '../../../assets/image/launchpad/swap_left_log.png'
import Pinecone from '../../../assets/image/launchpad/min-pinecone.svg'
import sweeping from '../../../assets/image/launchpad/sweeping-squirrel.png';
import settingIcon from '../../../assets/image/launchpad/shezhi.svg';
import refreshIcon from '../../../assets/image/launchpad/refresh.svg';
import bottomIcon from '../../../assets/image/launchpad/bottom-icon.svg';
import circularIcon from '../../../assets/image/launchpad/circular-icon.svg';
import wallet from '../../../assets/image/launchpad/wallet.svg';
import question from '../../../assets/image/common/question.svg';
import MyChart from './MyChart'
import { Button, Skeleton, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import Settings from './Settings'
import SelectToken from './SelectToken'
import { findAddressByName, fromUnit, OpenNotification, showLogin, testInput, toFixed, toUnit, UNIT_DECIMAL } from '../../../lib/util'
import { getTokenByName } from './list'
import {connect as reducxConnect} from 'react-redux'
import {getBalance, isRegistered} from '../../../methods/client.ts'
import { useWallet } from '@manahippo/aptos-wallet-adapter'
import { explorerUrl, getAddress } from '../../../contract'
import { useSubmitTransiction } from '../../../methods/submit'
import { calcRouterPriceByInput, calcRouterPriceByOutput, findRouter, formatRouter, formatRouterIcon, getAllReserves } from './getreserve'
import { toRegister } from '../../../methods/client.ts'
import { getUserStore } from '../../../methods/faucet'
import Loading from '../../../components/common/Loading'
const decimal = 6

function Swap(props) { 
  const optionsData = [
    { label: '1D', value: '1d' },
    { label: '1W', value: '1w' },
    { label: '1M', value: '1m' },
    { label: 'ALL', value: 'all' }
  ]

  const DOUBLE_BTN = [
    { label: 'Max', value: '100' },
    {label: '75%',value: '75' },
    {label: '50%',value: '50' },
    {label: '25%',value: '25' }
  ]

  let {signAndSubmitTransaction} = useWallet()
  const [showSetting, setShowSetting] = useState(false);
  const [showSelectToken, setShowSelectToken] = useState(false);
  const [selectType, setSelectType] = useState('input');
  const [buyer, setBuyer] = useState('');
  const [seller, setSeller] = useState('');
  const [inputToken, setInputToken] = useState('USDC')
  const [outToken, setOutToken] = useState('HARW')
  const [inputBalance, setinputBalance] = useState(0)
  const [outBalance, setOutBalance] = useState(0)
  const [isRegister, setIsregister] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const [price, setPrice] = useState(0)
  const [loading, setLoading] = useState(false)
  const [slip, setSlip] = useState(localStorage.getItem('slip')||'0.5')
  const [type, setType] = useState('input')
  const [loadingInputBalance, setLoadingInputBalance] = useState(false)
  const [loadingOutputBalance, setLoadingOutputBalance] = useState(false)
  const [canSwap, setCanSwap] = useState(false) // 是否能交易
  const [errMsg, setErrMsg] = useState('Enter an amount') // 错误提示
  const [routers, setRouters] = useState([])
  const [routers_with_price, setRoutersWithPrice] = useState([])
  const [reserveX, setReserveX] = useState(0)
  const [usdcBal, setUSDCBal] = useState(null)
  const [claimLoading, setClaimLoading] = useState(false)
  const [claimTimes, setClaimTimes] = useState(0)
  const [claimDate, setClaimDate] = useState(0)

  const {submitTransiction} = useSubmitTransiction()
  

 const toSelect = (type) => {
  setSelectType(type)
  setShowSelectToken(true)
 }
 const slipChange = (num) => {
  setSlip(num)
 }
 const confirmSelectToken = (name, type) => {
  type == 'input' ? setInputToken(name):setOutToken(name)
 }

const toSwap = async() => {
  setLoading(true)
  console.log(buyer)
  console.log(seller)
  let method = type == 'input' ? 'swap_exact_input_script':'swap_exact_output_script'
  let inputNum = type =='input' ? toUnit(toFixed(buyer*(1), UNIT_DECIMAL)):toUnit(toFixed(seller, UNIT_DECIMAL))
  let outputNum = type == 'input' ? toUnit(toFixed(seller*(1-slip/100), UNIT_DECIMAL)):toUnit(toFixed(buyer*(1+slip/100), UNIT_DECIMAL))
  if(routers_with_price[0].router.length==2) {
    method = 'swap_exact_input_doublehop_script'
    outputNum = 0
  } else if(routers_with_price[0].router.length==3) {
    method = 'swap_exact_input_triplehop_script'
    outputNum = 0
  }
  let coins = formatRouter(inputToken, outToken, routers_with_price[0].router).split('>')
  let dexType_list = routers_with_price[0].router.map(item => item.dexType)
  let payload = {
    "function": getAddress()['aggregator']['address']+'::'+method,
    "type_arguments": coins.map(item => findAddressByName(item)),
    "arguments": [
      ...dexType_list,
      inputNum, 
      outputNum
    ],
    "type": "entry_function_payload"
  }
  submitTransiction(payload, () => {
      setLoading(false)
      setBuyer('')
      setSeller('')
      setRefresh(refresh+1)
  }, () => {
    setLoading(false)
    setRefresh(refresh+1)
  })
}
const toClaim = () => {
  if(claimLoading) {
    return
  }
  if(new Date().getTime()/1000 - claimDate < 24*60*60) { // 一天之内
    OpenNotification('warning', 'Claim failed', `please claim in ${24-Math.floor((new Date().getTime()/1000 - claimDate)/60/60)} hour(s)`)
    return
  }

  setClaimLoading(true)
  let payload = {
    "function": getAddress()['faucet']['address']+'::claim_script',
    "type_arguments": [findAddressByName('USDC')],
    "arguments": [],
    "type": "entry_function_payload"
  }
  submitTransiction(payload, () => {
    setRefresh(refresh+1)
    setClaimLoading(false)
}, () => {
  setClaimLoading(false)
})
}
const goRegister = async () => {
  if(claimLoading) {
    return
  }
   setClaimLoading(true)
   toRegister('USDC', signAndSubmitTransaction, (hash)=> {
    OpenNotification('success', 'Rsgister Success', <a target="_blank" href={`${explorerUrl}${hash}`}>Go to browser to view</a>)
    setRefresh(refresh+1)
    setClaimLoading(false)
   }, ()=> {
    setUSDCBal(null)
    setClaimLoading(false)
   })
}
//  互换
 const exChange = () => {
  let name = inputToken
  setInputToken(outToken)
  setOutToken(name)
  type == 'input' ? setBuyer(''):setSeller('')
  type == 'input' ? setSeller(buyer):setBuyer(seller)
  setType(type == 'input'?'output':'input')
 }
 useEffect(() => {
  if(type == 'input' && buyer) {
     let routerswithprice = []
     routers.map(item => {
       routerswithprice.push(calcRouterPriceByInput(inputToken, outToken, item, toUnit(buyer)))
     })
     routerswithprice = [...routerswithprice.sort((a, b) => {return Number(b.amountOut) - Number(a.amountOut)})]
     console.log(routerswithprice)
     setRoutersWithPrice(routerswithprice)
     setPrice(routerswithprice[0].amountOut/routerswithprice[0].amountIn)
     setSeller(fromUnit(routerswithprice[0].amountOut))
     console.log(routerswithprice)
    setReserveX(findAddressByName(inputToken)<findAddressByName(outToken)?routerswithprice[0].router[0].reserve_x:routerswithprice[0].router[0].reserve_y)
    console.log(findAddressByName(inputToken)<findAddressByName(outToken)?routerswithprice[0].router[0].reserve_x:routerswithprice[0].router[0].reserve_y)
  } else if(type == 'input' && !buyer ) {
    setSeller('')
  }
 }, [buyer, type])

 useEffect(() => {
  if(type == 'output' && seller) {
    let routerswithprice = []
     routers.map(item => {
      routerswithprice.push(calcRouterPriceByOutput(inputToken, outToken, item, toUnit(seller)))
     })
     routerswithprice = [...routerswithprice.sort((a, b) =>  {return Number(a.amountIn) - Number(b.amountIn)})]
     let routerhasin = []
     let routernoin = []
     routerswithprice.map(item => {
       if(item.amountIn) {
        routerhasin.push(item)
       } else {
        routernoin.push(item)
       }
     })
     routerswithprice = [...routerhasin, ...routernoin]
     setRoutersWithPrice(routerswithprice)
     setPrice(routerswithprice[0].amountOut/routerswithprice[0].amountIn)
    setBuyer(fromUnit(routerswithprice[0].amountIn))
    setReserveX(findAddressByName(inputToken)<findAddressByName(outToken)?routerswithprice[0].router[0].reserve_x:routerswithprice[0].router[0].reserve_y)

  }else if(type == 'output' && !seller) {
    setBuyer('')
  }
 }, [seller, type])

 useEffect(() => {
  // 不能swap 的情况有：
  // 1, 交易对不存在
  // 2, 未连接钱包  
  // 3, 余额不足
  // 4, seller > reservey  流动性不足
  // 5，未输入金额

  if(!routers.length) { // 交易对不存在
    setCanSwap(false)
    setErrMsg('Liquidity does not exist')
  } 
  else if (!props.account) { // 未连接钱包
    setCanSwap(false)
    setErrMsg('Connect Wallet')
  }
  else if(buyer*1 > fromUnit(inputBalance)*1) { // 余额不足
     setCanSwap(false)
     setErrMsg(`Insufficient ${inputToken} balance`)
  } else if(routers_with_price.length && !routers_with_price[0].amountIn) { // 流动性不足
    setCanSwap(false)
    setErrMsg(`Insufficient liquidity for this trade.`)
 } else if(!buyer) { // 未输入金额
    setCanSwap(false)
    setErrMsg(`Enter an amount`)
  } else {
    setCanSwap(true)
    setErrMsg('')
  }

 }, [props.account, inputBalance, routers_with_price])

 // 获取balance
 useEffect( async () => {
  if(props.account) {
    setLoadingInputBalance(true)
    let bal = await getBalance(props.account, findAddressByName(inputToken))
    setinputBalance(bal)
    setLoadingInputBalance(false)
  } else {
    setinputBalance(0)
  }
 }, [inputToken, props.account, refresh])

 useEffect( async () => {
  if(props.account) {
    setLoadingOutputBalance(true)
    let bal = await getBalance(props.account, findAddressByName(outToken))
    setLoadingOutputBalance(false)
    setOutBalance(bal)
  } else {
    setOutBalance(0)
  }
 }, [outToken, props.account, refresh])

 useEffect(async () => {
  let result = await getAllReserves()
  let router_list = findRouter(result, inputToken, outToken)
  // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  // console.log(router_list)
  setRouters(router_list)
  if(router_list.length == 0) {
    setPrice(0)
    setBuyer('')
    setSeller('')
  }

 }, [inputToken, outToken, refresh])

 useEffect(()=> {
  setSlip(localStorage.getItem('slip'))
 }, [])

 useEffect(async () => {
  if(!props.account) {
    setClaimTimes(0)
    setClaimDate(0)
    return
  }
  let bal = await getBalance(props.account, findAddressByName('USDC'))
  setUSDCBal(bal)
  let userStore = await getUserStore(props.account)
  console.log(userStore)
  if(userStore) {
    setClaimTimes(userStore.data.claim_count)
    setClaimDate(userStore.data.last_claim_time)
  } else {
    setClaimTimes(0)
    setClaimDate(0)
  }
 }, [props.account, refresh])

// isregister
 useEffect( async () => {
  if(props.account) {
    let register = await isRegistered(props.account, outToken)
    setIsregister(register)
  }
 }, [outToken, props.account])
  return (
    <div className="swap-content flex">
      <img className='swap-left-log' src={LeftLogo} alt="" />
      <MyChart tokenChange={
        (tokenName) => {
          setInputToken(tokenName.split('/')[0])
          setOutToken(tokenName.split('/')[1])
        }
      }/>
      <div className="swap-right bgf pb">
        <img className='min-pinecone' src={Pinecone} alt="" />
        <img className='sweeping-squirrel' src={sweeping} alt="" />
        <div className='flex flex-between flex-center'>
          <span className='swap-right-swap flex flex-center'>Swap
          <span className='flex flex-center'>
          {
            usdcBal == null ?
            <span className='m-l-20 fz-14 c2b underline pointer flex flex-center register-usdc' onClick={goRegister}>Register & Cliam test USDC </span>:
            <span className='m-l-20 fz-14 c2b underline pointer flex flex-center register-usdc' onClick={toClaim}>Cliam test USDC</span>
          }
          {
            <span className='fz-12 normal m-l-3 '>({10-claimTimes} times remaining)</span> 
          }
          {claimLoading && <Loading className='m-l-5' zoom={0.4}/>}
            
          </span>
            </span>
          <div>
            <Tooltip title="Setting"> <img onClick={() => setShowSetting(true)} src={settingIcon} alt="" className='pointer'/></Tooltip>
            <Tooltip title="Refresh Balance"><img className='m-l-15 pointer' src={refreshIcon} alt="" onClick={()=>setRefresh(refresh+1)}/> </Tooltip>
              {/* <img className='m-l-20' src={setting2Icon} alt="" />*/}
              
          </div>
        </div>

        {/* 支付币种 */}
        <div className='swap-right-from bgEEF m-t-11'>
          <div className='flex flex-between flex-center'>
            <div className='flex flex-center gap-8'>
              {
                DOUBLE_BTN.map((el, i) => {
                  return (
                    <div className='help-btn pointer' onClick={()=>{
                      if(!routers.length) {
                        return
                      }
                      setBuyer(toFixed(fromUnit(inputBalance)*el.value/100, decimal))
                      // setSeller(toFixed(getAmountOut(fromUnit(inputBalance)*el.value/100, reservex, reservey), decimal))
                      setType('input')
                    }
                  } key={i}>{el.label}</div>
                  )
                })
              }
            </div>
            <div className='flex flex-center'>
              <img className='m-r-9' src={wallet} alt="" />
              <span className='c2b fz-14'>{
                loadingInputBalance ? <Skeleton.Button active size={'small'} />:
              fromUnit(inputBalance)||'0'
              }</span>
            </div>
          </div>
          <div className='flex flex-between flex-center m-t-14'>
            <input
              className='c2b fz-24 fwb lh-28 flex-1 flex w100 com_input'
              type="text"
              placeholder='0'
              value={buyer}
              disabled={!routers.length}
              onChange={(e) => {
                if (testInput(e.target.value)) {
                  return
                }
                setBuyer(e.target.value);
                // setSeller(getAmountOut(e.target.value, reservex, reservey))
                setType('input')
                }}/>
            <div className='flex flex-center  p-l-5 p-r-5' onClick={()=>toSelect('input')}>
              {/* <span className='c2b fz-14 m-r-16 lh-18'>~$1,445</span> */}
              <div className='switch-coin flex flex-center flex-middle'>
                <img src={getTokenByName(inputToken).icon} alt="token icon" className='token-icon'/>
                <span className='c2b fz-18 m-l-7 m-r-12'>{inputToken}</span>
                <img src={bottomIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* 支付币种 */}

        <div className='flex flex-between flex-center m-t-16 m-b-16'>
          <div className='flex flex-center m-l-20'>
            {
              routers.length && buyer ?  <span className='fz-14 c2b'>1 {inputToken} = {toFixed(price, decimal)} {outToken}</span>:''
            }
            
            {/* <img className='switch-icon m-l-8' src={Switch} alt="" /> */}
          </div>
          <div className='circular-switch' onClick={exChange}>
            <img src={circularIcon} alt="" />
          </div>
        </div>

        {/* 得到币种 */}
        <div className='swap-right-from bgEEF '>
          <div className='flex flex-between flex-center'>
            <div className='flex flex-center'>
              {/* <span className='fz-12 c00c m-r-6'>{`You save $0.12 (<0.01%)`}</span>
              <img src={exclamatoryMark} alt="" /> */}
            </div>
            <div className='flex flex-center'>
              <img className='m-r-9' src={wallet} alt="" />
              <span className='c2b fz-14'>
                {loadingOutputBalance ? <Skeleton.Button active size={'small'} />:
                fromUnit(outBalance)||'0'}</span>
            </div>
          </div>
          <div className='flex flex-between flex-center m-t-14'>
            <input
              className='c2b fz-24 fwb lh-28 flex-1 flex w100 com_input'
              type="text"
              placeholder='0'
              value={seller}
              disabled={!routers.length}
              onChange={(e) => {
                if (testInput(e.target.value)) {
                  return
                }
                setSeller(e.target.value);
                // setBuyer(getAmountIn(e.target.value, reservex, reservey));
                setType('output')
              }}/>
            <div className='flex flex-center' onClick={()=>toSelect('output')}>
              {/* <span className='c2b fz-14 m-r-16'>~$1,445</span> */}
               <div className='switch-coin flex flex-center flex-middle' >
                <img src={getTokenByName(outToken).icon} alt="token-icon" className='token-icon'/>
                <span className='c2b fz-18 m-l-7 m-r-12'>{outToken}</span>
                <img src={bottomIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* 得到币种 */}

         {/* <div className='flex flex-last m-t-17  m-b-17  p-l-20 p-r-20'> */}
          {/* <div className='flex flex-center'>
            <img src={yigouxuan} alt="" />
            <span className='fz-16 c00c fwb m-l-6 m-r-8'>Fair price</span>
            <img src={xialaGreen} alt="" />
          </div> */}
          {/* <div>
            <span className='fz-12 c2b m-r-8'>Slippage Tolerance</span>
            <span className='c00c fz-12'>{slip}%</span>
          </div>
        </div> */}
        {
          buyer ? 
          <div className='speed-info m-t-15'>
          {/* <div className='flex flex-between m-b-16 lh-18'>
            <span className='c2b fz-14'>Rate</span>
            <div className='flex flex-center'>
              {/* <img className='switch-icon m-r-8' src={Switch} alt="" /> */}
              {/* <span className='c232 fz-14 fwb'>1 {inputToken} = {toFixed(seller/buyer, decimal)} {outToken}</span> */}
            {/* </div> */}
          {/* </div> */}
          <div className='flex flex-between m-b-16 lh-18'>
            <div className='flex flex-center'>
              <span>Minimum received</span>
              <Tooltip title="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.">
                <img className='m-l-8' src={question} alt="" />
              </Tooltip>
            </div>
            <span className='c232 fz-14 fwb'>{toFixed(seller*(1-slip/100), decimal)} {outToken}</span>
          </div>
          <div className='flex flex-between m-b-1 lh-18'>
            <div className='flex flex-center'>
              <span>Price Impact</span>
              <Tooltip title="The difference between the market price and estimated price due to trade size.">
                <img className='m-l-8' src={question} alt="" />
              </Tooltip>
              
            </div>
            <span className='c00c fz-14 fwb'>{
            toUnit(buyer)/(toUnit(buyer)*1+(reserveX)*1)*100 < 0.01 
            ? '<0.01'
            : toFixed(toUnit(buyer)/(toUnit(buyer)*1+(reserveX)*1)*100, 2)}%</span>
          </div>
          <p className='tr lh-18 c232 fz-12 pointer' onClick={() => setShowSetting(true)} >Slippage tolerance: <span className='c00c'>{slip}%</span></p>
          <div className='flex flex-between m-b-1 lh-18'>
            <div className='flex flex-center'>
              <span>Liquidity Provider Fee</span>
              <Tooltip title="For each trade a 0.3% fee is paid">
                <img className='m-l-8' src={question} alt="" />
              </Tooltip>
            </div>
            <span className='c232 fz-14 fwb'>{toFixed(buyer*0.003, decimal)} {inputToken}</span>
          </div>
          <p className='tr lh-18 c232 fz-12 m-b-1'>0.3%</p>
          <div className='flex flex-between m-b-1 lh-18'>
            <div className='flex flex-center'>
              <span>Router</span>
              <Tooltip title="Routing through these tokens resulted in the best price for your trade.">
                <img className='m-l-8' src={question} alt="" />
              </Tooltip>
            </div>
            <span className='c232 fz-14 fwb flex flex-center'>
               {routers_with_price[0] && formatRouter(inputToken, outToken, routers_with_price[0]?.router)}
            </span>
          </div>
          <p className='tr lh-18 c232 fz-12 m-b-1'> {routers_with_price[0] && formatRouterIcon(routers_with_price[0]?.router)}</p>
          
        </div>:''
        }
        
        {/* // 未登录
        // 未注册
        // 未输入
        // 余额不足 */}
        {/* {
          seller*1 > fromUnit(reservey)*1 ? 
          <Button className='approve-btn pointer disabled' disabled loading={loading}>Insufficient liquidity for this trade.</Button>:
          props.account ?
          ( hasPair ?(
            buyer*1 > fromUnit(inputBalance)*1 ?
            <Button className='approve-btn pointer disabled' disabled loading={loading}>Not Enough Coin</Button>:
            <Button className='approve-btn pointer' onClick={toSwap} loading={loading}>Swap</Button>):
            <Button className='approve-btn pointer disabled' disabled loading={loading}>Unable to Swap</Button>
          )
          :
          <Button className='approve-btn pointer' onClick={showLogin} loading={loading}>Connect Wallet</Button>
        }  */}
        {
          <Button className={'approve-btn pointer  m-t-15 '+((!canSwap && errMsg != 'Connect Wallet') ? 'disabled': '')} 
             disabled={(!canSwap && errMsg != 'Connect Wallet')}
             onClick={canSwap ?toSwap:showLogin} loading={loading}>
              {canSwap ? 'Swap': errMsg}
          </Button>
        }
        {/* {
          <div className='speed-info m-t-15 m-b-30'>
            <div className="fz-16 fwb">Currency Reserves <span className='fz-12'>(Liquidity from {dexType == 2 ? 'Harwell': 'Pancake'})</span></div>
          <div className='flex flex-between m-t-20 m-b-16 lh-18'>
            <span className='c2b fz-14'>
               <img src={getTokenByName(inputToken).icon} alt="token icon" className='token-icon m-r-5'/>
               {inputToken}
            </span>
            <div className='flex flex-center'>
              <span className='c232 fz-14 fwb'>{toFixed(fromUnit(reservex), decimal)}</span>
            </div>
          </div>
          <div className='flex flex-between m-b-10 lh-18'>
            <span className='c2b fz-14'>
               <img src={getTokenByName(outToken).icon} alt="token icon" className='token-icon m-r-5'/>
               {outToken}
            </span>
            <div className='flex flex-center'>
              <span className='c232 fz-14 fwb'>{toFixed(fromUnit(reservey), decimal)}</span>
            </div>
          </div>
          </div>
        } */}
         {
          //  <div className="fz-16 fwb m-t-15">Total {routers.length} routes available</div>


         }
        {/* <div className='swap-right-from-submit c2b pointer' onClick={() => setApproveBtnShow(true)}>Insufficient liquidity for this trade.</div> */}
        {
            <Settings
            show={showSetting}
              closeFn={setShowSetting}
              slipChange={slipChange}
              slip={slip}
              ></Settings>
        }
        {
            <SelectToken closeFn={setShowSelectToken} input={inputToken} output={outToken} selectToken={(name)=>confirmSelectToken(name, selectType)} show={showSelectToken} type={selectType}></SelectToken>
        }
      </div>
    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Swap
);