import ThemePng from '../../assets/image/home/theme.png';
import { useState } from 'react';
import './Theme.scss'
export default () => {
  const [nftInfo, setNftInfo] = useState([
    {
      label: 'Current Price',
      value: '2.56',
    },
    {
      label: 'Total TVL',
      value: '47,556,743',
    },
    {
      label: 'Circulation supply',
      value: '345,324,523',
    },
    {
      label: 'Max supply',
      value: '20,000,000,000',
    },
  ]);
  return (
    window.innerWidth > 768 ?<div className="theme">
      <div className="content-wrapper">
        <img src={ThemePng} />
        <div className="right">
          <div className="fwb c2b text-1">Harwell makes our world fullof peace & love</div>
          <div className="fw-400 text-2">
            HARW token is an important part of the aptos ecosystem. Once you
            have HARW, your world will become more colorful and your assets will
            increase day by day
          </div>
          <div className="btn-1 ta hover fwb">Explore NFT</div>
        </div>
        <div className="nft">
          {nftInfo.map((item) => {
            return (
              <div className="nft-item">
                <label className="fw-500">{item.label}</label>
                <span className=" fwb">${item.value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>:<div className="theme-mobile">
    <div className="fwb c2b text-1">Harwell makes our world fullof peace & love</div>
          <div className="fw-400 text-2">
            HARW token is an important part of the aptos ecosystem. Once you
            have HARW, your world will become more colorful and your assets will
            increase day by day
          </div>
          <div className="btn-1 ta hover fwb">Explore NFT</div>
          <img src={ThemePng} />
          <div className="nft">
          {nftInfo.map((item) => {
            return (
              <div className="nft-item">
                <label className="fw-500">{item.label}</label>
                <span className=" fwb">${item.value}</span>
              </div>
            );
          })}
        </div>
    </div>
  );
};
