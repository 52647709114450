import { connect as reducxConnect } from 'react-redux';
import { useEffect, useState } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import { getLpToken } from '../../../methods/farm';
import {
  findAddressByName,
  fromUnit,
  toFixed,
  testInput,
} from '../../../lib/util';
import { getBalance } from '../../../methods/client.ts';

function RewardCalculate(props) {
  const [bal, setBal] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [stakeLpValue, setStakeLpValue] = useState(500);
  const [speed, setSpeed] = useState(0);
  const [day, setDay] = useState(0);
  useEffect(async () => {
    if (props.account) {
      let bal = await getBalance(
        props.account,
        getLpToken(findAddressByName('HARW'), findAddressByName('USDC'))
      );
      setBal(toFixed(fromUnit(bal), 4));
    }
  }, [props.info, props.account]);

  return (
    <div className="calculate-modal">
      <div className="fz-14 fw500 lh-18 c2b">Locked amount:</div>
      <div className="locked-amount w100 p-l-16 p-r-16 p-t-8 p-b-8 bdr-16 m-t-12">
        <div className="w100 flex flex-center">
          <input
            type="text"
            placeholder="0"
            className="flex-1 com_input p-8 fz-20 fwb"
            value={inputValue}
            onChange={(e) => {
              if (testInput(e.target.value)) {
                return;
              }
              setInputValue(e.target.value);
            }}
          />
          <span className="fz-18">HARW</span>
        </div>
      </div>
      <div className="fz-14 fw500 lh-18 c2b m-t-24">HARW-USDC LP Staked:</div>
      <div className="staked-amount w100 p-l-16 p-r-16 p-t-8 p-b-8 bdr-16 m-t-12">
        <div className=" flex flex-center">
          <input
            type="text"
            placeholder="0"
            className="flex-1 com_input p-8 fz-20 fwb"
            value={stakeLpValue}
            onChange={(e) => {
              if (testInput(e.target.value)) {
                return;
              }
              setStakeLpValue(e.target.value);
            }}
          />
          <span className="fz-18">LP</span>
        </div>
        <div className="range c2b  fz-12  flex flex-between">
          <span
            className={
              'pointer  m-r-11 ' +
              (stakeLpValue === 500 ? 'active' : '')
            }
            onClick={() => setStakeLpValue(500)}
          >
            500
          </span>
          <span
            className={
              'pointer m-r-11 ' +
              (stakeLpValue === 1000 ? 'active' : '')
            }
            onClick={() => setStakeLpValue(1000)}
          >
            1000
          </span>
          <span
            className={
              'pointer m-r-11 ' +
              (stakeLpValue === 3000 ? 'active' : '')
            }
            onClick={() => setStakeLpValue(3000)}
          >
            3000
          </span>
          <span
            className={
              'pointer  ' +
              (stakeLpValue === bal ? 'active' : '')
            }
            onClick={() => setStakeLpValue(bal)}
          >
            My Balance
          </span>
        </div>
      </div>
      <div className="arrow">
        <ArrowDownOutlined  className='fz-20'/>
      </div>
      <div className="p-24 c2b info">
        <div className="fz-12 fwb lh-16">Unlock speed</div>
        <div className="lh-16 fwb m-t-16">
          <span className="fz-24">{speed}</span>
          <span className="fz-16"></span>
        </div>
        <div className="m-t-8 fz-13 lh-20">
          <span>Estimated time of completion：</span>
          <span className="fwb">{day}days</span>
        </div>
      </div>
      <div className="link m-t-16 fwb fz-16 ta">GET HARW-USDC LP</div>
    </div>
  );
}

export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(RewardCalculate);
