import { NavLink } from 'react-router-dom';
import './index.scss';
import { useEffect, useState } from 'react';
import {connect as reducxConnect} from 'react-redux'
import { SearchOutlined } from '@ant-design/icons';
import { getTokenByName } from '../Dex/components/list';
import { fromUnit, toFixed } from '../../lib/util';
import { Button } from 'antd';
const Trend = ({idx, info}) => {
  return (
    <NavLink to="/marketplace/collection">
    <div className='trend w100 flex flex-center p-t-26 p-b-26 '>
       <span className='fz-16 c2b fwb'>{idx*1+1}</span>
       <img src={info.logo_uri} alt={info.name} className='trend-logo m-l-13 m-r-16'/>
       <span className="fz-18 fwb c2b flex-1">{info.name}
       {
        info.verified && <img src={require('../../assets/image/marketplace/verify.svg').default} className="m-l-8"></img>
       }
       </span>
       <div className="flex flex-start">

          <div className="flex flex-column flex-end">
              <span className="c23 fz-14">Volume</span>
              <span className='fz-16 c2b fwb flex flex-center m-t-5'>
                  <img src={getTokenByName('APT').icon} alt="apt" className='apt-logo m-r-4'/>
                  {
                    fromUnit(info.volume_24)
                  }
              </span>
              <span className={'fwb '+(Number(info.volume_48-info.volume_24)>Number(info.volume_24)?'red':'green')}>
                {
                  Number(info.volume_48-info.volume_24)>Number(info.volume_24)?'-':'+'
                }
                  {
                    !info.volume_48 ? '∞':
                    toFixed(Math.abs((info.volume_48-info.volume_24)*100/info.volume_48),2)
                  }%
              </span>
          </div>

          <div className="flex flex-column flex-end m-l-80">
              <span className="c23 fz-14">Floor Price</span>
              <span className='fz-16 c2b fwb flex flex-center m-t-5'>
                  <img src={getTokenByName('APT').icon} alt="apt" className='apt-logo m-r-4'/>
                  {
                    fromUnit(info.floor)
                  }
              </span>
          </div>

       </div>
       
    </div>
    </NavLink>
  )
}
function Marketplace(props) {
  let [trendList, setTrendList] = useState([])
  useEffect(async () => {
    let list = await fetch('https://api-v1.topaz.so/api/explore-collections?from=0&to=19')
    let data = (await list.json())
    setTrendList(data.data)
    console.log(data.data)
  }, [])
  return (
    <div className="market p-b-140">
      <div className="warning-tips">
        <div className="inner ta">
          <div className='fwb'>Warning Tips</div>
          For the Marketplace module, all you see are static pages at present. You need to wait for the completion of the API interface debugging, and we will remind you as soon as you enter the testable process.
        </div>
      </div>
      <div className='market-header-banner'>
        <div className="market-banner-content flex flex-column flex-middle">
          <div className='fz-40 c231 lh-50 market-banner-content-name'>Marketplace</div>
          <div className="c231 fz-20 m-t-8 market-banner-content-data">Create, collect & explore NFTs on Aptos</div>
          <div className="flex">
            <NavLink to="/marketplace" className='color supply-btn c231 fz-14 fwb m-t-30 ta hover' >Explore</NavLink>
            <NavLink to="/marketplace/launchpad" className='color supply-btn c231 fz-14 fwb m-t-30 ta hover m-l-20' >LaunchPad</NavLink>
          </div>
          
        </div>
      </div>
      <div className='market-content'>
        <div className="flex p-t-36 p-b-36 flex-between">
          <div className="marketplace-title fz-24 fwb">Trending collections in the past 24 hours</div>
          <div className="search-input flex flex-center">
              <input type="text" className='flex-1' placeholder='Search'/>
              <div className='p-r-13'>
                  <SearchOutlined style={{fontSize: 20}}/>
              </div>
          </div>
        </div>
        <div className="trend-wrap flex flex-column flex-center">
          <div className="flex w100 trend-inner">
            <div className="left flex-1 p-l-30 p-r-30">
              {
                trendList.map((item, index) => {
                    return index < 5 && <Trend key={index} info={item} idx={index}/>
                })
              }
            </div>
            <div className="right flex-1 p-l-30 p-r-30">
            {
                trendList.map((item, index) => {
                    return index >= 5 && index < 10 && <Trend key={index} info={item} idx={index}/>
                })
              }
            </div>

          </div>
          <Button className='color more-btn my-button m-t-30 m-b-30'>
            See More
          </Button>
        </div>
        
      </div>
    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Marketplace
);