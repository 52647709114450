import './Nft.scss'
import aptoslogo from '../../../assets/image/token/APT.svg'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { Button, Spin } from 'antd'
import { fromUnit } from '../../../lib/util'
import light from '../../../assets/image/marketplace/light.svg'
export default ({info,reLoad}) => {
  let [loading, setLoading] = useState(false)
    return (
         <div className='passport-item flex flex-column flex-middle flex-center p-b-6'>
            <NavLink to={"/marketplace/detail"} className='ta cover-wrap flex flex-center' >
              <img src={info.preview_uri} alt="nft-cover" className='cover'/>

              <div className="buynow-btn fz-14 fwb w100 p-l-6 p-r-6 m-t-12 ">
                <div loading={loading} className="btn-inner color c2b w100 ta pointer flex flex-middle flex-center">
                  {
                    loading? <Spin className='m-r-7'/>:<img src={light} alt="" className='m-r-10'/>
                  }
                  Buy Now
                </div>
               
              </div>
            </NavLink>
            <div className="fz-18 c2b fwb tl w100 p-l-16 p-r-16 m-t-12">{info.metadata.name}</div>
            <div className="fz-14 c2b tl w100 p-l-16 p-r-16 m-t-10 flex flex-center flex-between">
               <span className="flex flex-column">
                <span className="c23 fz-14">Price</span>

                <span className='fz-18 fwb flex  flex-center'>
                  <img src={aptoslogo} alt="kepler-logo" className='apt-logo m-r-4'/>
                  {fromUnit(info.price)} &nbsp;APT
                </span> 

               </span>
               <NavLink className='detail-btn fz-14 ta pointer islink' to={"/marketplace/detail/"}>
                 Detail
               </NavLink>
            </div>

         </div>
    )
}