import './index.scss'
import { connect as reducxConnect } from 'react-redux';
import { Badge, Button, Radio, Select, Spin, Tooltip } from 'antd'
import Project from '../../components/project'
import { NavLink } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import MySelect from '../../components/common/MySelect';
import { loadProjectList } from '../../api/launchpad';
import { setMaxListeners } from 'events';
import Loading from '../../components/common/Loading';
import avatar from '../../assets/image/profile/avatar.png'
import { addPoint, formatName, OpenNotification, showLogin } from '../../lib/util';
import { getNFTList, } from '../../methods/client.ts';
import Modal from '../../components/common/Modal'
import Transfer from './components/transfer';
import selectImg from '../../assets/image/checkbox/selected.png'
import { queryUserPendingClaims ,getMetaDataById, getMetaDataByNative} from '../../methods/nft';
import Tab1 from './Tab1';
import Tab3 from './Tab3';
import Tab2 from './Tab2';


const optionsWithDisabled = (num, value) => [
  {
    label: 'Collected',
    value: '1',
  },
  {
    label: 'Listing',
    value: '2',
  },
  {
    label: <Badge dot={num>0}><span className={value == 3 ?'':'c236'}>Inbox</span></Badge>,
    value: '3'
  },
  {
    label: 'Offers',
    value: '4'
  }
];


// id:token_id;
// isSelected: 是否选中
// showSelect: 是否显示右上角的选择框
const NFT = ({id, isSelected, showSelect, onSelect, onCancel, property_version}) => {
  let [metaData, setMetaData] = useState({})
  useEffect(async() => {
    let {
      data: {current_token_datas}
    } = await getMetaDataById(id)
    console.log(current_token_datas)
    setMetaData({...current_token_datas[0], property_version})
  }, [id])
  return (
     <div className={'nft-wrap flex flex-column '+(isSelected ? 'selected ':'')+(showSelect ? 'pointer':'')} onClick={()=>{
      if(!showSelect) {
        return
      }
      isSelected ? onCancel(metaData):onSelect(metaData)
     }
      }>
      {
        showSelect ?<div className="check-box-area pointer" >
        {
          isSelected ? 
          <img src={selectImg} alt="" className='check-active' />:
          <div className="check-none"></div>
        }
      </div>:''
      }
        
        <img src={metaData.metadata_uri} alt={formatName(metaData.name)} className='nft-cover'/>
        <div className='fz-18 c2b p-t-12 p-l-16 p-b-12 nft-name'>
        <span className='oneline-only w100'>{formatName(metaData.name)}</span>
        </div>
        
     </div>
  )
}
const NFTInBox = ({info}) => {
  let [metaData, setMetaData] = useState({})
  useEffect(async() => {
    let data  = await getMetaDataByNative(info.token_id?.token_data_id?.creator, info.token_id?.token_data_id?.collection, info.token_id?.token_data_id?.name)
    console.log(data)
    setMetaData(data)
  }, [info])
  return (
     <div className={'nft-wrap flex flex-column '}>
        
        <img src={metaData.uri} alt={formatName(metaData.name)} className="nft-cover"/>
        <div className='fz-18 c2b p-t-12 p-l-16 p-b-12 nft-name'>
        <span className='oneline-only w100'>{formatName(metaData.name)}</span>
        </div>
        
     </div>
  )
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})((props) => {
  console.log(props)

  const [value, setValue] = useState('1');
  const [state, setState] = useState(0)
  const [list, setList] = useState([])
  const [inboxList, setInboxList] = useState([])
  const [loading, setLoading] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  const [showTransfer, setShowTransfer] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [refreshTrigger, setRefreshTrigger] = useState(1)
  const RadioDom = ()=> {
    return (
      <>
      <Radio.Group
              className='my-radio-card'
              options={optionsWithDisabled(inboxList?.length, value)}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
      />
      </>
      
    )
  }
  const onChange = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue(value);
  };

  const toCopy = useCallback(async () => {
    if (props.account) {
      await navigator.clipboard.writeText(props.account);
      OpenNotification('success', 'Copy finshed')
    }
  }, [props.account]);

  useEffect(async () => {
    if (props.account) {
      setLoading(true)
      let inlist = await queryUserPendingClaims(props.account)
      console.log(inlist)
      setInboxList(inlist)
      setLoading(false)
    }
  }, [props.account, refreshTrigger])
  return (
    <div className="profile">
      <div className="profile-banner-area w100">
        <div className="profile-banner-content flex flex-column flex-middle"></div>
      </div>
      {
        props.account ? (
          <>
          <div className="profile-info middle-content">
            <img src={avatar} alt="" className="avatar" />
            <div className="fz-32 fwb">AE&CF119</div>
            <div className="fz-16">{addPoint(props.account, 9)}

              <Tooltip title="copy address">
                <CopyOutlined className="pointer m-l-8" onClick={toCopy} />
              </Tooltip>
            </div>
          </div>
          {/* <div className="middle-content flex flex-center flex-between">
            
          </div> */}

          {
            value == 1 ? <Tab1 radioDom={<RadioDom/>}/>:
            value == 2 ? <Tab2 radioDom={<RadioDom/>}/>:
            value ==3 ?<Tab3 radioDom={<RadioDom/>} loading={loading} list={inboxList} onSuccess={() => {setRefreshTrigger(refreshTrigger+1)}}/>:
            value ==4 ?<Tab3 radioDom={<RadioDom/>}  loading={loading} list={inboxList} onSuccess={() => {setRefreshTrigger(refreshTrigger+1)}}/>:''
          }
          </>
        ):(
          <div className="w100 p-t-50 p-b-50 ta">
            <Button className='large-btn color' onClick={showLogin}>Connect Wallet</Button>
          </div>
        )
      }
      
    </div>
  )
});

