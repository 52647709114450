import { Tabs } from 'antd';
import './airdrop.scss'
import { connect as reducxConnect } from 'react-redux'
import { useState } from 'react';
import tw from '../../../assets/image/airdrop/twitter.png'
import package1 from '../../../assets/image/airdrop/package1.png'
import package2 from '../../../assets/image/airdrop/package2.png'
import package3 from '../../../assets/image/airdrop/package3.png'

const packageList = [
    {
        image: package1,
        text: <span>Care Package <span className="fwb">Contains $HARW Tokens</span>, Lesser More Tokens, and Other Supplies</span>
    },
    {
        image: package2,
        text: <span>The number of care packages you getis based on your 6 month tradingactivity across  <span className="fwb">Topaz, Souffl3, BlueMove,OKX-NFT</span> Aidrop1 rarity is random.	</span>
    },
    {
        image: package3,
        text: <span>You have  <span className="fwb">XX days and XX hours</span> to secure your airdrop. Care Packs can be opened when $HARW is released in April.</span>
    }
]
const Airdrop1 = (props) => {
    let [tabvalue, setTabvalue] = useState('1')
    return (
        <div className='airdrop-item w100'>
            <div className="middle-content p-t-60 p-b-100">
                <div className="fz-36 fwb ta">$HARW Token Airdrop 1</div>
                <div className="fz-20 ta text-content p-t-24">
                    The Aptos NFT ecosystem has developed to a stage where a more active MarketPlace market is needed to
                    prosper the Aptos ecosystem. Inspired by the $Blur airdrop, so we are airdropping the Harwell
                    <span className='cr underline'> Care Package </span>
                    to everyone who has traded NFTs on the Aptos NFT marketplace in the past 6 months.
                </div>
                <div className="fz-20 ta text-content fwb p-t-24">
                    Don't be frustrated if you didn't get the Care Pack with AirDrop1 There are multiple ways to get the Harw Care Pack with AirDrop2
                    <span className='cr underline'> Click for details</span>
                </div>
                <div className="flex flex-center flex-column w100">
                    <div className="airdrop-btn fz-18 fwb ta pointer m-t-48">
                        Check Your Airdrop
                    </div>

                    <div className="ta w100 m-t-32 get-package">
                        <img src={tw} alt="" />
                        <span className='cr underline fz-18 m-l-8'>
                            Get the care package
                        </span>

                    </div>
                    <div className="package-items flex m-t-140 gap-50">
                        {
                            packageList.map((item, index) => {
                                return (
                                    <div className="package-item flex-1 flex flex-center flex-column">
                                        <img src={item.image} alt="" className='package-image' />
                                        <div className="w100 fz-16 ta p-l-50 p-r-50 p-t-13 package-text">
                                              {item.text}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>
        </div>
    )
}
export default reducxConnect(
    (state, props) => {
        return { ...state, ...props }
    }
)(
    Airdrop1
);