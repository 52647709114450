import { NavLink } from 'react-router-dom';
import './index.scss';
import { Radio, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import squirrelRight from '../../assets/image/launchpad/squirrel-right.png'
import listtype2 from '../../assets/image/farm/listtype.svg'
import listtype from '../../assets/image/farm/listtype2.svg'
import squaretype from '../../assets/image/farm/squaretype.svg'
import squaretype2 from '../../assets/image/farm/squaretype2.svg'
import { getLpToken, queryPoolInfo , getPendingReward, queryDepositInfo, getApr} from '../../methods/farm';
import { queryDepositInfo as queryDepositInfoFixed, getPendingReward as getPendingRewardFixed, queryPoolInfo as queryPoolInfoFixed ,getApr as getAprFixed} from '../../methods/farmfixed';
import { findAddressByName, fromUnit, toFixed } from '../../lib/util';
import farmlist from '../../contract/farmlist';
import {connect as reducxConnect} from 'react-redux'
import { getAddress } from '../../contract';
import useInterval from '@use-it/interval';
import Activenumber from '../../components/common/Activenumber';
import { queryTokenPairReserve } from '../../methods/swap';
import { queryResource} from '../../methods/client.ts';
import FarmList from './components/FarmList';
import { refresh_interval } from '../../global';
import myContext from './createContext';
const decimal = 6

function Farm(props) {
  const optionsWithDisabled = [
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Ended',
      value: 2,
    },
    // {
    //   label: 'Upcoming',
    //   value: '3'
    // },
    // {
    //   label: 'Vesting',
    //   value: '4'
    // },
  ];
  const optionsSelect = [,
    {
      label: 'Hot',
      value: 1
    },
    {
      label: 'APR',
      value: 2,
    },
    {
      label: 'Total staked',
      value: 3,
    },
    {
      label: 'Earned',
      value: 4,
    },
    {
      label: 'Latest',
      value: 5,
    }
  ];
  const [tipsShow, setTipsShow] = useState(false);
  const [type, setType] = useState(0) // listtye 0:list 1:square
  const [weeklyReward, setWeeklyReward] = useState(0)
  const [harwprice, setPrice] = useState(0)
  const [tvl, setTvl] = useState(0)
  const [refreshTrigger, setRefreshTrigger] = useState(0)
  const [showList, setShowList] = useState(farmlist)
  const [originShowList, setOriginShowList] = useState(farmlist) // 原始列表
  const [detailList, setDetailList] = useState([])
  const [filterText, setFilterText] = useState('')
  const [stakeOnly, setStakeOnly] = useState(false)
  const [status, setStatus] = useState(1)
  const [sort, setSort] = useState(1)
  useEffect(() => {
    let list = [...originShowList]
    console.log(filterText)
    console.log(list)
    console.log(status)
    list = list.filter(item => !filterText ||item.token?.toLowerCase().includes(filterText.toLowerCase())|| item.token1?.toLowerCase().includes(filterText.toLowerCase())||item.token2?.toLowerCase().includes(filterText.toLowerCase()))
    list = list.filter(item => !stakeOnly || item.depositions?.amount > 0)
    list = list.filter(item => (status ==1 && item.end_time*1 > new Date().getTime()/1000) || (status == 2 && item.end_time*1 < new Date().getTime()/1000) || !item.end_time)
    list = list.sort((b, a) => {
      switch (sort) {
        case 1:
          return b.index - a.index;
          break;
        case 2: 
          return a.apr?(a.apr-b.apr):(a.index - b.index);
          break;
        case 3: 
          return a.tvl?(a.tvl-b.tvl):(a.index - b.index);
          break;
        case 4: 
          return a.depositions?.amount?(a.depositions?.amount-b.depositions?.amount):(a.index - b.index);
          break;
        case 5: 
          return a.start_time?(a.start_time-b.start_time):(a.index - b.index);
          break;
        default:
          return a.index - b.index;
      }
    })
    setShowList(list)
  }, [filterText, originShowList, stakeOnly,status, sort])


  useEffect(async() => {
    // 获取harw 和池子的单价
    let {reserve_x, reserve_y}  = await queryTokenPairReserve(findAddressByName('HARW'), findAddressByName('USDC'))
    let priceHarw = reserve_y/reserve_x
    let supply = await queryResource(getAddress()['hwswap']['swap'].split('::')[0], `0x1::coin::CoinInfo<${getLpToken(findAddressByName('HARW'), findAddressByName('USDC'))}>`)
    let lptotal = supply.data.supply.vec[0].integer.vec[0].value
    let lpprice = reserve_y*2/lptotal
    
    let total_tvl = 0
    let weekreward = 0
    let list = [...farmlist]
     // 先获取池子信息
     let p = []
     farmlist.map(item => {
      let depositToken = item.lptype == 'LPToken' ? (getLpToken(findAddressByName(item.token1) , findAddressByName(item.token2))):findAddressByName(item.token)
      if(item.type == 'fixed') {
        p.push(queryPoolInfoFixed(depositToken, findAddressByName(item.rewardToken)))
      } else {
        p.push(queryPoolInfo(depositToken, findAddressByName(item.rewardToken)))
      }
     })
     let poolsdata = await Promise.all(p)
     list = list.map((item, index) => {
       item = {...item, ...poolsdata[index].data}
       // 重置仓位数量及reward数量
       item.pending_reward = 0
       item.last_harvest_time = 0
       item.depositions = item.type == 'fixed' ? {
        amount: 0,
        last_harvest_time: 0,
        pending_reward: 0,
        reward_index: 0,
        deposit_id: null,
        deposit_time: null,
        deposit_weight: null,
        lock_units: null,
        withdraw_time: null
      }:{
          amount: 0,
          last_harvest_time: 0,
          pending_reward: 0,
          reward_index: 0
        }
       if(Number(item.end_time) < Number(new Date().getTime()/1000)) { // 如果池子到期，每周释放量显示为0
        item.rewards_per_week = 0
       }
       // 计算tvl
       if(item.lptype == 'LPToken') {
          item.tvl = item.total_deposits*lpprice
       } else {
        item.tvl = item.total_deposits*priceHarw
       }
       total_tvl += item.tvl*1
       weekreward += item.rewards_per_week*1
       return item
     })
     setTvl(total_tvl)
     setWeeklyReward(weekreward)
     setPrice(priceHarw)

     // 计算apr
     let apr_p = []
     list.map(item => {
       if(item.type == 'fixed') {
          apr_p.push(getAprFixed(item))
       } else {
        apr_p.push(getApr(item))
       }
     })
     let apr_result = await Promise.all(apr_p)
     list = list.map((item, index) => {
      item.apr = apr_result[index]
      return item
    })
     if(props.account) { // 如果已经登陆
      let deposit_p = []
      let pending_p = []
      list.map(item => {
        let depositToken = item.lptype == 'LPToken' ? (getLpToken(findAddressByName(item.token1) , findAddressByName(item.token2))):findAddressByName(item.token)
        
        if(item.type =='fixed') {
          deposit_p.push(queryDepositInfoFixed(props.account, depositToken, findAddressByName(item.rewardToken)))
          pending_p.push(getPendingRewardFixed(props.account, depositToken, findAddressByName(item.rewardToken)))
        } else {
          deposit_p.push(queryDepositInfo(props.account, depositToken, findAddressByName(item.rewardToken)))
          pending_p.push(getPendingReward(props.account, depositToken, findAddressByName(item.rewardToken)))
        }
      })
      let deposit_result = await Promise.all(deposit_p)
      let pending_result = await Promise.all(pending_p)
      list = list.map((item, index) => {
        if(item.type == 'fixed') {
          let position_amount = 0
          deposit_result[index]?.data.depositions.map(item => {
            position_amount += item.amount*1
          })
          item.depositions = {
            amount:position_amount
          }
          item.deposit_list = deposit_result[index]?.data.depositions
          item.pending_reward = pending_result[index].pending_reward||0
          item.pending_rewards = pending_result[index].pending_rewards||[]
        } else {
           item.depositions = deposit_result[index]?.data||{
            amount: 0,
            last_harvest_time: 0,
            pending_reward: 0,
            reward_index: 0
          }
          item.pending_reward = pending_result[index]||0
        }
        
        return item
      })
    }

    setShowList(list)
    setOriginShowList(list)




  }, [farmlist, props.account, refreshTrigger])
  useInterval(() => {
      setRefreshTrigger(refreshTrigger + 1);
    }, refresh_interval);

  return (
    <div className="farm p-b-140">
      <div className="warning-tips">
        <div className="inner ta">
          <div className='fwb'>Warning Tips</div>
          The Farm module is still in the process of dynamic adjustment. Any problems you encounter at present are normal. It is expected to improve on 2.20 and enter the normal user experience.
        </div>
      </div>
      <div className='farm-header-banner'>
        <div className="farm-banner-content flex flex-column flex-middle">
          <div className='fz-40 c231 lh-50 farm-banner-content-name'>Farm</div>
          <div className="c231 fz-20 m-t-8 farm-banner-content-data">Multiple staking options for you to earn more rewards</div>
          {/* <NavLink to="/submit" className='color supply-btn c231 fz-14 fwb m-t-30 ta hover' >Supply</NavLink> */}
        </div>
      </div>
      {/* <div className='summary-data flex gap-29'>
        <div className='summary-data-item flex-1 flex flex-column flex-middle bgf'>
          <span className='fz-16 lh-18 summary-data-item-title'>Total Value Locked</span>
          <span className='fz-32 fwb lh-28 c2b m-t-16 summary-data-item-data'>$
          <Activenumber value={fromUnit(tvl)} decimals={4}/></span>
        </div>
        <div className='summary-data-item flex-1 flex flex-column flex-middle bgf'>
          <span className='fz-16 lh-18 summary-data-item-title'>HARW Price</span>
          <span className='fz-32 fwb lh-28 c2b m-t-16 summary-data-item-data'>$
          <Activenumber value={harwprice} decimals={6}/></span>
        </div>
        <div className='summary-data-item flex-1 flex flex-column flex-middle bgf'>
          <span className='fz-16 lh-18 summary-data-item-title'>Weekly Rewards</span>
          <span className='fz-32 fwb lh-28 c2b m-t-16 summary-data-item-data'>
            <Activenumber value={fromUnit(weeklyReward)} decimals={0}/> HARW</span>
        </div>

        <div className='summary-data-item flex-1 flex flex-column flex-middle bgf'>
          <span className='fz-16 lh-18 summary-data-item-title'>24h Rewards</span>
          <span className='fz-32 fwb lh-28 c2b m-t-16 summary-data-item-data'>
          <Activenumber value={fromUnit(weeklyReward/7)} decimals={0}/> HARW</span>
        </div>
      </div> */}
      <div className='farm-content'>
        {/* <div className='p-content-txt'>
          <div className='p-txt-header flex flex-between flex-center pointer' onClick={() => setTipsShow(val => !val)}>
            <div className='flex flex-center p-text-header-left'>
              <img className='left-songshu' src={songshu} alt="" />
              <span className='fz-16 lh-20 m-l-8'>All farms are setup using one of our two innovative farming mechanisms. Read the tips below to maximize  your farming rewards!</span>
            </div>
            <ShangjiantouHuaban
              className={classNames('jiantou-qiehuan-icon', {
                'rotate180': tipsShow
              })}
              width={12}
              height={7}></ShangjiantouHuaban>
          </div>
          {
            tipsShow && (
              <div className='m-t-10'>
                · Farms using this mechanism rely on the total time your liquidity position is active (i.e. in range) in the pool.
                · Once you stake your liquidity position into a farm, your farming rewards are calculated based on the time your liquidity position is currently active and supporting the current market price of the pool.
                · If your liquidity position goes out of range (i.e. becomes inactive), you will stop accumulating farming rewards. You have 2 options:
                ·
              </div>
            )
          }
        </div> */}
        <div className='flex flex-between flex-center m-t-60 farm-search-left'>
          <div className="flex flex-center">
            <Radio.Group
              className='my-radio-card'
              options={optionsWithDisabled}
              onChange={(v) =>setStatus(v.target.value)}
              value={status}
              optionType="button"
              buttonStyle="solid" />
            <div className='list-type flex pointer'>
              <img src={!type ? listtype2 : listtype} alt="" className='m-l-20' onClick={() => { setType(0) }} />
              <img src={type ? squaretype2 : squaretype} alt="" className='m-l-15' onClick={() => { setType(1) }} />
              
            </div>
          </div>
          <div className="flex flex-center farm-search-left ">
            <div className='fz-14 lh-18'>
              <span>Total Value Locked:</span>
              <span className='fwb'> $<Activenumber value={fromUnit(tvl)} decimals={0}/></span>
            </div>

            <div className='fz-14 lh-18 m-l-20 m-r-20 m-t-5 m-b-5'>
              <span>HARW Price:</span>
              <span className='fwb'> $<Activenumber value={(harwprice)} decimals={4}/></span>
            </div>

            <div className='fz-14 lh-18'>
              <span>Weekly Rewards:</span>
              <span className='fwb'> <Activenumber value={fromUnit(weeklyReward)} decimals={0}/> HARW</span>
            </div>
          </div>
          

          {/* <div className='fz-14 lh-18'>
            <span>Total Value Locked:</span>
            <span className='fwb'> $<Activenumber value={fromUnit(tvl)} decimals={4}/></span>
          </div> */}
        </div>
        <div className='flex flex-center flex-between m-t-22 search-condition'>

          <div className="search-input flex flex-center m-r-26 ">
            <input type="text" className='flex-1' placeholder='Search by token name' onChange={(e) =>setFilterText(e.target.value)}/>
            <div className='p-r-13'>
              <SearchOutlined style={{ fontSize: 20 }} />
            </div>
          </div>
          <div className='flex flex-center search-right'>
            <div className='search-right-switch'>
              <Switch className='my-switch' checked={stakeOnly} onChange={(value) => setStakeOnly(value)} />
              <span className='fz-14 m-l-5'>Staked Only</span>
            </div>
            <span className='flex flex-center sort-select'>
              <span className='c23 fz-14 m-r-8 m-l-30 sortby'>Sort by</span>
              <Select defaultValue="1" className='my-select flex flex-middle flex-center' style={{ width: 120 }} value={sort} onChange={v =>setSort(v)} suffixIcon={
                <img src={require('../../assets/image/common/down.svg').default}></img>
              } >
                {
                  optionsSelect.map(item => {
                    return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                  })
                }
              </Select>
            </span>
            {/* <span className='flex flex-center sort-select'>
              <span className='c23 fz-14 m-r-8 m-l-24 sortby'>APR Basis</span>
              <Select defaultValue="1" className='my-select flex flex-middle flex-center' style={{ width: 120 }} suffixIcon={
                <img src={require('../../assets/image/common/down.svg').default}></img>
              } >
                {
                  optionsSelect.map(item => {
                    return <Select.Option value={item.value}>{item.label}</Select.Option>
                  })
                }
              </Select>
            </span> */}
            {/* <div className='refresh-btn pointer' onClick={()=>setRefreshTrigger(refreshTrigger + 1)}>
              <RedoOutlined />
            </div> */}
          </div>
        </div>
        <div className='flex flex-center flex-between m-t-30'>
          <div className='flex flex-column'>
            <span className='fz-14 fwb lh-18'>Farms will run in multiple phases</span>
            <span className='fz-14 lh-18'>Once the current phase ends, you can harvest your rewards from the farm in the Ended tab. To continue earning rewards in the new phase, you must restake your NFT position into the active farm</span>
          </div>
          <img className='squirrel-right' src={squirrelRight} alt="" />
        </div>
        <myContext.Provider value={{forceRefresh: ()=>{
          console.log('//////////我执行到位了/////////////')
          setRefreshTrigger(refreshTrigger+1)
          }}}>
            <FarmList farmlist={showList} type={type} account={props.account}/>
        </myContext.Provider>
      </div>
    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Farm
);