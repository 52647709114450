import classNames from 'classnames';
import { useState } from 'react';
import exclamatoryMark2 from '../../../assets/image/launchpad/exclamatory-mark-2.svg';
import close from '../../../assets/image/common/close.svg';
import coin1 from '../../../assets/image/launchpad/coin-1.png'
import xingNo from '../../../assets/image/launchpad/xing-no.svg'
import magnifier from '../../../assets/image/launchpad/magnifier.svg'
import './SelectToken.scss';
import Item from 'antd/lib/list/Item';
import { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {getBalance, toRegister} from '../../../methods/client.ts'
import {connect as reducxConnect} from 'react-redux'
import { findAddressByName, fromUnit, OpenNotification } from '../../../lib/util';
import list from './list'
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { explorerUrl } from '../../../contract';

function SelectToken(props) {
  const { closeFn } = props;
  let {signAndSubmitTransaction} = useWallet()
  const [checkValue, setCheckValue] = useState('USDC');
  const [tokenList, setTokenList] = useState([])
  const [filter, setFilter] = useState('')
  const toSelectToken = (name) => {
    setCheckValue(name)
    props.selectToken(name)
    closeFn()
  }
  const goRegister = async (name) => {
    let tem_list = [...tokenList]
    tem_list.map(item => {
      if(item.title == name) {
        item.registerLoading = true
      }
      return item
    })
    setTokenList(tem_list)
    await toRegister(name, signAndSubmitTransaction, (hash)=> {
      let temp_list = [...list]
      temp_list.map(item => {
        if(item.title == name) {
          item.registerLoading = false
          item.balance = 0
        }
        return item
      })
      setTokenList(temp_list)
      OpenNotification('success', 'Rsgister Success', <a target="_blank" href={`${explorerUrl}${hash}`}>Go to browser to view</a>)
    }, () => {
      let temp_list = [...list]
      temp_list.map(item => {
        item.registerLoading = false
        return item
      })
      setTokenList(temp_list)
      OpenNotification('error', 'Rsgister Failed', 'Please try again')
    })
    
  }
  useEffect(() => {
     if(props.account) {
       list.map(item => {
        item.loading = true
        return item
       })
       setTokenList(list)
       let p = []
       list.map(item => {
        p.push(getBalance(props.account, findAddressByName(item.title)))
       })
       Promise.all(p).then(res => {
        console.log(res)
        list.map((item, index) => {
          item.balance = res[index] == null ? null:fromUnit(res[index])
          item.loading = false
          console.log(item)
          return item
        })
        setTokenList([...list].sort((a, b)=>{return b.balance - a.balance}))
       })
     } else {
      list.map(item => {
        item.balance = 0
        item.loading = false
        return item
      })
      setTokenList(list)
     }
  }, [props.account])
  return (
    <div className={"mask flex flex-center flex-middle " + (props.show ? 'show':'hide')}>
      <div className="select-token-box bgf">
        <div className='p-l-30 p-r-30'>
          <div className='flex flex-between flex-center '>
            <div className='flex'>
              <span className='fz-24 fwb c2b lh-28 m-r-8'>Select a token</span>
              <img src={exclamatoryMark2} alt="" />
            </div>
            <img className='pointer' src={close} onClick={ev => {
              closeFn(false)
          }} alt="" />
          </div>
          <p className='fz-14 lh-24 m-t-6 m-b-16 c2b'>You can search and select any token on harswap</p>
          <div className='search-token flex flex-between flex-center'>
            <input type="text" className='flex-1' placeholder='Token name, token symbol or address' onChange={e => setFilter(e.target.value)} />
            <img src={magnifier} alt="" />
          </div>
          {/* <div className='flex flex-wap gap-8 m-t-16'>
            <div className='history-token-btn flex flex-center'>
              <img className='m-r-8' src={coin1} alt="" />
              <span className='fz-16 fwb lh-20'>BNB</span>
            </div>
          </div>
          <div className='m-t-24'>
            <span className='fz-16 c2b fwb lh-26 m-r-11'>All</span>
            <span className='fz-16 lh-26'>Imported</span>
          </div> */}
        </div>
        <div className='m-t-24'>
          {
            tokenList.map((el, i) => (
              <div key={i} className={classNames('select-token-item flex flex-between flex-center', {
                'check': checkValue === el.title,
                'hide': filter && !el.title.toLowerCase().includes(filter.toLowerCase()),
                'disabled': props.type == 'input' && el.title == props.output || props.type == 'output' && el.title == props.input
              })}
              onClick={() => {
                if(props.type == 'input' && el.title == props.output || props.type == 'output' && el.title == props.input) {
                  return
                }
                toSelectToken(el.title)
              } }>
                <div className='flex flex-center'>
                  <img className='m-r-8 token-icon' src={el.icon} alt="" />
                  <div className='flex flex-column'>
                    <span className='c2b fz-16 fwb lh-20'>{el.title}</span>
                    <span className='c2b fz-16 lh-20'>{el.desc}</span>
                  </div>
                </div>
                <div>
                  {
                    el.loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#ccc'}} spin />}/>:
                    el.balance == null ? <Button className="color m-l-10 register-btn" loading={el.registerLoading} onClick={(e)=> {
                      e.stopPropagation()
                      goRegister(el.title)
                    }}>Register</Button>:<span className='c2b fz-16 lh-20 fwb'>{el.balance||'0'}</span>
                  }
                  {
                    
                  }
                  
                  {/* <img className='m-l-24' src={xingNo} alt="" /> */}
                </div>
              </div>
            ))
          }
        </div>
        {/* <div className='manage-tokens m-t-23'>Manage Tokens</div> */}
      </div>
    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  SelectToken
);