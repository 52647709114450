import './index.scss'
import { connect as reducxConnect } from 'react-redux';
import { Button } from 'antd'
import { useEffect, useState } from 'react';
import Loading from '../../components/common/Loading';
import { formatName, fromUnit } from '../../lib/util';
import { getNFTList} from '../../methods/client.ts';
import { getMetaDataById, getMetaDataByNative, queryListData, queryUserList} from '../../methods/nft';
import Modal from '../../components/common/Modal'
import Transfer from './components/transfer';
import selectImg from '../../assets/image/checkbox/selected.png'
import { useNavigate } from "react-router-dom";
import { getListData } from '../../api/nft';
import Nft from './components/Nft';
import { useSubmitTransiction } from '../../methods/submit';
import { getAddress } from '../../contract';
import Edit from './components/edit';
import { RedoOutlined } from '@ant-design/icons';




// id:token_id;
// isSelected: 是否选中
// showSelect: 是否显示右上角的选择框

export default reducxConnect((state, props) => {
  return { ...state, ...props };
})((props) => {

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [delistloading, setDelistloading] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [refreshTrigger, setRefreshTrigger] = useState(1)
  const { submitTransiction } = useSubmitTransiction()
const toSelect = (item) => {
  let items = [...selectedItems]
  !items.some(inner => (inner.collection+inner.name) == (item.collection+item.name)) && items.push(item)
  console.log(item)
  console.log(items)
  setSelectedItems(items)
}
const toCancel = (item) => {
  let items = [...selectedItems]
  items = items.filter(inner => (inner.collection+inner.name) != (item.collection+item.name))
  setSelectedItems(items)
}
const toDelist =  () => {
  if (delistloading) {
    return
  }
  setDelistloading(true)
  let payload = {
    type: "script_function_payload",
    function: `${getAddress().marketplace.address}::batch_delist_script`,
    type_arguments: [],
    arguments: [
      selectedItems.map(item => item.creator),
      selectedItems.map(item => item.collection),
      selectedItems.map(item => item.name),
      selectedItems.map(item => item.property_version)
    ],
  };
  console.log(payload)
  submitTransiction(payload, () => {
    setDelistloading(false)
    setSelectedItems([])
    setRefreshTrigger(refreshTrigger+1)
  }, () => {
    setDelistloading(false)
  })
}

  useEffect(async () => {
    if (props.account) {
      setLoading(true)
      let listdata = await getListData({lister: props.account})
      let list_origin = listdata?.data?.data
      console.log(list_origin)
      let list_p = []
      list_origin.map(item => {
        list_p.push(getMetaDataByNative(item.creator, item.collection, item.name))
      })
      let list_p_result = await Promise.all(list_p)
      console.log(list_p_result)
      list_origin = list_origin.map((item, index) => {
        item = {...item, ...list_p_result[index], price: fromUnit(item.price)}
        return item
      })
      setList(list_origin)
      setLoading(false)
    }
  }, [props.account, refreshTrigger])

  return (
      <div className='w100'>
        <div className="middle-content search-right flex flex-between gap-20 flex-center">
        {
          props.radioDom
        }
        <div className='flex flex-center gap-20'>
         {
          list?.length ? (
            <div>
            {
              showSelect ? 
              <>
              <Button className='my-button color' disabled={!selectedItems.length} loading={delistloading} onClick={toDelist}>Delist selected {selectedItems.length} items</Button>
              <Button className='my-button color m-l-10' disabled={!selectedItems.length} onClick={()=>setShowEdit(true)}>Edit price selected {selectedItems.length} items</Button>
              <Button className='my-button m-l-10' onClick={()=>{setShowSelect(false);setSelectedItems([])}}>Cancel</Button>
              </>
              
              :
              <Button className='my-button color' onClick={()=>setShowSelect(true)}>Edit</Button>
            }
            
            </div>
          
          ):''
        }
        <RedoOutlined spin={loading} style={{fontSize: '30px',color: 'rgba(0,0,0,.4)'}} className="pointer" onClick={()=>setRefreshTrigger(refreshTrigger+1)}/>
        
        </div>
        </div>
      <div className={'middle-content flex  w100 m-t-10 m-b-10 ' + (showSelect ? 'flex-between':'flex-last')} >
        <div className='select-all' onClick={
          () => {
            selectedItems.length == list.length ? setSelectedItems([]):setSelectedItems(list)
          }
        }>
          {
          showSelect ?<div className="check-box-area pointer flex flex-center" >
          {
            selectedItems.length == list.length ? 
            <img src={selectImg} alt="" className='check-active' />:
            <div className="check-none"></div>
          }
          
            <span className='m-l-8'>{selectedItems.length == list.length ? 'Cancel All':'Select All'}</span>  
          
        </div>:''
        }
        </div>
       
          
      </div>
      <div className="project-list flex flex-wrap flex-start  gap-30 ">
        {loading ?
              <div className="flex flex-center flex-middle p-t-120 p-b-280 w100">
                <Loading />
              </div> :(
                <>{
                  list?.map((item, index) => {
                        return <Nft info={item} showSelect={showSelect} isSelected={selectedItems.some(inner => (inner.collection+inner.name) == (item.collection+item.name))} onSelect={toSelect} onCancel={toCancel}/>
                  })
                }
                {list?.length ? '':<div className='w100 ta c236 fz-14 p-t-50'>No NFT</div>}
                </>
                 
                )}
      </div>

      <Modal isVisible={showEdit} title="List for sell" onClose={() => setShowEdit(false)}
        info="You're going to sell these NFTs in the marketplace, and if someone buys them, you're going to get the price you set after deducting some fees" border>
        <Edit selectList={selectedItems} onClose={() => {setShowEdit(false);setRefreshTrigger(refreshTrigger+1);setSelectedItems([])}}/>
      </Modal>
    </div>
  )
});

