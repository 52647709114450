import { useState } from 'react';
import { connect as reducxConnect } from 'react-redux';
function RewardFAQ(props) {
  const [faqList, setFaqList] = useState([
    {
      title: 'How to speed up my unlocker?',
      content:
        'The more HARW-USDC LP you staked in unlocker pool ,the more speed you can get to speed up your unlocker.',
    },
    {
      title: 'How to speed up my unlocker?',
      content:
        'The more HARW-USDC LP you staked in unlocker pool ,the more speed you can get to speed up your unlocker.',
    },
    {
      title: 'How to speed up my unlocker?',
      content:
        'The more HARW-USDC LP you staked in unlocker pool ,the more speed you can get to speed up your unlocker.',
    },
  ]);
  return (
    <div className="faq">
      <div className="label fwb">FAQ</div>
      {faqList.map((item, index) => {
        return (
          <div className="faq-wrapper" key={'faq'+index}>
            <span className="ind">{index + 1}</span>
            <div className="content">
              <div className="title fwb">{item.title}</div>
              <div className="text">{item.content}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(RewardFAQ);
