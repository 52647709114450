import { useState } from 'react';
import { connect as reducxConnect } from 'react-redux';
import { Button, Table } from 'antd';
import icon2 from '../../../assets/image/reward/reward-icon-2.svg';
function RewardSwapList(props) {
  const columns = [
    {
      title: 'Transction Name',
      dataIndex: 'transctionName',
      key: 'transctionName',
      width: '25%',
    },
    {
      title: 'Transiction Hash',
      dataIndex: 'transictionHash',
      key: 'transictionHash',
      width: '25%',
    },
    {
      title: 'Transiction time',
      dataIndex: 'transictionTime',
      key: 'transictionTime',
      width: '25%',
    },
    {
      title: 'Reward Amount',
      dataIndex: 'rewardAmount',
      key: 'rewardAmount',
      width: '25%',
      render: (text) => <span>{text} HARW</span>,
    },
  ];
  const [tableData, setTableData] = useState([
    {
      key:1,
      transctionName: 'Swap 1000 HARW to 20',
      transictionHash: '0x12312…234234',
      transictionTime: '2023-02-21 09:00:03',
      rewardAmount: '5',
    },
  ]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 23,
  });
  const claim = () => {};
  const onChange = (pagination, filters, sorter, extra) => {};
  return (
    <div className="reward-list reward-farm-list">
      <div className="header-wrapper">
        <img src={icon2} className="logo" alt="" />
        <div className="content-wrapper">
          <div className="fwb">Swap</div>
          <div>
            <span>Remain reward: </span>
            <span className="fwb">20300 HARW</span>
          </div>
          <Button className="claim-btn">Claim</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...pagination,
          showTotal: (total) => `Total Page ${total}`,
          showQuickJumper: true,
          hideOnSinglePage: true,
        }}
        onChange={onChange}
        className="reward-table"
      />
    </div>
  );
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(RewardSwapList);
