import './Liquidity.scss'
import Pinecone from '../../../assets/image/launchpad/min-pinecone.svg'
import positionIcon from '../../../assets/image/launchpad/position-icon.png';
import bottomIcon from '../../../assets/image/launchpad/bottom-icon.svg';
import squirrelLeft from '../../../assets/image/launchpad/squirrel-left.png'
import squirrelRight from '../../../assets/image/launchpad/squirrel-right.png'
import settingIcon from '../../../assets/image/launchpad/shezhi.svg';
import setting2Icon from '../../../assets/image/launchpad/shijian (2).svg';
import Coin1 from '../../../assets/image/launchpad/coin-1.png'
import Coin2 from '../../../assets/image/launchpad/coin-2.png'
import { useEffect, useState } from 'react';
import jiahao from '../../../assets/image/launchpad/jiahao.svg';
import question from '../../../assets/image/common/question.svg'
import fanhui from '../../../assets/image/common/fanhui.svg'
import {connect as reducxConnect} from 'react-redux'
import { getLPBalance, queryResource } from '../../../methods/client.ts';
import { getTokenByName } from './list';
import { findAddressByName, fromUnit, OpenNotification, showLogin, testInput, toFixed, toUnit, UNIT_DECIMAL } from '../../../lib/util';
import { explorerUrl, getAddress } from '../../../contract';
import { queryTokenPairMetadata, queryTokenPairReserve } from '../../../methods/swap';
import { Spin, Button, Skeleton } from 'antd';
import SelectToken from './SelectToken';
import {getBalance, isRegistered} from '../../../methods/client.ts'
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import Loading from '../../../components/common/Loading';
import { useLocation } from 'react-router-dom';
const option = [
  {
    label: '25%',
    value: '25',
  },
  {
    label: '50%',
    value: '50',
  },
  {
    label: '75%',
    value: '75',
  },
  {
    label: 'Max',
    value: '100',
  },
]
const AddLiquidity = (props) => {
  
  const [buyer, setBuyer]  = useState('')
  let {signAndSubmitTransaction} = useWallet()
  const [seller, setSeller]  = useState('')
  const [selectType, setSelectType] = useState('input')
  const [showSelect, setShowSelect] = useState(false)
  const [inputToken, setInputToken] = useState(props.input||'APT')
  const [outToken, setOutToken] = useState(props.output||'HARW')
  const [inputBalance, setinputBalance] = useState(0)
  const [outBalance, setOutBalance] = useState(0)
  const [price, setPrice] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [reservex, setReserveX] = useState(0)
  const [loading, setLoading] = useState(false)
  const [hasPair, setHaspair] = useState(false)
  const [loadingInputBalance, setLoadingInputBalance] = useState(false)
  const [loadingOutputBalance, setLoadingOutputBalance] = useState(false)
  const confirmSelectToken = (name, type) => {
    type == 'input' ? setInputToken(name):setOutToken(name)
   }
  const toSelect = (type) => {
    setSelectType(type)
    setShowSelect(true)
  }
  const toAdd = async() => {
    setLoading(true)
    console.log(buyer)
    console.log(seller)
      let payload = {
        "function": getAddress()['hwswap']['router']+'::add_liquidity',
        "type_arguments": [
         findAddressByName(inputToken),
         findAddressByName(outToken)
        ],
        "arguments": [
          toUnit(toFixed(buyer, UNIT_DECIMAL)),
          toUnit(toFixed(seller, UNIT_DECIMAL)),
          // 200000000,
          // 200000000,
          0,
          0
        ],
        "type": "entry_function_payload"
      }
      console.log(payload)
      try {
      const { hash } = await signAndSubmitTransaction(payload);
      console.log(hash)
      OpenNotification('success', 'Transaction Succeed', <a href={`${explorerUrl}${hash}`} target="_blank">View on explorer </a>)
      setLoading(false)
      setRefresh(refresh+1)
      setBuyer('')
      setSeller('')
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
   // 获取balance
 useEffect( async () => {
  if(props.account) {
    setLoadingInputBalance(true)
    let bal = await getBalance(props.account, findAddressByName(inputToken))
    setinputBalance(bal)
    setLoadingInputBalance(false)
  } else {
    setinputBalance(0)
  }
 }, [inputToken, props.account, refresh])

 useEffect( async () => {
  if(props.account) {
    setLoadingOutputBalance(true)
    let bal = await getBalance(props.account, findAddressByName(outToken))
    setOutBalance(bal)
    setLoadingOutputBalance(false)
  } else {
    setOutBalance(0)
  }
 }, [outToken, props.account, refresh])

 useEffect(async () => {
  try {
    let {reserve_x, reserve_y}  = await queryTokenPairReserve(findAddressByName(inputToken), findAddressByName(outToken))
    let data = await queryTokenPairMetadata(findAddressByName(inputToken), findAddressByName(outToken))
    console.log(data)
     if(findAddressByName(inputToken) < findAddressByName(outToken)) {
       setPrice(toFixed(reserve_y/reserve_x, 4))
       setReserveX(reserve_x)
     } else {
       setPrice(toFixed(reserve_x/reserve_y,4))
       setReserveX(reserve_y)
     }
     setHaspair(true)
  } catch(err) {
    setHaspair(false)
    setPrice(1)
    setReserveX(1)
  }
  
 
  }, [inputToken, outToken, refresh])
  return (
    <div className='add-liquidity'>
      <div className='flex flex-center'>
        <img className='m-r-20 pointer' src={fanhui} alt="" onClick={props.Cancel}/>
        <span className='fz-24 fwb c2b'>Add Liquidity</span>
        <img className='m-l-8' src={question} alt="" />
      </div>
      <span className='c2b fz-14 lh-18 m-l-38'>Provide liquidity to earn trading fees</span>
      <div className='liquidity-from m-t-24'>
        <div className='flex flex-last'>
          <span className='fz-14 c2b lh-18'>Balance: {
                loadingInputBalance ? <Skeleton.Button active size={'small'} />:(fromUnit(inputBalance)||'0')}</span>
        </div>
        <div className='m-t-14 flex flex-between flex-center'>
        <input
              className='c2b fz-24 fwb lh-28 flex-1 flex w100 com_input'
              type="text"
              placeholder='0'
              value={buyer}
              onChange={(e) => {
                if (testInput(e.target.value)) {
                  return
                }
              setBuyer(e.target.value);hasPair &&  setSeller(e.target.value*price);}}/>
          <div className='flex flex-center   p-l-5 p-r-5' onClick={()=> toSelect('input')}>
            {/* <span className='c2b fz-14 m-r-16'>~$1,445</span> */}
            <div className='switch-coin flex flex-center flex-middle' >
            <img src={getTokenByName(inputToken).icon} alt="token icon" className='token-icon'/>
            <span className='c2b fz-18 m-l-7 m-r-12'>{inputToken}</span>
            <img src={bottomIcon} alt="" />
            </div>
          </div>
        </div>
        <div className='flex m-t-11 gap-19'>
          {
            option.map(el => {
              return (
                <div className='radio-btn flex-1 c2b fz-12 pointer'
                key={el.label} 
                onClick={
                  () => {
                    setBuyer(fromUnit(inputBalance)*el.value/100);
                    hasPair && setSeller(fromUnit(inputBalance)*el.value/100*price)
                  }
                }
                >{el.label}</div>
              )
            })
          }
        </div>
      </div>
      <div className='add-icon flex flex-middle'>
        <img src={jiahao} alt="" />
      </div>
      <div className='liquidity-from'>
        <div className='flex flex-last'>
          <span className='fz-14 c2b lh-18'>Balance: {
          loadingOutputBalance ? <Skeleton.Button active size={'small'} />:
          (fromUnit(outBalance)||'0')}</span>
        </div>
        <div className='m-t-14 flex flex-between flex-center'>
        <input
              className='c2b fz-24 fwb lh-28 flex-1 flex w100 com_input'
              type="text"
              placeholder='0'
              value={seller}
              onChange={(e) => {
                if (testInput(e.target.value)) {
                  return
                }
                setSeller(e.target.value);
                hasPair && setBuyer(e.target.value/price);}}/>
          <div className='flex flex-center' onClick={()=>toSelect('output')}>
            {/* <span className='c2b fz-14 m-r-16'>~$1,445</span> */}
            <div className='switch-coin flex flex-center flex-middle'>
                <img src={getTokenByName(outToken).icon} alt="token-icon" className='token-icon'/>
                <span className='c2b fz-18 m-l-7 m-r-12'>{outToken}</span>
              <img src={bottomIcon} alt="" />
            </div>
          </div>
        </div>

        <div className='flex m-t-11 gap-19'>
          {
            option.map(el => {
              return (
                <div className='radio-btn flex-1 c2b fz-12 pointer' key={el.label} 
                onClick={
                  () => {
                    setSeller(fromUnit(outBalance)*el.value/100);
                    hasPair && setBuyer(fromUnit(outBalance)*el.value/100/price)
                  }
                }
                >{el.label}</div>
              )
            })
          }
        </div>
      </div>
      <p className='flex flex-between m-t-24 m-b-1 p-l-20 p-r-16'>
        <span className='fz-14'>Prices</span>
        {
          hasPair ? <span>1 {inputToken} = {price} {outToken}</span>:
          <span>1 {inputToken} = {seller/buyer} {outToken}</span>
        }
        
      </p>
      <p className='flex flex-between p-l-20 p-r-16'>
        <span className='fz-14'>Share of Pool</span>
        <span>{buyer ? toFixed(buyer*100/(fromUnit(reservex)*1+buyer*1), 2):'--'}%</span>
      </p>
      {
        props.account ? <Button loading={loading} className='unlock-wallet fz-18 fwb' onClick={() => {
          toAdd()
        }}>Add Liquidity</Button>:
        <Button className='unlock-wallet fz-18 fwb pointer' onClick={() => {
          showLogin()
        }}>Connect Wallet</Button>
      }
      {
            <SelectToken closeFn={setShowSelect} input={inputToken} output={outToken} selectToken={(name)=>confirmSelectToken(name, selectType)} show={showSelect} type={selectType}></SelectToken>
        }
    </div>
  )
}

function Liquidity(props) {
  const [showAdd, setShowAdd] = useState(false);
  const [linkShow, setLinkShow] = useState(false);
  const [openValue, setOpenValue] = useState('')
  const [LiquidityList, setLiquidityList] = useState([])
  const [loading, setLoading] = useState(false)
  const [removeloading, setRemoveLoading] = useState(false)
  const [addInput, setAddInput] = useState('')
  let {signAndSubmitTransaction} = useWallet()
  const [addOutput, setAddOutput] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [removePercent, setRmovePercent] = useState(0)
  const [showRemove, setShowRemove] = useState(false)
  
  let location = useLocation()
  let [coinType, setCoinType] = useState(location.search ? location.search.replace('?','').split('=')[2]?.toUpperCase():'')


  const toRemove = async(inputToken, outToken, value) => {
    setRemoveLoading(true)
    let payload = {
      "function": getAddress()['hwswap']['router']+'::remove_liquidity',
      "type_arguments": [
       findAddressByName(inputToken),
       findAddressByName(outToken)
      ],
      "arguments": [
        value,
        0,
        0
      ],
      "type": "entry_function_payload"
    }
    console.log(payload)
    try {
    const { hash } = await signAndSubmitTransaction(payload);
    console.log(hash)
    OpenNotification('success', 'Transaction Succeed', <a href={`${explorerUrl}${hash}`} target="_blank">View on explorer </a>)
    setRemoveLoading(false)
    setRmovePercent(0)
    setTimeout(()=>{
      setRefresh(refresh+1)
    }, 2000)
  } catch (err) {
    setRemoveLoading(false)
    console.log(err)
  }
  }
  useEffect(()=>{
    if(coinType) {
      setShowAdd(true);
      setAddInput(coinType.split('-')[0]);
      setAddOutput(coinType.split('-')[1])
    }
    
  }, [coinType])

  useEffect(async () => {
    setLoading(true)
    if(props.account) {
      let lp = await getLPBalance(props.account)
      let p = []
      let p2 = []
      lp.map(item => {
        let supply = queryResource(getAddress()['hwswap']['swap'].split("::")[0], item.type.replace('CoinStore', 'CoinInfo'))
        let reserve =  queryTokenPairReserve(findAddressByName(item.name.split('-')[0]), findAddressByName(item.name.split('-')[1]))
        p.push(supply)
        p2.push(reserve)
      })
      Promise.all(p).then(res => {
        console.log(res)
        lp.map((item, index) => {
          item.supply = +res[index].data.supply.vec[0].integer.vec[0].value
          return item
       })
      })

      Promise.all(p2).then(res => {
        console.log(res)
        lp.map((item, index) => {
          item.reserve_x = findAddressByName(item.name.split('-')[0]) < findAddressByName(item.name.split('-')[1]) ? res[index].reserve_x:res[index].reserve_y
          item.reserve_y = findAddressByName(item.name.split('-')[0]) < findAddressByName(item.name.split('-')[1]) ? res[index].reserve_y:res[index].reserve_x
          return item
       })
      })
      console.log(lp)
      setLiquidityList(lp)
       setLoading(false)
    } else {
      setLiquidityList([])
      setLoading(false)
    }
  }, [props.account, showAdd, refresh])

  return (
    <div className="liquidity-content bgf ">
      <img className='min-pinecone' src={Pinecone} alt="" />
      <img className='squirrel-left' src={squirrelLeft} alt="" />
      <img className='squirrel-right' src={squirrelRight} alt="" />
      {
        showAdd ? (
          <AddLiquidity {...props} input={addInput} output={addOutput} Cancel={() => setShowAdd(false)}></AddLiquidity>
        ): (
          <div className='liquidity-connect'>
            <div className='flex flex-center flex-between'>
              <span className='cb2 fz-24 fwb lg-28 fwb'>Liquidity</span>
              <div>
                {/* <img className='m-r-20' src={setting2Icon} alt="" />
                <img src={settingIcon} alt="" /> */}
              </div>
            </div>
            <p className='m-t-8 m-b-33 c2b fz-14 lh-18'>Add liquidity to receive LP tokens</p>
            {
              props.account ? (
                <div className='linked m-t-5'>
                  <p className='fz-14 lh-18 c2b'>Your Liquidity</p>
                  {
                    loading ? 
                    <div className="flex flex-center w100 flex-middle p-t-30 p-b-30">
                        <Loading/>
                    </div>
                    :
                    LiquidityList.length == 0 ?  <div className='ta w100 c05'>No liquidity found</div> :
                    LiquidityList.map((el, i) => (
                      <div
                        className='linked-item '
                        key={i}
                        onClick={() => {
                          if (openValue === el.name) {
                            setOpenValue('')
                          } else {
                            setOpenValue(el.name)
                          }
                        }}>
                        <div className='flex flex-between flex-center' >
                          <div className='flex flex-center pr'>
                            <img className='coin-left' src={getTokenByName(el.name.split('-')[0]).icon} alt="" />
                            <img className='coin-right' src={getTokenByName(el.name.split('-')[1]).icon} alt="" />
                            <span className='m-l-55 fwb'>{el.name}</span>
                          </div>
                          <div className='flex flex-center'>
                            <span className='m-r-4'>{fromUnit(el.value)}</span>
                            <img src={bottomIcon} alt="" />
                          </div>
                        </div>
                        {
                          openValue === el.name && (
                            <div className='token-detail bgf m-t-20'>
                              <div className='flex flex-between'>
                                <div className='flex flex-center'>
                                  <img className='m-r-10 icon'src={getTokenByName(el.name.split('-')[0]).icon} alt="" />
                                  <span>Pooled {el.name.split('-')[0]}</span>
                                </div>
                                <span>{el.supply ? fromUnit(el.reserve_x*(el.value/el.supply)): '--'}</span>
                              </div>
                              <div className='flex flex-between m-t-5'>
                                <div className='flex flex-center'>
                                  <img className='m-r-10 icon'src={getTokenByName(el.name.split('-')[1]).icon} alt="" />
                                  <span>Pooled {el.name.split('-')[1]}</span>
                                </div>
                                <span>{el.supply ? fromUnit(el.reserve_y*(el.value/el.supply)):"--"}</span>
                              </div>
                              <div className='flex flex-center'>
                                <hr className='share-line flex-1'></hr>
                                <div>
                                  <span>Share of Pool</span>
                                  <span className='c00c m-l-8'>{el.supply ? toFixed((el.value/el.supply)*100, 2):'--'}%</span>
                                </div>
                              </div>
                              <div className='m-t-30 flex gap-10'>
                                <div className='add-btn flex-1'>
                                  <Button className='add-btn-in flex flex-middle flex-center pointer' onClick={(e)=> {
                                      e.stopPropagation();
                                      setShowAdd(true);setAddInput(el.name.split('-')[0]);setAddOutput(el.name.split('-')[1])
                                  } }>
                                    <span>Add</span>
                                  </Button>
                                </div>
                                {
                                  !showRemove ? 
                                <Button className='remove-btn flex-1 pointer' loading={removeloading} onClick={(e)=>{e.stopPropagation(); setShowRemove(true)}}>Remove</Button>
                                  :
                                  <Button className='remove-cancel-btn flex-1 pointer' loading={removeloading} onClick={(e)=>{e.stopPropagation(); setShowRemove(false)}}>Cancel</Button>
                                }
                              </div>
                              {
                                showRemove && 
                                <div className="m-t-20">
                                  <div className='flex m-t-11 gap-19'>
                                    {
                                      option.map(el => {
                                        return (
                                          <div key={el.label}  className={'radio-btn flex-1 c2b fz-12 pointer '+ (removePercent == el.value ? 'active': '')} onClick={(e)=>{e.stopPropagation();setRmovePercent(el.value)}}>{el.label}</div>
                                        )
                                      })
                                    }
                                  </div>
                                  <Button className='w100 color confirm-remove m-t-10' loading={removeloading} disabled={removePercent<=0} onClick={(e)=> {e.stopPropagation(); toRemove(el.name.split('-')[0], el.name.split('-')[1], toFixed(el.value*(removePercent/100), 0))}}>Confirm Remove</Button>
                              </div>
                              }
                              
                            </div>
                          )
                        }
                      </div>
                    ))
                  }
                </div>
              ) : (
                <div className='you-liquidity flex flex-middle flex-column flex-center '>
                  <span className='c2b fz-14 lh-18'>Your Liquidity</span>
                  <div className='no-connect-tips m-t-19'>
                    <div className='no-connect-tips-in flex flex-middle flex-center pointer' onClick={() => showLogin()}>
                      <span>Connect to a wallet to view your liquidity.</span>
                    </div>
                  </div>
                </div>
              )
            }
            <Button className='add-liquidity-btn m-t-32 fz-18 fwb pointer' onClick={() => setShowAdd(true)}>+ Add Liquidity</Button>
          </div>
        )
      }
    </div>
  )
}


export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Liquidity
);