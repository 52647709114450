export default [
    {
        img: require('../image/share/twitter.svg').default,
        imgw: require('../image/share/twitterw.svg').default,
        link: 'https://twitter.com/Harwell_Web3',
        name: 'twitter'
    },
    {
        img: require('../image/share/discord.svg').default,
        imgw: require('../image/share/discordw.svg').default,
        link: 'https://discord.gg/MtQm9JwACK',
        name: 'discord'
    },
    // {
    //     img: require('../image/share/telegram.svg').default,
    //     imgw: require('../image/share/telegramw.svg').default,
    //     link: 'https://www.baidu.com',
    //     name: 'telegram'
    // },
    {
        img: require('../image/share/medium.svg').default,
        imgw: require('../image/share/mediumw.svg').default,
        link: 'https://medium.com/@Harwell_Web3',
        name: 'medium'
    }
]