import { NavLink, useParams } from 'react-router-dom';
import './detail.scss';
import { useEffect, useState } from 'react';
import { connect as reducxConnect } from 'react-redux'
import { getTokenByName } from '../Dex/components/list';
import { addPoint, fromUnit, toFixed } from '../../lib/util';
import aptoslogo from '../../assets/image/token/APT.svg'
import Logo from '../../assets/image/marketplace/logo.png'
import arrow1 from '../../assets/image/marketplace/down.svg'
import { Button, Select, Collapse, Tooltip } from 'antd';
import Nft from './components/Nft';
import { getCollectionDataNative, getMetaDataById, getMetaDataByNative, getOwnerById } from '../../methods/nft';
import Modal from '../../components/common/Modal';
import List from './components/List';
import Offer from './components/Offer';


const { Panel } = Collapse;
const properties = [
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    },
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    },
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    },
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    },
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    },
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    },
    {
        name: 'Background',
        value: 'Checkers',
        percent: '4%'
    }
]
function Detail(props) {
    let [list, setList] = useState([])
    let [showList, setShowList] = useState(false)
    let [showOffer, setShowOffer] = useState(false)
    let [metaData, setMetaData] = useState({})
    let [collectionData, setCollectionData] = useState({})
    let [owner, setOwner] = useState('')
    let { token_hash_id } = useParams()
    let { creator, collection, name } = useParams()
    useEffect(async () => {
        if (token_hash_id) {
            let { data } = await getMetaDataById(token_hash_id)
            console.log(data?.current_token_datas[0])
            setMetaData(data?.current_token_datas[0])
            let owners = await getOwnerById(token_hash_id, data?.current_token_datas[0]?.largest_property_version)
            console.log(owners)
            console.log(owners?.data)
            setOwner(owners?.data?.current_token_ownerships[0]?.owner_address)
            let collection = await getCollectionDataNative(data?.current_token_datas[0].creator_address, data?.current_token_datas[0].collection_name, data?.current_token_datas[0].name)
            console.log(collection)
            setCollectionData(collection)
        }
    }, [token_hash_id])

    useEffect(async () => {
        console.log(creator, collection, name)
        if (creator && collection && name) {

            let meta = await getMetaDataByNative(creator, collection, name)
            meta.metadata_uri = meta.uri
            setMetaData(meta)
            console.log(meta)
            let collections = await getCollectionDataNative(creator, collection)
            console.log(collections)
            setCollectionData(collections)
        }
    }, [creator, collection, name])
    //   useEffect(async () => {
    //     let l = await fetch('https://api-v1.topaz.so/api/listing-view-p?collection_id=0xf932dcb9835e681b21d2f411ef99f4f5e577e6ac299eebee2272a39fb348f702%3A%3AAptos%20Monkeys&from=0&to=49&sort_mode=PRICE_LOW_TO_HIGH&buy_now=false&page=0&min_price=undefined&max_price=null&filters={}&search=null')
    //     let data = (await l.json())
    //     setList(data.data)
    //     console.log('////////////')
    //     console.log(data.data)
    //   }, [])
    return (
        <div className="collection-detail">
            <div className="w100 flex m-t-80 gap-60">
                <div className="left-part">
                    <img src={metaData.metadata_uri} alt="" className="cover" />
                    <div className="property p-t-24 p-l-32 p-b-24 p-r-32 m-t-32">
                        <div className="fz-18 fwb c2b">Item Properties</div>
                        <div className="flex flex-wrap gap-12 m-t-24">
                            {
                                properties.map((item, index) => {
                                    return (
                                        <div className="property-item flex flex-column flex-center" key={index}>
                                            <span className="fz-14 c2b fw-500">Background</span>
                                            <span className='fz-14 c236'>Checkers</span>
                                            <span className='fz-14 c236'>(4%)</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <div className="right-part flex-1">
                    <div className="flex">
                        <span className="fwb fz-18 c236">
                            {collectionData.name}  ·   Floor : -- APT
                        </span>
                        <span className='share'></span>
                    </div>
                    <div className="fz-54 c2b fwb">{metaData.name}</div>
                    <div className="fz-18 fwb">Owned By <Tooltip title={owner}><span className='cr'>{owner?.toLowerCase() == props.account?.toLowerCase() ? 'You' : addPoint(owner)}</span></Tooltip> </div>
                    <div className="price-area w100 p-t-24 p-l-36 p-b-24 p-r-36 m-t-36">
                        {/* <div className="fz-18 fwb c2b">Price</div> */}
                        {/* <div className="price flex flex-center">
                         <img src={aptoslogo} alt="apt" className='apt-logo' />
                         <span className="cr fz-36 fwb m-l-12">95 APT</span>
                    </div> */}
                        <div className="flex gap-30 m-t-24">
                            <Button className='flex-1 buy-btn color' onClick={() => setShowList(true)}>List</Button>
                            <Button className='flex-1 offer-btn' onClick={() => { setShowOffer(true) }}>Make Offer</Button>
                        </div>
                        {/* <div className="fz-14 c236 m-t-16">5% Creator Royalty    ·    2.5% Marketplace Fee</div> */}
                    </div>
                    <Collapse expandIcon={() => <img src={arrow1} alt="" className="arrow" />} expandIconPosition="right"
                        style={{
                            borderRadius: 12,
                            overflow: 'hidden',
                            fontSize: 18,
                            fontWeight: 'bold',
                            background: 'transparent',
                            borderColor: '#E9E9E9',
                            marginTop: 36
                        }}>
                        <Panel header="Collection" key="1" style={{ borderRadius: 12 }}>
                            <div className='flex flex-center'>
                                <img src={collectionData.uri} alt="logo" className='collection-logo' />
                                <div className="fz-14 c2b m-l-20">
                                    {collectionData.description}
                                </div>
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse expandIcon={() => <img src={arrow1} alt="" className="arrow" />} expandIconPosition="right"
                        style={{
                            borderRadius: 12,
                            overflow: 'hidden',
                            fontSize: 18,
                            fontWeight: 'bold',
                            background: 'transparent',
                            borderColor: '#E9E9E9',
                            marginTop: 24
                        }}>
                        <Panel header="Latest Activity" key="2" style={{ borderRadius: 12 }}>
                            <p className='c236'>No Activity</p>
                        </Panel>
                    </Collapse>

                    <Collapse expandIcon={() => <img src={arrow1} alt="" className="arrow" />} expandIconPosition="right"
                        style={{
                            borderRadius: 12,
                            overflow: 'hidden',
                            fontSize: 18,
                            fontWeight: 'bold',
                            background: 'transparent',
                            borderColor: '#E9E9E9',
                            marginTop: 24
                        }}>
                        <Panel header="Offers" key="3" style={{ borderRadius: 12 }}>
                            <p className='c236'>No Offer</p>
                        </Panel>
                    </Collapse>


                </div>

            </div>
            <div className="w100 m-t-56">
                <div className="fz-24 fwb">More from this collection</div>
                <div className="flex w100 gap-30 m-t-32">
                    {
                        list.map((item, index) => {
                            return index < 5 && <Nft key={item.token_id} info={item} />
                        })
                    }
                </div>
            </div>

            <Modal isVisible={showList} title="List NFT" onClose={() => setShowList(false)}>
                <List metaData={metaData} onClose={() => { setShowList(false) }} />
            </Modal>
            <Modal isVisible={showOffer} title="Make Offer" onClose={() => setShowOffer(false)}>
                <Offer metaData={metaData} onClose={() => { setShowOffer(false) }} />
            </Modal>

        </div>
    )
}

export default reducxConnect(
    (state, props) => {
        return { ...state, ...props }
    }
)(
    Detail
);