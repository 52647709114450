 const list =  [
    // {
    //   title: 'BNB',
    //   desc: 'BNB',
    //   icon: require('../../../assets/image/token/BNB.svg').default
    // },
    {
      title: 'HARW',
      desc: 'Harwell token',
      icon: require('../../../assets/image/token/HARW.png')
    },
    {
      title: 'APT',
      desc: 'Aptos token',
      icon: require('../../../assets/image/token/APT.svg').default
    },
    {
      title: 'BUSD',
      desc: 'BUSD token',
      icon: require('../../../assets/image/token/BUSD.svg').default
    },
    {
      title: 'ETH',
      desc: 'ETH token',
      icon: require('../../../assets/image/token/ETH.svg').default
    },
    {
      title: 'BTC',
      desc: 'BTC token',
      icon: require('../../../assets/image/token/BTC.svg').default
    },
    {
      title: 'USDC',
      desc: 'USDC token',
      icon: require('../../../assets/image/token/USDC.svg').default
    },
    {
      title: 'CAKE',
      desc: 'CAKE token',
      icon: require('../../../assets/image/token/CAKE.svg').default
    }
  ]

  export const getTokenByName = (name) => {
    let token = {}
    list.map(item => {
        if(item.title == name) {
            token = item
        }
    })
    return token
  }
  export default list