import './stake.scss'
import copper from '../../../assets/image/stake/cards/copper.png'
import silver from '../../../assets/image/stake/cards/silver.png'
import gold from '../../../assets/image/stake/cards/gold.png'
import platinum from '../../../assets/image/stake/cards/platinum.png'
import diamond from '../../../assets/image/stake/cards/diamond.png'
import left from '../../../assets/image/stake/day-icon-left.png'
import right from '../../../assets/image/stake/day-icon-right.png'
import { Button, Skeleton } from 'antd'
import { useState } from 'react'
import { connect as reducxConnect } from 'react-redux'
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useEffect } from 'react'
import { getBalance } from '../../../methods/client.ts'
import { addPoint, findAddressByName, fromUnit, hexToString, numFormat, OpenNotification, toFixed, toUnit } from '../../../lib/util'
import { explorerUrl, getAddress } from '../../../contract'
import { useSubmitTransiction } from '../../../methods/submit'
const Stake = (props) => {
  let [selectedDay, setSelectedDay] = useState(180)
  let [balance, setBalance] = useState(0)
  let [percent, setPercent] = useState(0)
  let [inputNum, setInputNum] = useState('')
  let [loading, setLoading] = useState(false)
  let [loadingBalance, setLoadingBalance] = useState(false)
  let { signAndSubmitTransaction } = useWallet()
  let [refresh, setRefresh] = useState(1)
  const { submitTransiction } = useSubmitTransiction()
  const handleSetAmount = (value) => {
    if (value === '') {
      setInputNum(value)
      return
    }
    if (!(/^\d+((\.\d+)?|(\.))$/.test(value))) {
      return
    }
    if (value.split('.').length > 1 && value.split('.')[1].length > 3) {
      return
    }
    if (value < 0) {
      return
    }
    setInputNum(value)
  }
  const toStake = async () => {
    if (!inputNum || !selectedDay) {
      return
    }
    setLoading(true)
    let payload = {
      type: "script_function_payload",
      function: `${getAddress().grade.grade.address}::deposit`,
      type_arguments: [findAddressByName('HARW')],
      arguments: [toUnit(inputNum), selectedDay]
    };
    submitTransiction(payload, () => {
      setLoading(false)
      props.success()
      setRefresh(refresh + 1)
    }, () => {
      setLoading(false)
    })
  }
  useEffect(async () => {
    if (props.account) {
      setLoadingBalance(true)
      let bal = await getBalance(props.account, findAddressByName('HARW'))
      setBalance(fromUnit(bal))
      setLoadingBalance(false)
    }
  }, [props.account, refresh])


  useEffect(() => {
    if (props.account) {
      balance * percent > 0 ? setInputNum(toFixed(balance * percent / 100, 0) || '') : setInputNum('')
    } else {
      setInputNum('')
    }
  }, [percent, balance])
  return (
    <div className='stake-box w100 p-t-10'>
      <div className="stake-title flex flex-between">
        <span className='fz-16 fwb'>Stake Amount</span>
        <span className='fz-14 c05'>Your HARW Balance:
          {
            loadingBalance ? <Skeleton.Button active size={'small'} /> :
              <span className='c0'>{numFormat(balance)}</span>
          }

        </span>
      </div>
      <div className="stake-input-box flex flex-column flex-last m-t-9">
        <input type="text" placeholder='0' className='stake-input w100 p-l-18 fz-24' value={inputNum} onChange={(e) => handleSetAmount(e.target.value)} />
        <div className="percent flex flex-last">
          {
            [25, 50, 75, 100].map(item => {
              return (
                <div className='percent-item' key={item} onClick={() => { setPercent(item) }}>
                  <span className='fz-14 c05 percent-item-text pointer'>
                    {item != 100 ? item + '%' : 'Max'}
                  </span>

                  {item != 100 && <span className='c05'>/</span>}
                </div>
              )
            })
          }
        </div>
      </div>
      <div className='fz-16 fwb m-t-32'>Lock duration</div>
      <div className="lock flex flex-wrap flex-between m-t-9">
        {
          [7, 14, 30, 60, 90, 180].map(item => {
            return (
              <div onClick={() => { setSelectedDay(item) }} key={item} className={"lock-item ta m-b-11 pointer flex flex-center flex-between " + (selectedDay == item ? 'active' : '')}>
                <img src={left} alt="" className='icon' />
                {item} days
                <img src={right} alt="" className='icon' />
              </div>
            )
          })
        }
      </div>

      <div className='fz-16 fwb m-t-32'>Weight: <span>{inputNum * selectedDay}</span></div>

      <div className='fz-16 fwb m-t-32'>You will get</div>
      <div className='card-box fz-14 fwb flex flex-center'>
        {
          (() => {
            let lel = {}
            props.levels?.map((item, index) => {
              if (inputNum * selectedDay * 1 > fromUnit(item.weight) * 1) {
                lel = item
                lel.index = index
              }
            })
            return (
              lel.name ?
                <span>
                  <img src={props.option[lel.index].icon} alt="" className='level-icon m-r-8' />
                  {hexToString(lel.name).toLowerCase()} card
                </span>
                : ''
            )
          })()
        }

      </div>
      <div className='fz-14 c05'>
        Please refer to the following for grade description
      </div>
      <Button className='w100 color btn' onClick={toStake} loading={loading}>
        <span className='fz-16 fwb'>Stake</span>
      </Button>

    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return { ...state, ...props }
  }
)(
  Stake
);