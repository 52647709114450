import logo from './logo.svg';
import router from './router';

import Header from './components/layout/Header';
import Footer from './components/layout/Footer.jsx'
import { Routes, Route, Link } from "react-router-dom";
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom'
import {
  ConnectionProvider,
  WalletProvider,
  HippoWalletAdapter,
  AptosWalletAdapter,
  HippoExtensionWalletAdapter,
  MartianWalletAdapter,
  FewchaWalletAdapter,
  PontemWalletAdapter,
  SpikaWalletAdapter,
  RiseWalletAdapter,
  FletchWalletAdapter,
  TokenPocketWalletAdapter,
  ONTOWalletAdapter,
  BloctoWalletAdapter,
  SafePalWalletAdapter
} from '@manahippo/aptos-wallet-adapter';
const ScrollToTop = props => {
  const { pathname } = useLocation()
  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [pathname]);
  return props.children;

}
function App() {
  const wallets = useMemo(
    () => [
      // new HippoWalletAdapter(),
      new AptosWalletAdapter(),
      new MartianWalletAdapter(),
      new FewchaWalletAdapter(),
      // new HippoExtensionWalletAdapter(),
      new PontemWalletAdapter(),
      // new SpikaWalletAdapter(),
      new RiseWalletAdapter(),
      // new FletchWalletAdapter(),
      // new TokenPocketWalletAdapter(),
      // new ONTOWalletAdapter(),
      // new BloctoWalletAdapter(),
      // new SafePalWalletAdapter()
    ],
    []
  );
  return (
    <WalletProvider wallets={wallets} autoConnect onError={(error) => {
      console.log('Handle Error Message', error);
    }}>  <ScrollToTop>
        <div className="App">
          <Header />
          <Routes>
            {
              router.map(item => {
                return (
                  <Route key={item.path} path={item.path} exact={true} element={item.component} />
                )
              })
            }
          </Routes>
          <Footer />
        </div>
      </ScrollToTop>
    </WalletProvider>
  );
}

export default App;
