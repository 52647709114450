import { Button } from 'antd'
import { useState } from 'react'
import { getAddress } from '../../../contract';
import { testInput, toUnit } from '../../../lib/util'
import aptoslogo from '../../../assets/image/token/APT.svg'
import { useSubmitTransiction } from '../../../methods/submit';
import './List.scss'
export default (props) => {
    let [price, setPrice] = useState('')
    let [loading, setLoading] = useState(false)
    const { submitTransiction } = useSubmitTransiction()
    const listToken = async () => {
        if (loading) {
            return
          }
          setLoading(true)
          let payload = {
            type: "script_function_payload",
            function: `${getAddress().marketplace.address}::batch_list_script`,
            type_arguments: [],
            arguments: [
              [props.metaData.creator_address],
              [props.metaData.collection_name],
              [props.metaData.name],
              [props.metaData.largest_property_version],
              [toUnit(price)]
            ],
          };
          console.log(payload)
          submitTransiction(payload, () => {
            setLoading(false)
            props.onClose()
          }, () => {
            setLoading(false)
          })
    }
    return (
        <div className='list'>
             <div className="nft-info flex flex-center">
                <img src={props.metaData.metadata_uri} alt="" />
                <span className='fz-20 fwb m-l-8'>{props.metaData.name}</span>
             </div>
             <div className="tl c236 fz-13 m-t-20">Price</div>
             <div className="price-area flex p-19 bdr-12 flex-center m-t-10">
                <input type="number" placeholder='0.00' value={price} onChange={(e) => {
                if (testInput(e.target.value)) {
                    return
                  }
                  setPrice(e.target.value);
                }} className="com_input flex-1 fz-14 fwb tr p-r-10" />
                <img src={aptoslogo} alt="" className='aptlogo'/>
                <span className='c2b fz-14 fwb m-l-10'>APT</span>
             </div>
             <Button loading={loading} className='color large-btn w100 m-t-20' onClick={listToken} disabled={!price}>List</Button>
        </div>
    )
}