import { Tabs } from "antd";
import { useState } from "react";
import swapTab from '../../assets/image/launchpad/swap-tab.png'
import songzi from '../../assets/image/launchpad/Liquidity-tab.png'
import Liquidity from "./components/Liquidity";
import Swap from "./components/Swap";
import {useLocation } from 'react-router-dom';
import './index.scss'
function Dex() {
  let location = useLocation()
  let [tabValue, setTabValue] = useState(location.search ? location.search.replace('?','').split('=')[1].split('&')[0]?.toLowerCase():'swap')
  return (
    <div className="dex">
      <div className="dex-header flex flex-middle bgf">
        <Tabs
          defaultActiveKey={tabValue}
          className='dex-tab'
          onTabClick={(value) => {
            setTabValue(value)
          }}>
          <Tabs.TabPane
            tab={
              <div className="p-l-39 p-r-39">
                <img src={swapTab} alt="" width={28}/>
                <span className='c23 fz-18 fwb m-l-8'>Swap</span>
              </div>}
            key="swap">
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className="p-l-28 p-r-28">
                <img src={songzi} alt="" width={28}/>
                <span className='c23 fz-18 fwb m-l-8'>Liquidity</span>
              </div>}
            key="liquidity">
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="dex-content">
        {
          tabValue === 'swap' && (
            <Swap></Swap>
          )
        }
        {
          tabValue === 'liquidity' && (
            <Liquidity></Liquidity>
          )
        }
      </div>
    </div>
  )
}

export default Dex;