import { Button, Select } from 'antd'
import { useState } from 'react'
import { getAddress } from '../../../contract';
import { testInput, toUnit } from '../../../lib/util'
import aptoslogo from '../../../assets/image/token/APT.svg'
import { useSubmitTransiction } from '../../../methods/submit';
import './Offer.scss'

const optionsSelect = [
  {
    label: '3 days',
    value: '1',
    duration: 3*24*60*60
  },
  {
    label: '7 days',
    value: '2',
    duration: 7*24*60*60
  },
  {
    label: '1 months',
    value: '3',
    duration: 30*24*60*60
  },
  {
    label: '3 months',
    value: '4',
    duration: 90*24*60*60
  },
  {
    label: '6 months',
    value: '5',
    duration: 180*24*60*60
  }
]
export default (props) => {
    let [price, setPrice] = useState('')
    let [expire, setExpire] = useState('1')
    let [loading, setLoading] = useState(false)
    const { submitTransiction } = useSubmitTransiction()
    const makeOffer = async () => {
        if (loading) {
            return
          }
          setLoading(true)
          let payload = {
            type: "script_function_payload",
            function: `${getAddress().marketplace.address}::batch_make_offer_script`,
            type_arguments: [],
            arguments: [
              [props.metaData.creator_address],
              [props.metaData.collection_name],
              [props.metaData.name],
              [props.metaData.largest_property_version],
              [toUnit(price)],
              [optionsSelect.find(item => item.value == expire).duration]
            ],
          };
          console.log(payload)
          submitTransiction(payload, () => {
            setLoading(false)
            props.onClose()
          }, () => {
            setLoading(false)
          })
    }
    return (
        <div className='list'>
             <div className="nft-info flex flex-center">
                <img src={props.metaData.metadata_uri} alt="" />
                <span className='fz-20 fwb m-l-8'>{props.metaData.name}</span>
             </div>
             <div className="tl c236 fz-13 m-t-20">Price</div>
             <div className="price-area flex p-19 bdr-12 flex-center m-t-10">
                <input type="number" placeholder='0.00' value={price} onChange={(e) => {
                if (testInput(e.target.value)) {
                    return
                  }
                  setPrice(e.target.value);
                }} className="com_input flex-1 fz-14 fwb tr p-r-10" />
                <img src={aptoslogo} alt="" className='aptlogo'/>
                <span className='c2b fz-14 fwb m-l-10'>APT</span>
             </div>

             <div className="tl c236 fz-13 m-t-20">Offer Expiration</div>
             <Select defaultValue="1" value={expire} onChange={(e) => setExpire(e)} className='my-select flex flex-middle flex-center' style={{ width: '100%' }} suffixIcon={
          <img src={require('../../../assets/image/common/down.svg').default}></img>
        } >
          {
            optionsSelect.map(item => {
              return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
            })
          }
        </Select>
             <Button loading={loading} className='color large-btn w100 m-t-20' onClick={makeOffer} disabled={!price || !expire}>Make Offer</Button>
        </div>
    )
}