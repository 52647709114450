import { useState } from 'react';
import { connect as reducxConnect } from 'react-redux';
import { Button, Table } from 'antd';
import icon4 from '../../../assets/image/reward/reward-icon-4.svg';
function RewardAirdropList(props) {
  const columns = [
    {
      title: 'Reward Name',
      dataIndex: 'rewardName',
      key: 'rewardName',
      width: '50%',
    },
    {
      title: 'Remain Reward Amount',
      dataIndex: 'remainRewardAmount',
      key: 'remainRewardAmount',
      width: '50%',
      render: (text) => <span>{text} HARW</span>,
    },
  ];
  const [tableData, setTableData] = useState([
    {
      rewardName: 'Harwell airdrop 1',
      remainRewardAmount: '10100',
    },
  ]);
  const claim = () => {};
  return (
    <div className="reward-list reward-farm-list">
      <div className="header-wrapper">
        <img src={icon4} className="logo" alt="" />
        <div className="content-wrapper">
          <div className="fwb">Airdrop</div>
          <div>
            <span>Remain reward: </span>
            <span className="fwb">20300 HARW</span>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        className="reward-table"
      />
    </div>
  );
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(RewardAirdropList);
