import { NavLink } from 'react-router-dom';
import './launchpad.scss';
import { useEffect, useState } from 'react';
import {connect as reducxConnect} from 'react-redux'
import { SearchOutlined } from '@ant-design/icons';
import { getTokenByName } from '../Dex/components/list';
import Logo from '../../assets/image/marketplace/logo.png'
import aptoslogo from '../../assets/image/token/APT.svg'
import { addPoint, fromUnit, toFixed } from '../../lib/util';
import { Button , Carousel} from 'antd';
import Mint from './components/Mint';
function LaunchPad(props) {
    const onChange = () => {

    }
  return (
    <div className="launch w100">
        <div className="banner w100 p-t-50">
                <div className="banner-bkg"></div>
                <div className="mint-banner flex flex-between">
                <Carousel afterChange={onChange} dots={{className: 'dots'}}>
                            <div className='cover'>
                                <img src={Logo} alt="" />
                            </div>
                            <div  className='cover'>
                                <img src={Logo} alt="" />
                            </div>
                    </Carousel>
                    <div className="mint-info">
                        <div className="price-area flex flex-between flex-start">
                            <img src={Logo} alt="" className="logo" />
                            <div className="price flex fz-16 flex-center">
                                <img src={aptoslogo} alt="apt" className='apt-logo m-r-5 '/>
                                12.32
                            </div>

                        </div>
                        <div className="fz-36 fwb m-t-35">Bonks Habibiz Syndicate</div>
                        <div className="fz-16 c236 m-t-12">
                        A department of ART-COMBATANT FORCE (DAF) to support BUILDERS in WEB3. Discord CLOSED for HODLERS.
                        </div>
                        <div className="fz-18 c2b m-t-36">Created by:
                            <span className="c2b underline fz-14 m-l-5">{addPoint('0x6669eb490c9c9aad8c41d7b1e719361f1edb3ccef1f6ff1725f74721f9042bd9', 7)}</span>
                        </div>
                        <div className="progress-mint w100 m-t-36">
                            <div className="flex flex-between c2b fz-16 fwb">
                                <span>0.48% Sold</span>
                                <span>59/100</span>
                            </div>
                            <div className="progress-inner w100 m-t-8">
                                <div className="progress-content" style={{width: '40%'}}></div>
                            </div>

                        </div>
                        <Button className='mint-btn color fz-24 fwb c2b m-t-36'>Mint</Button>
                        
                    </div>
                </div>

        </div>
        <div className="launch-content">
            <div className="fz-36 fwb">Hot</div>
            <div className="flex m-t-24 gap-26 flex-wrap">
                <Mint/>
                <Mint/>
                <Mint/>
                <Mint/>
            </div>
        </div>

        <div className="launch-content">
            <div className="fz-36 fwb">Latest</div>
            <div className="flex m-t-24 gap-26 flex-wrap">
                <Mint/>
                <Mint/>
                <Mint/>
                <Mint/>
            </div>

        </div>
    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  LaunchPad
);