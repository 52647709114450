import './Submit.scss'
import { Button, Radio, Upload, Form, notification} from 'antd';
import shower from '../assets/image/submit/shower.png'
import left from '../assets/image/submit/left.png'
import submit from '../assets/json/submit';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import passport, {TwitterStrategy} from 'passport-twitter'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { reqCreateProject } from '../api/launchpad';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCmh3ishqSnM5C7BLdJPg4dN8S4rYy3ZiU",
  authDomain: "harwell-7b2ea.firebaseapp.com",
  projectId: "harwell-7b2ea",
  storageBucket: "harwell-7b2ea.appspot.com",
  messagingSenderId: "318384023030",
  appId: "1:318384023030:web:07050fafe20fa22cce53d7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new TwitterAuthProvider();
const auth = getAuth(app);
export default () => {
  const [loading, setLoading] = useState(false);
  const [twitterInfo, setTwitterInfo] = useState({})
  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
  const [imageUrl, setImageUrl] = useState();
  const [fields, setFields] = useState([])
  const onChange = () => {

  }
  const onFinish = (values) => {
    console.log('Success:', values);
    reqCreateProject(values).then(res => {
      notification['success']({
        message: '提交成功'
      });
    })
  };

  let toBind = ()=>{
    signInWithPopup(auth, provider).then((result) => {
      console.log(result)
      setTwitterInfo(result.user.reloadUserInfo)
      setFields([
        {name: 'logo', value: result.user.reloadUserInfo.photoUrl.replace('normal', '400x400')},
        {name: 'twitterUrl', value: result.user.reloadUserInfo.screenName}
      ])
    })
  }
  return (
    <div className='submit-detail p-t-40 p-b-280'>
      <div className="submit-inner p-r-60 p-l-60 p-b-60">
          <img src={shower} alt="" className='shower'/>
          <img src={left} alt="" className='left show-p'/>
          <div className="submit-title fz-22 fwb ta ">
              Submit an  project for IDO
          </div>
          <div className="m-t-50 w100" style={{height:'1px'}}></div>
          <Form className='submit' 
            onFinish={onFinish}
            fields={fields}>
          {
            submit.map(item => {
                return (
                    <Form.Item label=""  name={item.key} key={item.key}
                    rules={item.rules}>
                    <div className="form-item flex">
                        <div className="form-name fz-16 fwb tr p-r-40">
                            {item.name}
                            {
                                item.required && <span className='badge'>*</span>
                            }
                            
                        </div>
                        <div className="form-input flex-1">
                            {
                                item.type == 'input' ? <input type="text" className='w100'/>:
                                item.type == 'website'? <span className='flex c236'>
                                    <span className='m-r-24'>https://</span>
                                    <input type="text" className='w100'/>
                                </span>:
                                item.type == 'radio' ? (
                                    <Radio.Group onChange={onChange} className="my-radio">
                                        {
                                            item.items.map(radio => {
                                                return (
                                                    <Radio value={radio} key={radio} style={{width: '40%', marginBottom: '10px'}}>
                                                        <span className='c2b'>
                                                        {radio}
                                                        </span>
                                                    </Radio>
                                                )
                                            })
                                        }
                                    </Radio.Group>
                                ):
                                item.type == 'logo' ? (
                                    <div>
                                        <div className="avatar-uploader c236 flex flex-center">
                                          {twitterInfo.photoUrl ? <img src={twitterInfo.photoUrl.replace('normal', '400x400')} alt="avatar" style={{ width: '100%' }} className="logo-image"/>:'Use your twitter avatar'}
                                        </div>

                                    </div>
                                ):
                                item.type == 'twitter' ? (
                                    <span className='flex c236 twitter-form'>
                                    {/* <span className='m-r-24'>https://twitter.com/</span> */}
                                    <span className='twitter-input w100'>
                                        <input type="text" disabled className='w100' value={twitterInfo.screenName?''+twitterInfo.screenName:''}/>
                                        <Button className='color c0 bind-twitter m-l-20' onClick={toBind}>Bind Twitter</Button>
                                    </span>
                                    
                                </span>
                                ):
                                <textarea type="text" className='w100'/>
                            }
                            
                        </div>
                    </div>
                    </Form.Item>
                    
                )
            })
          }
          <div className="submit-btn-wrap w100 flex flex-last m-t-18">
              <Button className='color submit-btn fwb fz-16' htmlType="submit">
                  <span className='fz-16 fwb'>Submit</span>
              </Button>
          </div>
          </Form>
      </div>
    </div>
  )
}