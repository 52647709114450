import './Launchpad.scss'
import { Button, Radio, Select, Spin } from 'antd'
import Project from '../components/project'
import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import MySelect from '../components/common/MySelect';
import { loadProjectList } from '../api/launchpad';
import { setMaxListeners } from 'events';
import Loading from '../components/common/Loading';

const optionsWithDisabled = [
  {
    label: 'Ongoing Pools',
    value: '1',
  },
  {
    label: 'Upcoming Pools',
    value: '2',
  },
  {
    label: 'Completed Pools',
    value: '3'
  },
  {
    label: 'All',
    value: '4'
  },
];
const optionsSelect = [
  {
    label: 'Amount high-low',
    value: '1',
  },
  {
    label: 'Amount low-high',
    value: '2',
  },
  {
    label: 'Date early-late',
    value: '3'
  },
  {
    label: 'Date late-early',
    value: '4'
  },
];
export default () => {

  const [value, setValue] = useState('1');
  const [state, setState] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const onChange = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue(value);
  };
  useEffect(async () => {
    setLoading(true)
    let { data: { data: { list } } } = await loadProjectList({
      page: 1,
      pageSize: 10,
      state
    })
    setList(list)
    setLoading(false)
    console.log(list)
  }, [state])
  return (
    <div className="launchpad">
      <div className="warning-tips">
        <div className="inner ta">
          <div className='fwb'>Warning Tips</div>
          For the Launchpad module, all you see are static pages at present. You need to wait for the completion of the API interface debugging, and we will remind you as soon as you enter the testable process.
        </div>
      </div>
      <div className="launch-banner-area w100">
        <div className="launch-banner-content flex flex-column flex-middle">
          <div className='fz-40 c231 pools'>Pools</div>
          <div className="c231 fz-20 m-t-8 launch-slogan">We bring new technologies to our community</div>
          <NavLink to="/submit" className='color supply-btn c231 fz-14 fwb m-t-30 ta hover' >Supply IDO</NavLink>

        </div>
      </div>
      <div className="search-box project-list flex flex-center flex-between p-t-60">
        <Radio.Group
          className='my-radio-card'
          options={optionsWithDisabled}
          onChange={onChange}
          value={value}
          optionType="button"
          buttonStyle="solid"
        />
        <div className="flex flex-center search-right">
          <div className="search-area m-r-26 ">
            <div className="search-input flex flex-center ">
              <input type="text" className='flex-1' placeholder='Project name/ token symbol' />
              <div className='p-r-13'>
                <SearchOutlined style={{ fontSize: 20 }} />
              </div>
            </div>
          </div>

          <MySelect />
          <span className='flex flex-center sort-select'>
            <span className='c23 fz-14 m-r-8 m-l-30 sortby'>Sort by</span>
            <Select defaultValue="1" className='my-select flex flex-middle flex-center' style={{ width: 120 }} suffixIcon={
              <img src={require('../assets/image/common/down.svg').default}></img>
            } >
              {
                optionsSelect.map(item => {
                  return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                })
              }
            </Select>
          </span>
        </div>
      </div>
      <div className="project-list flex flex-wrap flex-between p-t-42 p-b-200">
        {
          loading ?
            <div className="flex flex-center flex-middle p-t-120 p-b-280 w100">
              <Loading />
            </div> :
            list.map((item, index) => {
              return <Project className="m-b-60" info={item} key={index} />
            })
        }
      </div>
    </div>
  )
}