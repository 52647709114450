import './Mint.scss'
import aptoslogo from '../../../assets/image/token/APT.svg'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { Button, Spin } from 'antd'
import Logo from '../../../assets/image/marketplace/logo.png'
import { fromUnit } from '../../../lib/util'
import verfiy from '../../../assets/image/marketplace/verify.svg'
export default ({info,reLoad}) => {
  let [loading, setLoading] = useState(false)
    return (
         <div className='mint-item flex flex-middle p-20 flex-column'>
             <img src={Logo} alt="logo" className='mint-cover' />
             <div className="flex flex-between flex-center m-t-16">
                 <div className="flex-column">
                    <div className="fz-20">Spacetime Warrior #014</div>
                    <div className="c23 flex flex-center">@rob_amfr <img src={verfiy} alt="verify" className='m-l-6' /></div>
                 </div>
                 <img src={Logo} alt="logo"  className='mint-logo'/>
             </div>
             <div className="flex flex-between m-t-16">
                <span className="c23 fz-14">Current Bid</span>
                <span className="c2b fz-16 fwb">1.0 APT</span>
             </div>

             <div className="progress-mint w100 m-t-16">
                <div className="progress-inner w100 m-t-8">
                    <div className="progress-content" style={{width: '40%'}}></div>
                </div>
                <div className="flex flex-between c2b fz-12 fwb m-t-6">
                    <span>0.48% Sold</span>
                    <span>59/100</span>
                </div>
            </div>
         </div>
    )
}