import { Button } from 'antd';
import HarwellNFTIcon from '../../assets/image/home/HarwellNFT-icon.png'
import './HarwellNFT.scss';
export default () => {
  return window.innerWidth > 768 ? (
    <div className="harwell-nft">
      <div className="content-wrapper">
        <div className="left">
          <div className="text-1">Harwell have all kinds of NFT</div>
          <div className="text-2">
            You can buy & sell your NFTs in{' '}
            <span className="fwb">Harwell marketplace</span>
          </div>
          <Button className="btn">Explore NFT</Button>
        </div>
        <img src={HarwellNFTIcon} alt='' width={640} />
        <div> </div>
      </div>
    </div>
  ) : (
    <div className="harwell-nft-mobile">
      <div className="text-1">Harwell have all kinds of NFT</div>
      <div className="text-2">
        You can buy & sell your NFTs in{' '}
        <span className="fwb">Harwell marketplace</span>
      </div>
      <Button className="btn">Explore NFT</Button>
      <img src={HarwellNFTIcon} alt='' width={281}/>
    </div>
  );
};
