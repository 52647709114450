import { Button } from 'antd'
import { useState } from 'react'
import { getAddress } from '../../../contract'
import { useSubmitTransiction } from '../../../methods/submit'
import './transfer.scss'

export default ({selectList, onClose}) => {
    let [reciver, setReciver] = useState('')
    let [loading, setLoading] = useState(false)
     console.log(selectList)
    const { submitTransiction } = useSubmitTransiction()
    const batchTransfer = () => {
          if(loading) {
            return 
          }
          setLoading(true)
          let payload = {
            type: "script_function_payload",
            function: `${getAddress().marketplace.address}::batch_send_token_to_inbox_script`,
            type_arguments: [],
             arguments: [
                selectList.map(item => reciver),
                selectList.map(item => item.creator_address),
                selectList.map(item => item.collection_name),
                selectList.map(item => item.name),
                selectList.map(item => item.property_version),
                selectList.map(item => 1)
             ],
            };
            console.log(payload)
            submitTransiction(payload, () => {
              setLoading(false)
              onClose()
            }, () => {
              setLoading(false)
            })
    }
    return (
    <div className="p-t-10 w100">
      <div className="tr fz-14 c236 p-b-5"><span className='fwb'>{selectList.length}</span> NFTs selected</div>
        <div className="w100 p-l-20 p-r-10 p-t-10 p-b-10 transfer-nft-box">
           {
            selectList.map((item, index) => {
              return (
                <div className='m-b-5'>
                   <img src={item.metadata_uri} alt={item.name} className="transfer-img" />
                   <span className='fz-14 m-l-5'>{item.name}</span>
                </div>
              )
            })
           }
        </div>
        <textarea placeholder="please input your friends wallet address" className="address-input w100 m-t-20" value={reciver} onChange={(e) => {setReciver(e.target.value)}}/>
        <Button loading={loading} className="w100 color my-button transfer-btn large-btn m-t-20" disabled={!reciver} onClick={batchTransfer}>Confirm Transfer</Button>
    </div>)
}