import './index.scss'
import { connect as reducxConnect } from 'react-redux';
import { Button } from 'antd'
import { useEffect, useState } from 'react';
import Loading from '../../components/common/Loading';
import { formatName } from '../../lib/util';
import { getMetaDataByNative} from '../../methods/nft';
import Modal from '../../components/common/Modal'
import Transfer from './components/transfer';

import selectImg from '../../assets/image/checkbox/selected.png'
import { useSubmitTransiction } from '../../methods/submit';
import { getAddress } from '../../contract';
import { RedoOutlined } from '@ant-design/icons';


// id:token_id;
// isSelected: 是否选中
// showSelect: 是否显示右上角的选择框
const NFTInBox = ({ info, isSelected, showSelect, onSelect, onCancel }) => {
  let [metaData, setMetaData] = useState({})
  useEffect(async () => {
    let data = await getMetaDataByNative(info.token_id?.token_data_id?.creator, info.token_id?.token_data_id?.collection, info.token_id?.token_data_id?.name)
    console.log(data)
    setMetaData(data)
  }, [info])
  return (
    <div className={'nft-wrap flex flex-column pointer ' + (isSelected ? 'selected ' : '') + (showSelect ? 'canselect ' : '')} onClick={() => {
      if (!showSelect) {
        return
      }
      isSelected ? onCancel(info) : onSelect(info)
    }
    }>
      {
        showSelect ? <div className="check-box-area pointer" >
          {
            isSelected ?
              <img src={selectImg} alt="" className='check-active' /> :
              <div className="check-none"></div>
          }
        </div> : ''
      }
      <img src={metaData.uri} alt={formatName(metaData.name)} className="nft-cover" />
      <div className='fz-18 c2b p-t-12 p-l-16 p-b-12 nft-name'>
        <span className='oneline-only w100'>{formatName(metaData.name)}</span>
      </div>

    </div>
  )
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})((props) => {

  const [showSelect, setShowSelect] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const { submitTransiction } = useSubmitTransiction()
  const [claimLoading, setClaimLoading] = useState(false)
  const [claimallLoading, setClaimAllLoading] = useState(false)
  const [metaDataList, setMetaDataList] = useState([])
  const toSelect = (item) => {
    let items = [...selectedItems]
    !items.some(inner => inner.token_id?.token_data_id?.name == item.token_id?.token_data_id?.name) && items.push(item)
    console.log(item)
    console.log(items)
    setSelectedItems(items)
  }
  const toCancel = (item) => {
    let items = [...selectedItems]
    items = items.filter(inner => inner.token_id?.token_data_id?.name != item.token_id?.token_data_id?.name)
    setSelectedItems(items)
  }

  const toClaimAll = () => {
    if (claimLoading && claimallLoading) {
      return
    }

    setClaimAllLoading(true)
    let payload = {
      type: "script_function_payload",
      function: `${getAddress().marketplace.address}::batch_claim_token_from_inbox_script`,
      type_arguments: [],
      arguments: [
        props.list.map(item => item.sender),
        props.list.map(item => item.token_id.token_data_id.creator),
        props.list.map(item => item.token_id.token_data_id.collection),
        props.list.map(item => item.token_id.token_data_id.name),
        props.list.map(item => item.token_id.property_version)
      ],
    };
    submitTransiction(payload, () => {
      setClaimAllLoading(false)
      setSelectedItems([])
      props.onSuccess()
    }, () => {
      setClaimAllLoading(false)
    })
  }

  const toClaim = () => {
    if (claimLoading && claimallLoading) {
      return
    }
    setClaimLoading(true)
    let payload = {
      type: "script_function_payload",
      function: `${getAddress().marketplace.address}::batch_claim_token_from_inbox_script`,
      type_arguments: [],
      arguments: [
        selectedItems.map(item => item.sender),
        selectedItems.map(item => item.token_id.token_data_id.creator),
        selectedItems.map(item => item.token_id.token_data_id.collection),
        selectedItems.map(item => item.token_id.token_data_id.name),
        selectedItems.map(item => item.token_id.property_version)
      ],
    };
    console.log(payload)
    submitTransiction(payload, () => {
      setClaimLoading(false)
      setSelectedItems([])
      props.onSuccess()
    }, () => {
      setClaimLoading(false)
    })
  }



  return (
    <div>
      <div className='middle-content flex flex-between flex-center w100 gap-20 ' >
      {
          props.radioDom
        }
        <div className='flex flex-center gap-20'>
        {
          props.list?.length ? (
            <>
              {
                showSelect ?
                  <>
                    <Button className='my-button color m-r-10' loading={claimallLoading} disabled={claimLoading}  onClick={toClaimAll}>Claim All</Button>
                    <Button className='my-button color' loading={claimLoading} disabled={!selectedItems.length || claimallLoading} onClick={toClaim}>Claim selected {selectedItems.length} items</Button>
                    <Button className='my-button m-l-10' disabled={claimLoading || claimallLoading} onClick={() => { setShowSelect(false); setSelectedItems([]) }}>Cancel</Button>
                  </>

                  :
                  <Button className='my-button color' onClick={() => setShowSelect(true)}>Claim</Button>
              }

            </>

          ) : ''
        }
         <RedoOutlined spin={props.loading} style={{fontSize: '30px',color: 'rgba(0,0,0,.4)'}} className="pointer" onClick={props.onSuccess}/>
         </div>
      </div>
      <div className="project-list flex flex-wrap flex-start  gap-30 ">
        {
          props.loading ?
            <div className="flex flex-center flex-middle p-t-120 p-b-280 w100">
              <Loading />
            </div> : (
              <>{
                props.list?.map((item, index) => {
                  console.log(item.token_id?.token_data_id?.name)
                  return <NFTInBox info={item} showSelect={showSelect} isSelected={selectedItems.some(inner => inner.token_id?.token_data_id?.name == item.token_id?.token_data_id?.name)} onSelect={toSelect} onCancel={toCancel} />
                })
              }
                {props.list?.length ? '' : <div className='w100 ta c236 fz-14 p-t-50'>No NFT For Inbox</div>}
              </>

            )
        }

      </div>
    </div>
  )
});

