import './SoonHarwell.scss';
import SoonHarwellIcon from '../../assets/image/home/soon-harwell.png';
import { useState } from 'react';
import Partners1 from '../../assets/image/home/partners/partners-1.png';
import Partners2 from '../../assets/image/home/partners/partners-2.png';
import Partners3 from '../../assets/image/home/partners/partners-3.png';
import Partners4 from '../../assets/image/home/partners/partners-4.png';
import Partners5 from '../../assets/image/home/partners/partners-5.png';
import Partners6 from '../../assets/image/home/partners/partners-6.png';
import Partners9 from '../../assets/image/home/partners/partners-9.png';
import Partners10 from '../../assets/image/home/partners/partners-10.png';
import Partners11 from '../../assets/image/home/partners/partners-11.png';
import Partners12 from '../../assets/image/home/partners/partners-12.png';
import Partners13 from '../../assets/image/home/partners/partners-13.png';
import Partners14 from '../../assets/image/home/partners/partners-14.png';
export default () => {
  const [partners, setPartners] = useState([
    Partners1,
    Partners2,
    Partners3,
    Partners4,
    Partners5,
    Partners6,
    Partners9,
    Partners10,
    Partners11,
    Partners12,
    Partners13,
    Partners14,
  ]);

  return (
    <div className="soon-harwell">
      <div className="fwb c2b text-1">Distinguished Partners</div>
      <div className="company">
        {partners.map((item) => {
          return (
            <div className='company-item'>
              <img src={item} alt=""/>
            </div>
          );
        })}
      </div>
      <div className="text-2 fwb">Start soon on Harwell</div>
      <div
        className="text-3 fw-400"
      >
        Connect your wallet and start your harwell journey 
        { window.innerWidth >768 ? '' : <br/>} 
         I hope you have a
        good trip
      </div>
      <img src={SoonHarwellIcon}  className="img-harwell" />
    </div>
  );
};
