import User from '../../assets/image/home/user.png';
import './UserHierarchies.scss';
export default () => {
  return window.innerWidth > 768 ? (
    <div className="user-hierarchies">
      <div className="content-wrapper">
        <img src={User} alt="" />
        <div className="right">
          <div className="fwb text-1">5 user hierarchies</div>
          <div className="c2b text-2">
            <span className="fwb">
              Bronze / Silver/ Gold / Platinum / Diamond
            </span>
            <br />
            <span>Different levels of users get different rights</span>
          </div>
          <div className="btn-wrapper">
            <div className="btn-1 ta hover c2b fwb">Get quota</div>
            <div className="btn-2 ta hover">
              <div className="btn-bg">
                <span className="btn-text fwb">Get quota</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="user-hierarchies-mobile">
      <div className="wrapper">
        <div className="fwb text-1">5 user hierarchies</div>
        <div className="c2b text-2">
          <span className="fwb">
            Bronze / Silver/ Gold / Platinum / Diamond
          </span>
          <br />
          <span>Different levels of users get different rights</span>
        </div>
      </div>
      <div className="btn-wrapper">
        <div className="btn-1 ta hover c2b fwb">Get quota</div>
        <div className="btn-2 ta hover">
          <div className="btn-bg">
            <span className="btn-text fwb">Get quota</span>
          </div>
        </div>
      </div>
      <img src={User} alt="" />
    </div>
  );
};
