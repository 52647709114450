import { useState } from 'react';
import './bannerNew.scss';
import { Carousel } from 'antd';
import BannerBg from '../../assets/image/home/banner-bg.svg';
import BannerBg1 from '../../assets/image/home/banner-bg-1.png';
export default () => {
  const [banners, setBanners] = useState([
    {
      url: window.innerWidth < 768 ? BannerBg1 : BannerBg,
      text: 'Harwell Airdrop is alive !!!',
    },
    {
      url:  window.innerWidth < 768 ? BannerBg1 : BannerBg,
      text: 'adasdadad',
    },
  ]);
  return (
    <div className="banner-new">
      <Carousel autoplay>
        {banners.map((item) => {
          return (
            <div key={item.text}>
              <div style={{
                background: `url(${window.location.origin}${item.url}) center center /cover`,
              }} className='banner-bg'>
                <div className="banner-new-wrapper">
                  <div className="content fwb">{item.text}</div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
