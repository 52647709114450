import { Button } from 'antd';
import TradeIcon from '../../assets/image/home/trade-icon.png';
import './TradeEarn.scss';
import { useState } from 'react';
export default () => {
  return window.innerWidth > 768 ? (
    <div className="trade-earn">
      <div className="bg-text"> Harwel</div>
      <div className="content-wrapper">
        <div className="left">
          <div className="text-1">WELCOME TO Harwell</div>
          <div className="text-2">Trade 2 Earn on Harwell</div>
          <div className="text-3">
            Win extra HARW for every Swap or NFT trade on
            <a class="text-3" href="Harwell.pro">
              Harwell.pro
            </a>
          </div>
          <Button className="btn">Trade Now !</Button>
        </div>
        <img src={TradeIcon} alt="" />
      </div>
    </div>
  ) : (
    <div className="trade-earn-mobile">
      <div className="text-1">WELCOME TO Harwell</div>
      <div className="text-2">Trade 2 Earn on Harwell</div>
      <div className="text-3">
        Win extra HARW for every Swap or NFT trade on
        <br/>
        <a class="text-3" href="Harwell.pro">
          Harwell.pro
        </a>
      </div>
      <Button className="btn">Trade Now !</Button>
      <img src={TradeIcon} alt="" />
      <div className="bg-text"> Harwel</div>
    </div>
  );
};
