import './Connect.scss'
import { NavLink, useLocation } from 'react-router-dom'
import { Button, Drawer, Radio, Space, Tooltip } from 'antd';
import {HexString} from 'aptos'
import { useCallback, useEffect, useState } from 'react'
import BgTop from '../../assets/image/connect/top.png'
import BgBottom from '../../assets/image/connect/bottom.png'
import { addPoint, findAddressByName, fromUnit, hideLogin, OpenNotification, showLogin } from '../../lib/util'
import {connect as reducxConnect} from 'react-redux'
import { useWallet} from '@manahippo/aptos-wallet-adapter';
import {
  CopyOutlined,
  LogoutOutlined 
} from '@ant-design/icons';
import store, {setChain, setconnect, connect, disconnect, setUserStore} from '../../store'
import { getBalance } from '../../methods/client.ts';
import { getTokenByName } from '../../pages/Dex/components/list';
import { getUserStore } from '../../methods/staking';
import Loading from '../common/Loading';

const coinList = ['HARW', 'APT', 'BTC', 'ETH', 'CAKE', 'USDC']

function Connect (props) {
    let [show, setShow] = useState(false)
    let [selectWalletName, setSelectWalletName] = useState('')
    let [assetList, setAssetList] = useState([])
    let [loading, setLoading] = useState('')
     let { pathname } = useLocation()
    const { wallets:aptosWallets, select: aptosSelect, disconnect:aptosDisconnect, connect:aptosConnect,  wallet: aptosWallet, account} = useWallet();
    console.log(useWallet)
    const [aptosConnecting, setAptosConnecting] = useState(false)

    const handleAptosConnect = async (walletName) => {
        await aptosDisconnect()
        console.log(aptosConnect)
        aptosSelect(walletName);
        setAptosConnecting(true)
      }
    const hexStringV0ToV1 = (v0) => {
    if (typeof v0 === 'string') {
        return new HexString(v0);
    } else if (v0.hexString) {
        return new HexString(v0.toString());
    } else {
        throw new Error(`Invalid hex string object: ${v0}`);
    }
    };
    const toCopy = useCallback(async () => {
      if (props.account) {
        await navigator.clipboard.writeText(props.account);
        OpenNotification('success', 'Copy finshed')
      }
    }, [props.account]);
    const toLogout = () => {
      store.dispatch(disconnect(''))
      aptosDisconnect()
    }
    useEffect(async () => {
        if ( aptosWallet && aptosConnecting) {
          props.dispatch(setconnect('1'))
          try {
            await aptosConnect()
          } catch(e) {
            console.log(e)
          } finally {
            setAptosConnecting(false)
          }
        }
      }, [aptosWallet, aptosConnecting])

      useEffect(() => {
        console.log(account)
        if ( account?.address) {
          const address = hexStringV0ToV1(account?.address)
          console.log(address.toString())
          props.dispatch(connect(address?.toString()))
          props.dispatch(setconnect(''))
        }
      }, [account]) 

      useEffect(async ()=>{
        if(!props.account) {
          return
        }
        setLoading(true)
        let p = []
        let list = []
        coinList.map(item => {
          p.push(getBalance(props.account, findAddressByName(item)))
        })
        Promise.all(p).then(res => {
          coinList.map((item, index) => {
            list.push({
              name: item,
              value: res[index]
            })
          })
          setAssetList(list)
          setLoading(false)
        })

        let {data:userStore} = await getUserStore(props.account)
        console.log(userStore)
        props.dispatch(setUserStore(userStore))
        
      }, [props.account])
      
      useEffect(() => {
        setShow(false)
      }, [pathname])
      useEffect(() => {
        console.log(props)
        setShow(props.showLogin)
      }, [props.showLogin])


    return (
        <div className='connect'>
            <div className='fz-14 button connect-btn ta pointer hover' onClick={()=>{setShow(!show)}}>
                 {
                    props.account ? addPoint(props.account):'Connect Wallet'
                 }  
            </div>
            <Drawer
              title="My Wallet"
              placement='right'
              closable={true}
              onClose={()=>{setShow(false);hideLogin()}}
              visible={show}
              className="connect-drawer"
              key='right'
            > 
            {
              props.account ? (
                <>
                <div className="name fz-18 fwb ">Wallet Address</div>
                <div className="wallet-item ta flex flex-between w100   pointer p-l-20 p-r-20">
                  <span>
                  {addPoint(props.account)}
                  </span>
                    
                    <span>
                      <Tooltip title="copy address">
                          <CopyOutlined className="pointer m-r-10" onClick={toCopy}/>
                      </Tooltip>
                      <Tooltip title="logout">
                      <LogoutOutlined className="pointer" title="logout" onClick={toLogout}/>
                      </Tooltip>

                    </span>
                </div>
                </>
              ):(
                <>
                <div className="name fz-18 fwb ">Choose Wallet</div>
                    {
                        aptosWallets.map(wallet => {
                            return (<div  key={wallet.adapter.name} className={"wallet-item ta flex flex-center flex-middle pointer "+(wallet.adapter.name == selectWalletName ? 'active':'')} onClick={()=>{
                                handleAptosConnect(wallet.adapter.name)
                            }}> 
                                <img src={wallet.adapter.icon
                      } alt={`${wallet.adapter.name} icon`} className='wallet-logo m-r-5'/>
                                <div className="wallet-name tl">{wallet.adapter.name}</div>
                            
                            </div>)
                    })}
                </>
              )
            }
                    
                    <div className="name fz-18 fwb m-t-24 flex flex-between">
                      <span>
                      My Asset
                      </span>
                      <NavLink to="/profile" className="fz-14 ">
                        <div className='color-font underline'>My Profile {'>'}</div>
                      </NavLink>
                    </div>
                    {
                      props.account ? (
                        <div className='w100 flex flex-wrap flex-between'>
                        {
                          loading ? <div className='w100 flex flex-middle p-t-30 p-b-30'><Loading/></div> :assetList.map(item => {
                            return <div className="account-item flex  flex-center  w50 p-l-5 p-r-5" key={item.name}>
                              <img src={getTokenByName(item.name).icon} alt="" className='token-icon m-r-5'/>
                              <span>
                              {fromUnit(item.value)||'0'} {item.name}
                              </span>
                              
                            </div>
                          })
                        }
                        </div>
                          
                      ):(
                        <div className="no-wallet-account c2b fz-14 m-t-13 flex flex-center flex-column flex-middle">
                          <img src={require('../../assets/image/connect/songshu.svg').default} className="m-b-17" alt="" />
                          no wallet connected
                        </div>
                      )
                    }
                    
                    <div className="name fz-18 fwb m-t-24">Quick Shortcuts</div>
                    <div className="nav-wrap flex flex-between flex-wrap">
                        <NavLink className="nav-item" to="/launch">LaunchPad</NavLink>
                        <NavLink className="nav-item" to="/getquota">Get Quota</NavLink>
                        <NavLink className="nav-item" to="/swap">Swap</NavLink>
                        {
                          props.account && <div className="nav-item pointer" onClick={toLogout}>
                            <LogoutOutlined className="pointer m-r-10" title="logout"/>
                            Logout</div>
                        }
                        
                    </div>

                    <img src={BgTop} alt="" className='bg bg-top' />
                    <img src={BgBottom} className='bg bg-bottom' alt="" />
              </Drawer>
      
            
        </div>
    )
}
export default reducxConnect(
    (state, props) => {
      return {...state, ...props}
    }
  )(
    Connect
  );