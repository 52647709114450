import { Tabs } from 'antd';
import './index.scss'
import {connect as reducxConnect} from 'react-redux'
import airdrop1 from '../../assets/image/airdrop/Airdrop1.svg'
import airdrop2 from '../../assets/image/airdrop/Airdrop2.svg'
import airdrop3 from '../../assets/image/airdrop/Airdrop3.svg'
import airdrop1disabled from '../../assets/image/airdrop/Airdrop1disabled.svg'
import airdrop2disabled from '../../assets/image/airdrop/Airdrop2disabled.svg'
import airdrop3disabled from '../../assets/image/airdrop/Airdrop3disabled.svg'
import { useState } from 'react';
import Airdrop1 from './components/Airdrop1';
import Airdrop2 from './components/Airdrop2';

const Airdrop = (props) => {
    let [tabvalue, setTabvalue] = useState('1')
    return (
        <div className='airdrop'>
            <Tabs defaultActiveKey="1" className='my-tab' onChange={(e) => {setTabvalue(e)}}>
                <Tabs.TabPane tab={
                  <div className='tab-inner'>
                    <img src={tabvalue == '1' ? airdrop1:airdrop1disabled} alt="" className='tab-icon'/>
                    <span className='c23 fz-16 fwb m-l-8'> Airdrop 1</span>
                  </div>
                  } key="1">
                    <Airdrop1/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={
                  <div className='tab-inner'>
                    <img src={tabvalue == '2' ? airdrop2:airdrop2disabled}alt="" className='tab-icon'/>
                    <span className='c23 fz-16 fwb m-l-8'> Airdrop 2</span>
                  </div>
                  } key="2">
                    <Airdrop2/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={
                  <div className='tab-inner'>
                    <img src={tabvalue == '3' ? airdrop3:airdrop3disabled} alt="" className='tab-icon'/>
                    <span className='c23 fz-16 fwb m-l-8'> Airdrop 3</span>
                  </div>
                  } key="3">
                    21123
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}
export default reducxConnect(
    (state, props) => {
      return {...state, ...props}
    }
  )(
    Airdrop
  );