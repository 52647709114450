import logo from '../../assets/image/common/harwell-Testnet.svg';
import menu from '../../assets/image/common/menu.svg';
import { Button, Drawer, Radio, Space, Tooltip } from 'antd';
import global from '../../assets/image/common/global.svg';
import { NavLink, useLocation } from 'react-router-dom'
import community from '../../assets/json/community';
import './Header.scss'
import Connect from './Connect'
import soon from '../../assets/image/nav/soon.svg'
import { useEffect, useState } from 'react';

const nav = [
  {
    name: 'Home',
    link: '/',
    icon: require('../../assets/image/nav/Home.svg').default
  },
  {
    name: 'Marketplace',
    link: '/marketplace',
    icon: require('../../assets/image/nav/Marketplace.svg').default
  },
  {
    name: 'Swap',
    link: '/swap',
    icon: require('../../assets/image/nav/Swap.svg').default
  },
  {
    name: 'Farm',
    link: '/farm',
    icon: require('../../assets/image/nav/Farm.svg').default
  },
  // {
  //   name: 'Get Quota',
  //   link: '/getquota',
  //   icon: require('../../assets/image/nav/Staking.svg').default
  // },
  {
    name: 'Launch',
    link: '/launch',
    icon: require('../../assets/image/nav/Launchpad.svg').default
  },
  {
    name: 'Airdrop',
    link: '/airdrop',
    // disabled: true,
    iscoming: true,
    icon: require('../../assets/image/nav/Airdrop.svg').default
  }
]
export default () => {
  let [show, setShow] = useState(false)
  let [navlist, setNavlist] = useState(nav)
  let { pathname } = useLocation()
  useEffect(() => {
    setShow(false)
  }, [pathname])
  return (
    <>
      <div className="p-t-20 p-l-40 p-r-40 p-b-20 flex flex-between flex-center header">
        <div className="nav-part flex flex-center">
          <img src={logo} alt="" className='harwell-logo' />
          <div className="nav-items fz-16 m-l-80 flex">
            {
              navlist.map(item => {
                return (
                  item.disabled ?
                  <div key={item.name} className="m-r-15 flex flex-center nav-item disabled">
                  <img src={item.icon} alt="" className='m-r-5' />
                  {item.name}
                  {
                    item.iscoming &&  <img src={soon} alt="" className='m-r-5' />
                  }
                  </div>:
                  <NavLink disabled={item.disabled} to={item.link} key={item.name} className="m-r-15 flex flex-center nav-item">
                  <img src={item.icon} alt="" className='m-r-5' />
                  {item.name}
                  {
                    item.iscoming &&  <img src={soon} alt="" className='m-r-5' />
                  }
                  </NavLink>
                )
              })
            }
          </div>
        </div>
        <div className="connect-part flex flex-center ">
          {
            community.map((item, index) => {
              return <a href={item.link} className="m-r-14 community-item" target='_blank' key={index}>
                <img src={item.img} alt="" />
              </a>
            })
          }

            <Tooltip title="My Profile">
              <NavLink to={'/profile'} className="m-l-15">
              <img src={require('../../assets/image/nav/myprofile.png')} alt="" width={25}/>
              </NavLink>
            </Tooltip>
            <Tooltip title="Get Quota">
              <NavLink to={'/getquota'} className="m-l-15">
              <img src={require('../../assets/image/nav/Staking.svg').default} alt="" />
              </NavLink>
            </Tooltip>
          <NavLink to="/swap">
          <div className='buy-btn button fz-14 ta hover'>
            Buy HARW
          </div>
          </NavLink>
          <Connect />
        </div>

        <div className="show-m">
          {/* <img src={global} alt="" className='global'/> */}
          <img src={menu} alt="" className='menu m-l-24' onClick={() => setShow(!show)} />
          <Drawer
            title=""
            placement='right'
            closable={true}
            onClose={() => setShow(false)}
            visible={show}
            className="connect-drawer"
            key='right'
            width={300}
          >
            <div className='flex flex-column nav-mobile w100'>
              {
                navlist.map(item => {
                  return (item.disabled ?
                  <div key={item.name} className="flex flex-center nav-link">
                  <div className="mobile-icon">
                      <img src={item.icon} alt="" className='m-r-5' />
                    </div>
                  {item.name}
                  {
                    item.iscoming &&  <img src={soon} alt="" className='m-r-5' />
                  }
                  </div>:<NavLink to={item.link} key={item.name}>
                    <div className="mobile-icon">
                      <img src={item.icon} alt="" className='m-r-5' />
                    </div>
                    {item.name}
                  </NavLink>)
                })
              }
              <NavLink to={'/getquota'}>
                    <div className="mobile-icon">
                      <img src={require('../../assets/image/nav/Staking.svg').default} alt="" className='m-r-5' />
                    </div>
                    Get Quota
                  </NavLink>
              <NavLink to="/swap">
                <div className='buy-btn button fz-14 ta hover m-t-40 m-b-20 w100'>
                Buy HARW
              </div>
              </NavLink>

              <Connect />
              <div className="flex community-mobile flex-middle m-t-40">
                {
                  community.map((item, index) => {
                    return <a href={item.link} className="m-r-14" target='_blank' key={index}>
                      <img src={item.img} alt="" />
                    </a>
                  })
                }
              </div>

            </div>
          </Drawer>
        </div>
      </div>
      <div className="header-offset"></div>
    </>

  )
}