import './HomeNew.scss';
import BannerNew from '../components/home/bannerNew';
import TradeEarn from '../components/home/TradeEarn';
import NetworkApp from '../components/home/NetworkApp';
import HarwellNFT from '../components/home/HarwellNFT';
import UserHierarchies from '../components/home/UserHierarchies';
import FarmStaking from '../components/home/FarmStaking';
import Theme from '../components/home/Theme';
import SoonHarwell from '../components/home/SoonHarwell';
export default () => {
  return (
    <div className="home-new">
      <BannerNew />
      <TradeEarn />
      <NetworkApp />
      <HarwellNFT />
      <UserHierarchies />
      <FarmStaking />
      <Theme />
      <SoonHarwell />
    </div>
  );
};
