import './index.scss'
import { connect as reducxConnect } from 'react-redux';
import { Button, Select } from 'antd'
import { useEffect, useState } from 'react';
import Loading from '../../components/common/Loading';
import { formatName } from '../../lib/util';
import { getNFTList } from '../../methods/client.ts';
import { getMetaDataById } from '../../methods/nft';
import Modal from '../../components/common/Modal'
import Transfer from './components/transfer';
import selectImg from '../../assets/image/checkbox/selected.png'
import { useNavigate } from "react-router-dom";
import List from './components/list';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';



const optionsSelect = [{
  label: 'Collection Name A-Z',
  value: '1'
},{
  label: 'Floor Price Low-High',
  value: '2'
},{
  label: 'Floor Price High-Low',
  value: '3'
}]


// id:token_id;
// isSelected: 是否选中
// showSelect: 是否显示右上角的选择框
const NFT = ({ metaData, isSelected, showSelect, onSelect, onCancel }) => {
  // let [metaData, setMetaData] = useState({})
  const navigate = useNavigate();
  // useEffect(async() => {
  //   let {
  //     data: {current_token_datas}
  //   } = await getMetaDataById(id)
  //   console.log(current_token_datas)
  //   setMetaData({...current_token_datas[0], property_version})
  //   reportMetaData({...current_token_datas[0], property_version})
  // }, [id])
  return (
    <div className={'nft-wrap flex flex-column pointer ' + (isSelected ? 'selected ' : '') + (showSelect ? 'canselect ' : '')} onClick={() => {
      if (!showSelect) {
        console.log(metaData)
        console.log(encodeURI('#'))

        navigate(`/marketplace/detail/${metaData.token_data_id_hash}`)
      }
      isSelected ? onCancel(metaData) : onSelect(metaData)
    }
    }>
      {
        showSelect ? <div className="check-box-area pointer" >
          {
            isSelected ?
              <img src={selectImg} alt="" className='check-active' /> :
              <div className="check-none"></div>
          }
        </div> : ''
      }

      <img src={metaData.metadata_uri} alt={formatName(metaData.name)} className='nft-cover' />
      <div className='fz-18 c2b p-t-12 p-l-16 p-b-12 nft-name'>
        <span className='oneline-only w100'>{formatName(metaData.name)}</span>
      </div>

    </div>
  )
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})((props) => {

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  const [showTransfer, setShowTransfer] = useState(false)
  const [showList, setShowList] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [refreshTrigger, setRefreshTrigger] = useState(1)
  const [btnType, setBtnType] = useState(0) //1:transfer 2:list for sell
  const [filterText, setFilterText] = useState('')
  const toSelect = (item) => {
    let items = [...selectedItems]
    !items.some(inner => inner.token_data_id_hash == item.token_data_id_hash) && items.push(item)
    console.log(item)
    console.log(items)
    setSelectedItems(items)
  }
  const toCancel = (item) => {
    let items = [...selectedItems]
    items = items.filter(inner => inner.token_data_id_hash != item.token_data_id_hash)
    setSelectedItems(items)
  }

  useEffect(async () => {
    if (props.account) {
      setLoading(true)
      let list = await getNFTList(props.account)
      console.log(list)
      let metaDataList_p = []
      list.map(item => {
        metaDataList_p.push(getMetaDataById(item.token_data_id_hash))
      })
      let metalist = await Promise.all(metaDataList_p)
      console.log(metalist)
      metalist = metalist.map((item, index) => {
        return {
          ...item.data.current_token_datas[0],
          property_version: list[index].property_version
        }
      })
      setList(metalist)
      setLoading(false)
      console.log(list)
    }
  }, [props.account, refreshTrigger])

  return (
    <div className='w100'>
      <div className="middle-content search-right flex gap-20 flex-center">
        {
          props.radioDom
        }
        <div className="flex-1">
          <div className="search-input flex flex-center m-r-26 ">
            <input type="text" className='flex-1' value={filterText} placeholder='Token Name/Token ID' onChange={(e) =>setFilterText(e.target.value)} />
            <div className='p-r-13'>
              <SearchOutlined style={{ fontSize: 20 }} />
            </div>
          </div>
        </div>
        <Select defaultValue="1" className='my-select flex flex-middle flex-center' style={{ width: 120 }} suffixIcon={
          <img src={require('../../assets/image/common/down.svg').default}></img>
        } >
          {
            optionsSelect.map(item => {
              return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
            })
          }
        </Select>
        {
          list.length ? (
            <div>
              {
                showSelect ?
                  <>
                    {
                      btnType == 1 ? <Button className='my-button color' disabled={!selectedItems.length} onClick={() => setShowTransfer(true)}>Transfer selected {selectedItems.length} items</Button> :
                        btnType == 2 ? <Button className='my-button color' disabled={!selectedItems.length} onClick={() => setShowList(true)}>List selected {selectedItems.length} items</Button> : ''
                    }

                    <Button className='my-button m-l-10' onClick={() => { setShowSelect(false); setSelectedItems([]) }}>Cancel</Button>
                  </>

                  :
                  <>
                    <Button className='my-button color' onClick={() => { setShowSelect(true); setBtnType(1) }}>Transfer</Button>
                    <Button className='my-button color m-l-20' onClick={() => { setShowSelect(true); setBtnType(2) }}>List for sell</Button>
                  </>

              }

            </div>

          ) : ''
        }
        <RedoOutlined spin={loading} style={{fontSize: '30px',color: 'rgba(0,0,0,.4)'}} className="pointer" onClick={()=>setRefreshTrigger(refreshTrigger+1)}/>

      </div>

      <div className={'middle-content flex  w100 m-t-10 m-b-10 ' + (showSelect ? 'flex-between' : 'flex-last')} >
        <div className='select-all' onClick={
          () => {
            selectedItems.length == list.length ? setSelectedItems([]) : setSelectedItems(list)
          }
        }>
          {
            showSelect ? <div className="check-box-area pointer flex flex-center" >
              {
                selectedItems.length == list.length ?
                  <img src={selectImg} alt="" className='check-active' /> :
                  <div className="check-none"></div>
              }

              <span className='m-l-8'>{selectedItems.length == list.length ? 'Cancel All' : 'Select All'}</span>

            </div> : ''
          }
        </div>

       

      </div>
      <div className="project-list flex flex-wrap flex-start  gap-30 ">
        {loading ?
          <div className="flex flex-center flex-middle p-t-120 p-b-280 w100">
            <Loading />
          </div> : (
            <>{
              list.map((item, index) => {
                return (!filterText || item.name.toLowerCase().includes(filterText.toLowerCase()) ) && <NFT history={props.history} metaData={item} showSelect={showSelect} id={item.token_data_id_hash} isSelected={selectedItems.some(inner => inner.token_data_id_hash == item.token_data_id_hash)} onSelect={toSelect} onCancel={toCancel} />
              })
            }
              {(list.length && (!filterText || list.some(item =>item.name.toLowerCase().includes(filterText.toLowerCase())))) ? '' : <div className='w100 ta c236 fz-14 p-t-50'>{filterText ?'No Result':'No NFT'}</div>}
            </>

          )}
      </div>
      <Modal isVisible={showTransfer} title="Transfer" onClose={() => setShowTransfer(false)}
        info="The NFT you transfer to other accounts will be displayed in the inbox, and you need to get it manually if necessary." border>
        <Transfer selectList={selectedItems} onClose={() => { setShowTransfer(false); setRefreshTrigger(refreshTrigger + 1); setSelectedItems([]) }} />
      </Modal>

      <Modal isVisible={showList} title="List for sell" onClose={() => setShowList(false)}
        info="You're going to sell these NFTs in the marketplace, and if someone buys them, you're going to get the price you set after deducting some fees" border>
        <List selectList={selectedItems} onClose={() => { setShowList(false); setRefreshTrigger(refreshTrigger + 1); setSelectedItems([]) }} />
      </Modal>
    </div>
  )
});

