import '../index.scss';
import { Button, Skeleton, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as DownCircleOutlined } from '../../../assets/image/common/DownCircleOutlined.svg'
import { ReactComponent as UpCircleOutlined } from '../../../assets/image/common/UpCircleOutlined.svg'
import classNames from 'classnames';
import { ReactComponent as ArrowOpen } from '../../../assets/image/common/down.svg'
import { getTokenByName } from '../../Dex/components/list';
import { calcDays, calcHours, formatTime, fromUnit, hexToString, numFormat, showLogin } from '../../../lib/util';
import Activenumber from '../../../components/common/Activenumber';
import store from '../../../store';
import { getAddress } from '../../../contract';
import { intervalUnit, interval_unit_text, timeUnit } from '../../../global';
import { ClaimTips } from '../../../contract/farmlist';

const FarmListItem = (props) => {
  const [open, setOpen] = useState(false);
  const [lastTime, setLastTime] = useState(0)
  useEffect(()=> {
      if(props.account && props.info.depositions?.last_harvest_time) {
        console.log(props.info.depositions?.last_harvest_time)
        // let intervalUnit = 60*60 // 一个小时
        let userStore = store.getState().userStore
        let level = (userStore?.level && (userStore?.level?.vec[0]?.name)) ?(userStore?.level?.vec[0]?.name): 'none' // 获取等级
        let levelIndex = getAddress().grade.grade.levels.findIndex(item => item.name == level)
        let interval = getAddress().farm.flexible.harvestIntervalMap[levelIndex*1+1].interval*intervalUnit
        let gapTime = new Date().getTime()/1000 - props.info.depositions?.last_harvest_time
        setLastTime(calcHours(interval-gapTime))
      }
  }, [props.account, props.info])
  return (
    <div className='farm-list-item m-b-30 w100'>
      <div className='farm-list-item-header flex flex-between'>
        <div className='token-info flex flex-center'>
          {
            props.info.lptype == 'LPToken' ?

              <div className='coin-logo flex flex-center'>
                {
                  [getTokenByName(props.info.token1).icon, getTokenByName(props.info.token2).icon].map((v, i, arr) => (
                    <img src={v} key={v}  style={{ zIndex: arr.length - i }} />
                  ))
                }
              </div> :

              <div className='coin-logo flex flex-center'>
                {
                  [getTokenByName(props.info.token).icon].map((v, i, arr) => (
                    <img src={v} key={v}  style={{ zIndex: arr.length - i }} />
                  ))
                }
              </div>
          }
          <div className='token-info-name flex flex-center'>
            <div className='m-l-13 fz-24 fwb token-info-name-title'>
              {
                props.info.lptype == 'LPToken' ? (props.info.token1 + '-' + props.info.token2 + '（2Pool LP）') : (props.info.token)
              }
            </div>
            <div className='flex flex-center'>
              {
                props.flexibleType == 'flexible' ?
                  <div className='tag-flexible m-l-8 lh-26 bdr-13 p-l-19 p-r-19'>Flexible</div> :
                  <div className='tag-fixed m-l-8 lh-26 bdr-13 p-l-19 p-r-19'>Fixed</div>
              }
            </div>
          </div>
        </div>
        <div className='flex flex-center token-info-right'>
          {/* <span>View LP Distributio</span> */}
          {/* <YouJianTou className='m-l-3' width={17}></YouJianTou> */}
        </div>
      </div>
      <hr className='m-t-24 m-b-24 hr-line' />
      <div className='flex flex-center pointer center-info-data' onClick={() => { setOpen(o => !o) }}>
        <div className='flex-1 flex flex-between center-info-data-list'>
          <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>My position <Tooltip title=''><QuestionCircleOutlined /></Tooltip></div>
            <div className='value fw-500 c2b lh-20'>
            {typeof(props.info.depositions?.amount) == 'undefined' ?<Skeleton.Button active size={'small'} />:(<><Activenumber value={fromUnit(props.info.depositions?.amount)} decimals={4}/></>)}
              &nbsp;
              {
                props.info.lptype == 'LPToken' ? (props.info.token1 + '-' + props.info.token2 + ' LP') : (props.info.token)
              }</div>
          </div>
          <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>{props.flexibleType == 'fixed' && 'MAX' } APY </div>
            <div className='value cr fw-500 c2b lh-20'>{typeof(props.info.apr) == 'undefined' ?<Skeleton.Button active size={'small'} />:(<><Activenumber value={props.info.apr} decimals={2}/>%</>)}</div>
          </div>
          <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>Earn </div>
            <div className='value fw-500 c2b lh-20'>{props.info.rewardToken}</div>
          </div>

          <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>Pool ends in {typeof(props.info.end_time) != 'undefined' &&<Tooltip title={`pool ends in ${formatTime(props.info.end_time)}`}><QuestionCircleOutlined /></Tooltip>}</div>
            <div className='value fw-500 c2b lh-20'>
              {
                typeof(props.info.end_time) == 'undefined' ?<Skeleton.Button active size={'small'} />:(<><Activenumber value={calcDays(props.info.end_time)} decimals={0}/> days</>)
              }</div>
          </div>

          <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>Weekly Reward </div>
            <div className='value fw-500 c2b lh-20'>
            {
                typeof(props.info.rewards_per_week) == 'undefined' ?<Skeleton.Button active size={'small'} />:<Activenumber value={fromUnit(props.info.rewards_per_week) || '0'} decimals={0}/>
            }
            </div>
          </div>
          <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>Total Staked </div>
            <div className='value fw-500 c2b lh-20'>
              {typeof(props.info.total_deposits) == 'undefined' ?<Skeleton.Button active size={'small'} />:<Activenumber value={fromUnit(props.info.total_deposits) || '0'} decimals={4} />}
              &nbsp;
              {
                props.info.lptype == 'LPToken' ? (props.info.token1 + '-' + props.info.token2 + ' LP') : (props.info.token)
              }
            </div>
          </div>
          {/* <div className='center-info-data-list-item'>
            <div className='label fz-14 lh-18 m-b-8'>Governance Weight </div>
            <div className='value fw-500 c2b lh-20 tr'>46.23</div>
          </div> */}
        </div>
        {
          open
            ? (
              <DownCircleOutlined className='m-l-64 m-r-8 open-icon' width={24} height={24} ></DownCircleOutlined>
            ) : (<UpCircleOutlined className='m-l-64 m-r-8 open-icon' width={24} height={24}></UpCircleOutlined>)
        }
      </div>
      {
        open && (
          <div className='m-t-16 flex open-box'>
            <div className='flex-1 flex c2b lh-30 border-r p-t-30 p-b-30 p-l-40 p-r-40 open-box-left'>
              <div className='flex-1'>
                <div className=''>Earned</div>
                <div className='fwb fz-18'>
                {
                  typeof(props.info.pending_reward) == 'undefined' ?<Skeleton.Button active size={'small'} />:<Activenumber value={fromUnit(props.info.pending_reward)} decimals={4} /> 
                }
                  &nbsp;{props.info.rewardToken}</div>
                {/* <div className='fz-14 fw-500'>≈ $1,000.00</div> */}
              </div>
              {
                props.account && (props.info.lptype == 'single' && props.flexibleType == 'flexible' ?
                  <Button className='color supply-btn fwb c231 fz-14 fwb m-t-30 ta hover' loading={props.claimLoading} onClick={props.showReward}>Reward</Button> :
                   <span className="flex flex-column">
                    {
                      props.flexibleType == 'fixed'?<Button className='color supply-btn fwb c231 fz-14 fwb m-t-30 ta hover' disabled={props.info.depositions?.amount <=0} loading={props.claimLoading} onClick={props.showDetail}>Stake Detail</Button>:
                      <Button className='color supply-btn fwb c231 fz-14 fwb m-t-30 ta hover' disabled={lastTime || props.info.pending_reward <=0} loading={props.claimLoading} onClick={props.toClaim}>Claim</Button>
                    }
                        
                        {
                          lastTime ? <span className='fz-12 c236'>Unable in {lastTime} &nbsp; 
                          <Tooltip title={<ClaimTips/>}><QuestionCircleOutlined /></Tooltip>
                          <Tooltip title="Get Quota">
                             <a href="/getquota" target="_blank" className='m-l-5'>
                             <img src={require('../../../assets/image/farm/speed.svg').default} alt="" />
                            </a>
                          </Tooltip>
                          
                         </span>:''
                        }
                   </span>
                  )
              }
            </div>
            <div className='flex-1 c2b lh-30 p-t-20 p-l-40 p-r-40 p-b-30 open-box-right'>
              <div className='flex flex-center open-box-right-header'>

                <div className='flex-1'>
                  <div className="flex flex-center gap-15">
                    <div>
                      <div>
                        Staked
                        &nbsp;  <a className='cr' href={props.info.lptype == 'LPToken' ?`/swap?type=liquidity&cointype=${props.info.token1}-${props.info.token2}`:'/swap'}>Get {
                          props.info.lptype == 'LPToken' ? (' LP') : (props.info.token)
                        }</a>
                      </div>
                      <div>
                      {typeof(props.info.depositions?.amount) == 'undefined' ?<Skeleton.Button active size={'small'} />:(<><Activenumber value={fromUnit(props.info.depositions?.amount)} decimals={4}/></>)}
                        &nbsp;
                        {
                          props.info.lptype == 'LPToken' ? (' LP') : (props.info.token)
                        }
                      </div>
                    </div>
                    {
                      props.flexibleType == 'fixed' && (
                        <div>
                          {(() => {
                            let lock_units = props.info.depositions.lock_units * 1
                            if (!lock_units) {
                              return ''
                            }
                            let unlockdate = props.info.depositions?.deposit_time * 1 + props.info.depositions?.lock_units * timeUnit
                            return (
                              <>
                                <div>
                                  Locked
                                </div>
                                <div>
                                  {(lock_units || '0')+' week(s) (unlock time: '+ formatTime(unlockdate)+')'}
                                </div>
                              </>
                            )

                          })()
                          }
                        </div>
                      )
                    }


                  </div>

            


                </div>
                <div className='flex flex-column'>
                  {
                    !props.account ? (<Button className='color supply-btn fwb c231 fz-14 fwb  ta hover' onClick={showLogin}>Connect Wallet </Button>):props.flexibleType == 'fixed' ? (
                      <Button className='color supply-btn fwb c231 fz-14 fwb  ta hover' onClick={props.showStake}>Fixed Stake </Button>
                    ) : (
                      <Button className='color supply-btn fwb c231 fz-14 fwb  ta hover' onClick={props.showStake}>Stake </Button>
                    )
                  }
                  {
                    props.info.depositions?.amount > 0 && props.flexibleType == 'flexible' && <Button className='unstake-btn supply-btn fwb c231 fz-14 fwb m-t-15 ta hover' onClick={props.showUnStake}>UnStake </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className='mobile-arrow-open'>
        {
          <ArrowOpen className={classNames({
            'rotate180': open
          })} width={22} height={12} onClick={() => { setOpen(o => !o) }}></ArrowOpen>
        }
      </div>
    </div>
  )
}

export default FarmListItem