import '../index.scss';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { getTokenByName } from '../../Dex/components/list';
import { getLpToken } from '../../../methods/farm';
import { findAddressByName, fromUnit, OpenNotification, toFixed, toUnit } from '../../../lib/util';
import { explorerUrl, getAddress, getNodeUrl } from '../../../contract';
import { useSubmitTransiction } from '../../../methods/submit';

const decimal = 6

const UnStake = ({info, account, stakeNum, onSuccess}) => {
  const [bal, setBal] = useState(fromUnit(stakeNum)||0)
  const [inputValue, setInputValue] = useState('')
  const [activePercent, setActivePercent] = useState(0)
  const [loading, setLoading] = useState(false)
  const { submitTransiction } = useSubmitTransiction()


  const toPercent = (percent) => {
    setActivePercent(percent)
    if(bal>0) {
      setInputValue(toFixed(bal*percent/100,4))
    }
  }
  const toUnstake = async() => {
    setLoading(true)
    let payload = {
      type: "script_function_payload",
      function: `${getAddress().farm.flexible.address}::withdraw_script`,
      type_arguments: [info.lptype=='LPToken'? getLpToken(findAddressByName(info.token1),findAddressByName(info.token2)):findAddressByName(info.token), findAddressByName(info.rewardToken)],
      arguments: [toUnit(inputValue)],
      };
      submitTransiction(payload, () => {
        setLoading(false)
        setInputValue('')
        setActivePercent(0)
        onSuccess()
      }, () => {
        setLoading(false)
      })

  }
  useEffect(() => {
    setBal(fromUnit(stakeNum))
  }, [stakeNum])
  return (
    <div className='w100 stake-modal'>
      <div className="w100 fz-14 c2b tr">Total Staked: {fromUnit(stakeNum)||'0'}</div>
      <div className="bgEEF w100 p-l-16 p-t-22 p-b-22 p-r-16 flex bdr-24 m-t-10 flex-center">
        {
          info.lptype == 'LPToken' ?
          <div className='c2b fwb fz-18'>
            <img src={getTokenByName(info.token1).icon} alt="icon" className='token-icon' />
            <img src={getTokenByName(info.token2).icon} alt="icon" className='token-icon right-icon' />
            <span className='m-l-9'>
              {info.token1}-{info.token2}
            </span>
          </div>:
          <div className='c2b fwb fz-18'>
          <img src={getTokenByName(info.token).icon} alt="icon" className='token-icon' />
          <span className='m-l-9'>
             {info.token}
          </span>
        </div>
        }
        
        
        <input type="text" onChange={
          (e) => {
            setActivePercent(0)
            setInputValue(e.target.value)
          }
        } value={inputValue} className='com_input flex-1 tr fz-20 fwb' placeholder='0.00' />
      </div>
      {/* bgEEF w100  flex bdr-24 m-t-10 */}
      <div className="p-l-16 p-t-16 p-b-22 p-r-16 progress-wrap bdr-24 m-t-12">
        {/* <Progress /> */}
        {/* <div className="flex w100 percent-area m-t-12"> */}
        <div className="flex w100 percent-area">
          <span className={'percent-item pointer c2b fwb p-t-5 p-b-5 '+ (activePercent == 25 ? 'active':'')} onClick={()=>toPercent(25)}>25%</span>
          <span className={'percent-item pointer c2b fwb p-t-5 p-b-5 '+ (activePercent == 50 ? 'active':'')} onClick={()=>toPercent(50)}>50%</span>
          <span className={'percent-item pointer c2b fwb p-t-5 p-b-5 '+ (activePercent == 75 ? 'active':'')} onClick={()=>toPercent(75)}>75%</span>
          <span className={'percent-item pointer c2b fwb p-t-5 p-b-5 '+ (activePercent == 100 ? 'active':'')} onClick={()=>toPercent(100)}>MAX</span>
        </div>
      </div>
      <Button onClick={toUnstake} loading={loading} disabled={inputValue <=0} className="color w100 bdr-24 confirm-btn m-t-15 fz-16 fwb">
        Confirm
      </Button>

    </div>
  )
}


export default UnStake