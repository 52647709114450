import '../index.scss';
import { Button, Skeleton } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getTokenByName } from '../../Dex/components/list';
import { getLpToken } from '../../../methods/farm';
import { findAddressByName, formatTime, fromUnit, OpenNotification, testInput, toFixed, toUnit } from '../../../lib/util';
import { explorerUrl, getAddress, getNodeUrl } from '../../../contract';
import { useSubmitTransiction } from '../../../methods/submit';
import { getBalance} from '../../../methods/client.ts';
import { format } from 'path';
import { timeUnit } from '../../../global';
import myContext from '../createContext';
import { getPendingRewardByIndex } from '../../../methods/farmfixed';

const Stake = ({info, account, toStake}) => {
  const [bal, setBal] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [activePercent, setActivePercent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [balanceRefresh, setBalanceRefresh] = useState(1)
  const { submitTransiction } = useSubmitTransiction()
  const {forceRefresh} = useContext(myContext)
  const toWithdraw = async(deposit_id) => {
    setLoading(true)
    let payload = {
      type: "script_function_payload",
      function: `${getAddress().farm.fixed.address}::withdraw_script`,
      type_arguments: [info.lptype=='LPToken'? getLpToken(findAddressByName(info.token1),findAddressByName(info.token2)):findAddressByName(info.token), findAddressByName(info.rewardToken)],
      arguments: [deposit_id],
      };
      console.log(info)
      console.log(payload)
      submitTransiction(payload, () => {
        forceRefresh()
        setLoading(false)
      }, () => {
        forceRefresh()
        setLoading(false)
      })
  }

  return (
    <div className='w100 stake-modal'>
     <div className='stake-list-area'>
     {
      info.deposit_list.map((item, index) => {
         console.log(item)
         return (
          <div className='stake-item m-t-20' key={index}>
            <div className="fz-18 fwb">
              {fromUnit(item.amount)} {
            info.lptype == 'LPToken' ?
            (info.token1+'-'+info.token2):
            info.token
            } {info.lptype == 'LPToken' && 'LP'} Fixed  </div>
            <div className="flex flex-between flex-center">
                <div className="flex flex-column">
                  <div className="fz-12 c236 m-t-5">Stake duration: <span className='c2b fwb'>{item.lock_units} weeks</span> <span className="apr fz-12 m-l-5">APY:{toFixed((info.apr/11.2)+((info.apr/11.2)*0.2*(item.lock_units-1)), 2)}%</span></div>
                  <div className="fz-12 c236 m-t-5">Stake Time: <span className='c2b fwb'>{formatTime(item.deposit_time)}</span></div>
                  <div className="fz-12 c236 m-t-5">Unlock Time: <span className='c2b fwb'>{formatTime(item.deposit_time*1+(item.lock_units*timeUnit)*1)}</span></div>
                  <div className="fz-12 c236 m-t-5">Reward Amount: <span className='c2b fwb'>{fromUnit(info.pending_rewards[index])} HARW</span></div>
                </div>
                <Button className='color bdr-12 my-button' loading={loading} onClick={()=>toWithdraw(item.deposit_id)} disabled={(item.deposit_time*1+(item.lock_units*timeUnit)*1) > (new Date().getTime()/1000)}>Withdraw</Button>
            </div>
          </div>
         )

      })
     }
     </div>
     
     {
      info.deposit_list.length==0 && <div className='ta w100 c05 m-t-20'> No Stake Info found</div> 
     }
      
      <div className="get-text ta m-t-16 pointer" onClick={toStake}>
        Stake more Fixed {
         info.lptype == 'LPToken' ?
         (info.token1+'-'+info.token2):
         info.token
        } {info.lptype == 'LPToken' && 'LP'}
      </div>

    </div>
  )
}


export default Stake