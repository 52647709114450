import './FarmStaking.scss';
import IncomeHouse from '../../assets/image/home/income-house.png';

export default () => {
  return (
    <div className="farm-staking">
      <div className="content-wrapper">
        <div className="left">
          <div className="c2b fwb text-1">Farm extra income by staking</div>
          <div className="fw-400 text-2">
            You can pledge your assets on a farm to
            <span class="fwb"> get a higher Apr</span>
          </div>
          <div className="btn-1 ta hover c2b fwb">Farm</div>
        </div>
        <img src={IncomeHouse} alt="" />
      </div>
    </div>
  );
};
