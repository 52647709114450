import './Project.scss'
import { Breadcrumb, Button, Tabs} from 'antd';
import logo from '../assets/image/projectdetail/logo.png'
import die from '../assets/image/projectdetail/die.png'
import songshu from '../assets/image/projectdetail/songshu.png'
import token from '../assets/image/projectdetail/token.png'
import voice from '../assets/image/projectdetail/voice.png'
import slogan from '../assets/image/projectdetail/slogan.jpg'
import mogu from '../assets/image/projectdetail/mogu.png'
import songzi from '../assets/image/projectdetail/songzi.png'
import community from '../assets/json/community';
import Progress from '../components/common/idoprogress'
import {Link, useParams} from 'react-router-dom'
import { useEffect, useState } from 'react';
import { getProject } from '../api/launchpad';
import { formatTime } from '../lib/util';
import project from '../components/project';
const {TabPane} = Tabs
const Ido = ({project})=>{
    return (
        <div className="project-participate p-l-24 p-r-24 p-b-24">
            <img src={voice} alt="" className='voice'/>
            <div className="status ongoing fz-14">Ongoing</div>
            <div className="ido-title">
                IDO Progress
            </div>
            <div className="fz-14 c236">Total Cap</div>
            <div className="c231 fz-40 fwb m-b-10">${project.totalUsd}</div>
            <Progress/>
            <div className="ido-info fz-14 p-l-16 p-r-16 p-t-8 p-b-8 m-t-23">
                <div className="ido-info-item w100 flex flex-between p-t-8 p-b-8">
                    <span className="c231 fwb">Swapped</span>
                    <span className="c236">10000 USDC</span>
                </div>
                <div className="ido-info-item w100 flex flex-between p-t-8 p-b-8">
                    <span className="c231 fwb">Remaining</span>
                    <span className="c236">10000 USDC</span>
                </div>
                <div className="ido-info-item w100 flex flex-between p-t-8 p-b-8">
                    <span className="c231 fwb">Swap rate</span>
                    <span className="c236">1 {project.tokenSymbol} = {project.totalUsd/project.saleAmount} USDC</span>
                </div> 
                <div className="hr m-t-8 m-b-8"></div>
                <div className="ido-info-item w100 flex flex-between p-t-8 p-b-8">
                    <span className="c231 fwb">Your Allocation</span>
                    <span className="c236">10000 USDC</span>
                </div>

                <div className="ido-info-item w100 flex flex-between p-t-8 p-b-8">
                    <span className="c231 fwb">Already Swaped</span>
                    <span className="c236">10000 USDC</span>
                </div>

                <div className="ido-info-item w100 flex flex-between p-t-8 p-b-8">
                    <span className="c231 fwb">Remaining Allocation</span>
                    <span className="c236">10000 USDC</span>
                </div>

            </div>
            <div className="amount-area flex p-6 flex-center m-t-20">
                <input type="number" className='flex-1 p-l-6' placeholder='Input your amount'/>
                <div className="unit c236 ta">USDC</div>
            </div>
            <Button className='color w100 fwb part-btn m-t-12'>
                <span className='fz-16 fwb'>
                    Participate in IDO
                </span>
            </Button>
            {/* <div className="expect ta m-t-12 fz-13">Expected 1000KEPL</div> */}


        </div>
    )
    
}
const ClaimList = () => {
    return (
        <table  className='claim-table m-t-14'>
                <tr>
                    <th>Swap Date</th>
                    <th>Swap Date</th>
                    <th>Allocation</th>
                    <th>Swap Date</th>
                    <th>Action</th>
                </tr>
                <tr>
                    <td>2022-11-12 08:00:00</td>
                    <td>1000 USDC</td>
                    <td>2000 KEPL</td>
                    <td>2022-11-12 08:00:00</td>
                    <td>
                        <span className="claim-btn">Claim</span>
                    </td>
                </tr> 
                <tr>
                    <td>2022-11-12 08:00:00</td>
                    <td>1000 USDC</td>
                    <td>2000 KEPL</td>
                    <td>2022-11-12 08:00:00</td>
                    <td>
                        <span className="claim-btn disabled">Claim</span>
                    </td>
                </tr> 

            </table>
    )
}
const Detail = ({project}) => {
    return (
        <div className='p-t-10'>
            <div className="desc fz-18 c231 m-b-24">
                        {project.describe}         
            </div>
            <div className="project-info-items flex flex-wrap">
                <div className="project-info-item flex flex-column m-t-24">
                    <span className='c23 fz-14 info-type'>Project Name</span>
                    <span className='c231 fz-16 info-type-content'>{project.name}</span>
                </div>
                <div className="project-info-item flex flex-column m-t-24">
                    <span className='c23 fz-14 info-type'>Token Symbol</span>
                    <span className='c231 fz-16 info-type-content'>{project.tokenSymbol}</span>
                </div>

                <div className="project-info-item flex flex-column m-t-24">
                    <span className='c23 fz-14 info-type'>Total Supply</span>
                    <span className='c231 fz-16 info-type-content'>{project.totalSupply}</span>
                </div>


                <div className="project-info-item flex flex-column m-t-24">
                    <span className='c23 fz-14 info-type'>TGE</span>
                    <span className='c231 fz-16 info-type-content'>{project.tge}</span>
                </div>


                <div className="project-info-item flex flex-column m-t-24">
                    <span className='c23 fz-14 info-type'>Opens</span>
                    <span className='c231 fz-16 info-type-content'>{formatTime(project.startTime)}</span>
                </div>


                <div className="project-info-item flex flex-column m-t-24">
                    <span className='c23 fz-14 info-type'>Closes</span>
                    <span className='c231 fz-16 info-type-content'>{formatTime(project.endTime)}</span>
                </div>
            </div>
            <div className="c23 m-t-18">Schedule</div>
            <table  className='schedule-table m-t-14'>
                <tr>
                    <th>Round</th>
                    <th>Opens</th>
                    <th>Closes</th>
                </tr>
                {
                    project && JSON.parse(project?.rounds||'[]').map(item => {
                        return (
                            <tr key={item}>
                                <td>{item.record}</td>
                                <td>{formatTime(item.opens)}</td>
                                <td>{formatTime(item.closes)}</td>
                            </tr> 
                        )
                        
                    })
                }

            </table>
        </div>
    )
}
export default (props) => {
    let {id} = useParams()
    let [project, setProject] = useState({})
    useEffect(async()=>{
        let {data:{data:{project}}} = await getProject({id})
        console.log(project)
        setProject(project)
    }, [id])
    return (
        <div className='project-detail p-t-40'>
            <div className="project-inner">
                <img src={die} alt="" className='die-img show-p'/>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item href="/launchpad">Project</Breadcrumb.Item>
                    <Breadcrumb.Item>{project.name}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="project-header flex flex-between flex-center m-t-34">
                     <div className="project-brief flex flex-start">
                          <img src={project.logo} alt="" className='logo'/>
                          <div className="project-name-box flex flex-column m-l-22">
                             <div  className='fz-32 fwb project-name'>{project.name}</div>
                             <div className='flex flex-center fz-14'>
                                <span className='c23 '>Token Symbol:</span>
                                <img src={token} alt="" className='token-icon m-l-12'/>
                                <span className='m-l-5 fwb'>{project.tokenSymbol}</span>
                             </div>
                          </div>
                     </div>
                     <div className="project-link">
                         <img src={songshu} alt="" className='songshu m-r-50'/>
                         <span>
                            {
                                community.map((item, index) => {
                                    return <a href={item.link} className="m-r-18" key={item.link}>
                                        <img src={item.img} alt="" />
                                    </a>
                                })
                            }
                         </span>
                         
                        <Button className="color add-btn m-l-40"><span className='fwb fz-14'>Add to Wallet</span></Button>

                     </div>
                </div>
                <div className="project-info m-t-40 p-b-100 flex flex-between">
                    <div className="project-info-detail">
                        <img src={slogan} alt="" className='slogan m-b-40'/>
                        <Tabs defaultActiveKey="1" className='my-tab '>
                            <Tabs.TabPane tab={
                                <div>
                                    <img src={mogu} alt="" width={28}/>
                                    <span className='c23 fz-16 fwb m-l-8'>Project Detail</span>
                                </div>
                            } key="1">
                             <Detail project={project}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={
                                <div>
                                    <img src={songzi} alt="" width={28}/>
                                    <span className='c23 fz-16 fwb m-l-8'>Claim</span>
                                </div>
                            } key="2">
                              <ClaimList/>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                    <Ido project={project}/>
                    

                </div>
                
            </div>
        </div>
    )
}