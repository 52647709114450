
import Home from '../pages/Home'
import HomeNew from '../pages/HomeNew'
import LaunchPad from '../pages/Launchpad'
import Project from '../pages/Project'
import Submit from '../pages/Submit'
import Stake from '../pages/Stake'
import Dex from '../pages/Dex/index'
import Farm from '../pages/Farm/index.jsx'
import FarmReward from '../pages/Reward/FarmReward.jsx'
import Marketplace from '../pages/Marketplace'
import Collection from '../pages/Marketplace/collection'
import Detail from '../pages/Marketplace/detail'
import Launch from '../pages/Marketplace/launchpad'
import Profile from '../pages/Profile'
import Airdrop from '../pages/Airdrop'
import Twitter from '../pages/Twitter'

export default [
    {
        path: '/',
        component: <HomeNew/>
    },
    {
        path: '/twitter',
        component: <Twitter/>
    },
    {
        path: '/launch',
        component: <LaunchPad/>
    },
    {
        path: '/launchpad/project/:id',
        component: <Project/>
    },
    {
        path: '/submit',
        component: <Submit/>
    },
    {
        path: '/getquota',
        component: <Stake/>
    },
    {
      path: '/swap',
      component: <Dex/>
    },
    {
      path: '/farm',
      component: <Farm />
    },
    {
      path: '/farm/reward',
      component: <FarmReward />
    },
    {
      path: '/marketplace',
      component: <Marketplace />
    },
    {
      path: '/marketplace/collection',
      component: <Collection />
    },
    {
      path: '/marketplace/detail/:token_hash_id',
      component: <Detail />
    },
    {
      path: '/marketplace/detail/:creator/:collection/:name',
      component: <Detail />
    },
    {
      path: '/marketplace/launchpad',
      component: <Launch />
    },
    {
      path: '/profile',
      component: <Profile />
    },
    {
      path: '/airdrop',
      component: <Airdrop />
    }
]