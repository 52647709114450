import { Button, Skeleton } from 'antd';
import { connect as reducxConnect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getTokenByName } from '../../Dex/components/list';
import { getLpToken } from '../../../methods/farm';
import {
  findAddressByName,
  fromUnit,
  toFixed,
  toUnit,
  testInput,
} from '../../../lib/util';
import { explorerUrl, getAddress, getNodeUrl } from '../../../contract';
import { useSubmitTransiction } from '../../../methods/submit';
import { getBalance } from '../../../methods/client.ts';
import farmlist from '../../../contract/farmlist';
import { getPendingReward } from '../../../methods/farm';
import useInterval from '@use-it/interval';
import { refresh_interval } from '../../../global';
import { getAvailabePoints } from '../../../methods/unlocker';

function RewardUnStakeLP(props) {
  const [bal, setBal] = useState(0);
  const [unlockInfo, setUnlockerInfo] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [pendingReward, setPendingReward] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [activePercent, setActivePercent] = useState(0);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [balanceRefresh, setBalanceRefresh] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const { submitTransiction } = useSubmitTransiction();
  const toPercent = (percent) => {
    setActivePercent(percent);
    if (bal > 0) {
      setInputValue(
        toFixed((fromUnit(unlockInfo.deposit_amount) * percent) / 100, 4)
      );
    }
  };

  const toUnstake = async () => {
    setLoading(true);
    let payload = {
      type: 'script_function_payload',
      function: `${getAddress().farm.unlocker.address}::withdraw_script`,
      type_arguments: [
        getLpToken(findAddressByName('HARW'), findAddressByName('USDC')),
      ],
      arguments: [toUnit(inputValue)],
    };
    submitTransiction(
      payload,
      () => {
        setLoading(false);
        setInputValue('');
        setBalanceRefresh(balanceRefresh + 1);
        props.onSuccess();
      },
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(async () => {
    if (props.account) {
      setLoadingData(true);
      // 获取总质押奖励数量
      let pending_p = [];
      farmlist.map((item) => {
        let depositToken =
          item.lptype == 'LPToken'
            ? getLpToken(
                findAddressByName(item.token1),
                findAddressByName(item.token2)
              )
            : findAddressByName(item.token);
        item.type == 'flexible' &&
          item.lptype == 'single' &&
          pending_p.push(
            getPendingReward(
              props.account,
              depositToken,
              findAddressByName(item.rewardToken)
            )
          );
      });
      let result = await Promise.all(pending_p);
      if (result.length == 0) {
        setPendingReward(0);
      } else {
        setPendingReward(
          result.reduce((old, now) => {
            return old * 1 + now * 1;
          }, 0)
        );
      }

      let unlocker_info = await getAvailabePoints(props.account);
      setUnlockerInfo(unlocker_info);
      setLoadingData(false);
    } else {
      setPendingReward(0);
    }
  }, [props.info, props.account, refreshTrigger]);

  useEffect(async () => {
    if (props.account) {
      setBalanceLoading(true);
      let bal = await getBalance(
        props.account,
        getLpToken(findAddressByName('HARW'), findAddressByName('USDC'))
      );
      setBal(toFixed(fromUnit(bal), 4));
      setBalanceLoading(false);
    }
  }, [props.info, props.account, balanceRefresh]);
  useInterval(() => {
    setRefreshTrigger(refreshTrigger + 1);
  }, refresh_interval);

  return (
    <div className="unstake-modal">
      <div className="flex flex-between m-t-28">
        <span className="fz-14 c236">
          <img
            src={getTokenByName('HARW').icon}
            alt=""
            className="token-icon"
          />
          <img
            src={getTokenByName('USDC').icon}
            alt=""
            className="token-icon right-icon"
          />
          <span className="m-l-5 fwb">HARW-USDC</span>
        </span>
        <span className="fz-14 c236 fw500">
          Balance:{' '}
          {balanceLoading ? (
            <Skeleton.Button active size={'small'} />
          ) : (
            bal || '0'
          )}
        </span>
      </div>
      <div className="bgf w100 p-l-16 p-r-16 p-t-8 p-b-8 bdr-16 m-t-12">
        <div className="w100 flex flex-center fwb">
          <input
            type="text"
            placeholder="0"
            className="flex-1 com_input p-8 fz-20"
            value={inputValue}
            onChange={(e) => {
              if (testInput(e.target.value)) {
                return;
              }
              setActivePercent(0);
              setInputValue(e.target.value);
            }}
          />
          <span className="fz-14">LP</span>
        </div>
        <div className="flex flex-last">
          <span
            className={
              'pointer fz-12 c236 m-l-16 ' +
              (activePercent === 25 ? 'active' : '')
            }
            onClick={() => toPercent(25)}
          >
            25%
          </span>
          <span
            className={
              'pointer fz-12 c236 m-l-16 ' +
              (activePercent === 50 ? 'active' : '')
            }
            onClick={() => toPercent(50)}
          >
            50%
          </span>
          <span
            className={
              'pointer fz-12 c236 m-l-16 ' +
              (activePercent === 75 ? 'active' : '')
            }
            onClick={() => toPercent(75)}
          >
            75%
          </span>
          <span
            className={
              'pointer fz-12 c236 m-l-16 ' +
              (activePercent === 100 ? 'active' : '')
            }
            onClick={() => toPercent(100)}
          >
            MAX
          </span>
        </div>
      </div>
      <div className="info p-16 m-t-16 lh-28">
        <div className='flex flex-between'>
          <span className='fz-12'>Stake amount</span>
          <span className='fwb'>500>700 HARW-USDC LP</span>
        </div>
        <div className='flex flex-between'>
          <span className='fz-12'>Locked amount</span>
          <span className='fwb'>20100HARW</span>
        </div>
        <div className='flex flex-between'>
          <span className='fz-12'>Unlock speed</span>
          <span className='fwb'>0.55>0.88 HARW/block</span>
        </div>
        <div className='flex flex-between'>
          <span className='fz-12'>Estimated time of completion</span>
          <span className='cr'> 2023-02-09 21:00</span>
        </div>
      </div>
      <Button
        className="confirme-btn color  fwb c232 m-t-30 ta fz-16"
        loading={loading}
        disabled={
          inputValue <= 0 ||
          inputValue > fromUnit(unlockInfo.deposit_amount) * 1
        }
        onClick={toUnstake}
      >
        Confirm Stake
      </Button>
    </div>
  );
}

export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(RewardUnStakeLP);
