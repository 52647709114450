import { connect as reducxConnect } from 'react-redux';
import { useState } from 'react';
import './FarmReward.scss';
import { Col, Row, Progress, Button } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import RewardFarmList from './components/RewardFarmList.jsx';
import RewardSwapList from './components/RewardSwapList.jsx';
import RewardMarketplace from './components/RewardMarketplace';
import RewardAirdropList from './components/RewardAirdropList';
import ReawrdFAQ from './components/RewardFAQ';
import RewardStackToUnLock from './components/RewardStackToUnLock';
import RewardUnStakeLP from './components/RewardUnStakeLP';
import RewardCalculate from './components/RewardCalculate';
import Modal from '../../components/common/Modal';
function FarmReward(props) {
  const [unLock, setUnLock] = useState(false);
  const [unStake, setUnStake] = useState(false);
  const [calculate, setCalculate] = useState(false);

  const refreshTrigger = () => {};
  return (
    <div className="reward">
      <div className='reward-header-banner'>
        <div className="reward-banner-content flex flex-column flex-middle">
          <div className='fz-40 c231 lh-50 reward-banner-content-name'>Reward</div>
          <div className="c231 fz-20 m-t-8 reward-banner-content-data">Stake HARW-USDC LP to unlock your reward</div>
          {/* <NavLink to="/submit" className='color supply-btn c231 fz-14 fwb m-t-30 ta hover' >Supply</NavLink> */}
        </div>
      </div>
      <div className="reward-content">
        <Row gutter={20}>
          <Col xs={24} xl={12}>
            <div className="gutter-row">
              <div className="top">
                <span className="left fw500">Remained Reward Amount</span>
                <div className="right">
                  <div className="content" style={{ marginRight: '40px' }}>
                    <div className="title">Claimable</div>
                    <div className="text fwb">10500 HARW</div>
                  </div>
                  <div className="content">
                    <div className="title">Locked</div>
                    <div className="text fwb">20100 HARW</div>
                  </div>
                </div>
              </div>
              <div className="middle fwb">30600 HARW</div>
              <div className="bottom">
                <Progress
                  showInfo={false}
                  percent={50}
                  strokeColor={{ '0%': '#F2F6A1', '100%': '#DC849E' }}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="gutter-row">
              <div className="top">
                <span className="left fw500">Unlocker staked</span>
                <div className="right">
                  <div className="content">
                    <div className="title">Unlock speed</div>
                    <div className="text fwb c232">
                      0.56 HARW/block{' '}
                      <CalculatorOutlined onClick={() => setCalculate(true)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="middle fwb">500 HARW-USDC LP</div>
              <div className="bottom">
                <Button
                  className="color supply-btn  c231 fz-14 ta hover m-r-12"
                  onClick={() => setUnLock(true)}
                >
                  Stake more to speed up{' '}
                </Button>
                <Button
                  className=" supply-btn  c231 fz-14 m-t-15 ta hover unstake-btn"
                  onClick={() => setUnStake(true)}
                >
                  UnStake{' '}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="detail fwb">Reward detail</div>
        <RewardFarmList />
        <RewardSwapList />
        <RewardMarketplace />
        <RewardAirdropList />
        <ReawrdFAQ />
      </div>
      <Modal
        isVisible={unLock}
        title="Stake to unlock"
        onClose={() => setUnLock(false)}
        info="The Reward need unlock by staking HARW-USDC to obtain rewards. the more lp you staked ,the faster speed unlock you can get."
        border
      >
        <RewardStackToUnLock
          info={props.info}
          account={props.account}
          toUnLock={() => {
            setUnLock(false);
          }}
          onSuccess={() => refreshTrigger()}
        />
      </Modal>
      <Modal
        isVisible={unStake}
        title="UnStake Lp"
        onClose={() => setUnStake(false)}
        info="The Reward need unlock by staking HARW-USDC to obtain rewards. the more lp you staked ,the faster speed unlock you can get."
        border
      >
        <RewardUnStakeLP
          info={props.info}
          account={props.account}
          toStake={() => {
            setUnStake(false);
          }}
          onSuccess={() => {
            refreshTrigger();
          }}
        />
      </Modal>
      <Modal
        isVisible={calculate}
        title="Calculate unlock speed"
        onClose={() => setCalculate(false)}
      >
        <RewardCalculate info={props.info} account={props.account} />
      </Modal>
    </div>
  );
}
export default reducxConnect((state, props) => {
  return { ...state, ...props };
})(FarmReward);
