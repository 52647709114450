import logo from '../../assets/image/common/harwell_w.svg';
import community from '../../assets/json/community';
import './Footer.scss'
export default () => {
    return (
        <div className="footer p-t-70">
            <div className="footer-inner">
                <div className="footer-top flex flex-start flex-between p-b-56">
                    <div className="left-part">
                        <img src={logo} alt="" />
                        <div className="buy-btn fz-14 cf ta m-t-140">Buy XToken</div>

                        <span className='show-m m-t-35'>
                        {
                            community.map((item, index) => {
                                return <a href={item.link} className="m-r-14" key={index}>
                                    <img src={item.imgw} alt="" />
                                </a>
                            })
                        }
                        </span>
                    </div>
                    <div className="right-part cf flex p-t-20">
                        <div className="nav-item">
                            <div className="nav-name fz-16 fwb">
                                Products
                            </div>
                            <div className="nav-item-inner fz-14 m-t-56">
                                Launchpad
                            </div>
                            <div className="nav-item-inner fz-14 m-t-20">
                                Staking
                            </div>

                            <div className="nav-item-inner fz-14 m-t-20">
                                Claim
                            </div>
                            <div className="nav-item-inner fz-14 m-t-20">
                                Apply as a project
                            </div>
                        </div>
                        <div className="nav-item">
                            <div className="nav-name fz-16 fwb">
                            Support
                            </div>
                            <div className="nav-item-inner fz-14 m-t-56">
                                Docs
                            </div>
                            <div className="nav-item-inner fz-14 m-t-20">
                                Media Kit
                            </div>

                            <div className="nav-item-inner fz-14 m-t-20">
                                Contract us
                            </div>
                        </div>
                    </div>
                </div>
                <div className="other-info p-t-56 p-b-50 flex flex-center flex-between">
                    <span className='cf fz-14 copyright'>
                      Harwell © 2023, All rights reserved
                    </span>
                    <span className='show-p'>
                    {
                        community.map((item, index) => {
                            return <a href={item.link} key={index} target="_blank" className="m-r-14">
                                <img src={item.imgw} alt="" />
                            </a>
                        })
                    }
                    </span>
                    
                </div>
                
            </div>
        </div>
    )
}