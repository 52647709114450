import './Swap.scss'
import LeftLogo from '../../../assets/image/launchpad/swap_left_log.png'
import Coin1 from '../../../assets/image/launchpad/coin-1.png'
import Coin2 from '../../../assets/image/launchpad/coin-2.png'
import Switch from '../../../assets/image/launchpad/switch.svg'
import Line from '../../../assets/image/launchpad/line.svg'
import bottomIcon from '../../../assets/image/launchpad/bottom-icon.svg';
import Column from '../../../assets/image/launchpad/column-icon.svg'
import question from '../../../assets/image/common/question.svg';

import { Modal, Radio, Select, Skeleton, Spin } from 'antd'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import ReactECharts from 'echarts-for-react'
import  * as echarts from 'echarts'
import { reqKlineList } from '../../../api/dex'
import { showK, showLine } from './chart'
import list, { getTokenByName } from './list'
import { decimal, findAddressByName, formatTime, toFixed } from '../../../lib/util'
import { queryTokenPairReserve } from '../../../methods/swap'
import Loading from '../../../components/common/Loading'

function Swap(props) { 
  const optionsData = [
    { label: '1D', value: '1d' },
    { label: '1W', value: '1w' },
    { label: '1M', value: '1m' },
    { label: 'ALL', value: 'all' }
  ]

  const DOUBLE_BTN = [
    { label: 'Max', value: '1' },
    {label: 'Half',value: '1' },
    {label: '75%',value: '1' },
    {label: '25%',value: '1' },
    {label: '12%',value: '1' }
  ]
  const tokenSelect = [
    // {
    //   label: 'BTC/USDC', value: 'BTC/USDC'
    // },
    // {
    //   label: 'ETH/USDC', value: 'ETH/USDC'
    // },
    // {
    //   label: 'CAKE/USDC', value: 'CAKE/USDC'
    // },
    // {
    //   label: 'APT/USDC', value: 'APT/USDC'
    // },
    {
      label: 'HARW/USDC', value: 'HARW/USDC'
    },
    {
      label: 'APT/HARW', value: 'APT/HARW'
    }
  ]
  const [timeValue, setTimeValue] = useState('1d');
  const [ChartType, setChartType] = useState('line')
  let [timeType, setTimeType] = useState(1)

  const [options, setOptions] = useState({})
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [tokenPair, setTokenPair] = useState('HARW/USDC')
  const [priceData, setPriceData] = useState([])
  const [price, setPrice] = useState('')

  const onChange = ({ target: { value } }) => {
    setTimeValue(value);
  };

  const tokenChange = (e) => {
    props.tokenChange(e)
    setTimeType(1)
     setTokenPair(e)
  }

  const getPrice = async (name) => {
    let interval = '5m';
    let timeUnit = 1
    let startTime = Math.floor(new Date(new Date().getTime() - 24*60*60*1000).getTime()/timeUnit).toString()
    const { list:list1 } = (await reqKlineList({
      symbol: name,
      interval,
      closeTime: +new Date(),
      openTime: startTime,
      page: 1,
      pageSize: 200
    })).data.data;
    const { list:list2 } = (await reqKlineList({
      symbol: name,
      interval,
      closeTime: +new Date(),
      openTime: startTime,
      page: 2,
      pageSize: 200
    })).data.data;

    const { list:list3 } = (await reqKlineList({
      symbol: name,
      interval,
      closeTime: +new Date(),
      openTime: startTime,
      page: 3,
      pageSize: 200
    })).data.data;
    let list =[...list1, ...(list2||[]), ...(list3||[])]
    let today = formatTime(new Date().getTime()/1000).split(' ')[0]
    let lastday = formatTime(new Date().getTime()/1000 - 24*60*60).split(' ')[0]
    console.log(new Date(today).getTime(), new Date(lastday).getTime())
    let EightPrice = list.find(item => {
      return item.openTime*1 == (new Date().getTime()*1 < new Date(today).getTime() ? new Date(lastday).getTime():new Date(today).getTime())*1
    })
    console.log(EightPrice)
    setPriceData([EightPrice, list[list.length-1]])
    let {reserve_x, reserve_y}  = await queryTokenPairReserve(findAddressByName('HARW'), findAddressByName('USDC'))
    setPrice(toFixed(reserve_y/reserve_x,  decimal))
  }
  const getChart = async (timeValue, ChartType, name) => {
    let interval = '5m';
    let startTime = '';
    let timeUnit = 1
    setLoading(true)
    // []string{"5m", "15m", "30m", "1h", "2h", "4h", "6h", "8h", "12h", "1d", "3d", "1w", "1M"}
    switch (timeValue) {
      case 1:
        startTime = Math.floor(new Date(new Date().getTime() - 24*60*60*1000).getTime()/timeUnit).toString()
        interval = '5m'
        break;
      case 2:
        startTime = Math.floor(new Date(new Date().getTime() - 7*24*60*60*1000).getTime()/timeUnit).toString();
        interval = '15m'
        break;
      case 3:
        startTime = Math.floor(new Date(new Date().getTime() - 30*24*60*60*1000).getTime()/timeUnit).toString()
        interval = '4h'
        break;
      case 4:
        startTime = '0'
        interval = '1d'
        break;
      case 5:
        startTime = Math.floor(new Date(new Date().getTime() - 250*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '5m'
        break;
      case 6:
        startTime = Math.floor(new Date(new Date().getTime() - 15*50*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '15m'
        break;
      case 7:
        startTime = Math.floor(new Date(new Date().getTime() - 30*50*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '30m'
        break;
      case 8:
        startTime = Math.floor(new Date(new Date().getTime() - 60*50*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '1h'
        break;
      case 9:
        startTime = Math.floor(new Date(new Date().getTime() - 240*50*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '4h'
        break;
      case 10:
        startTime = Math.floor(new Date(new Date().getTime() - 24*60*50*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '1d'
        break;
      case 11:
        startTime = Math.floor(new Date(new Date().getTime() - 7*24*60*50*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '1w'
        break;
      default:
        startTime = Math.floor(new Date(new Date().getTime() - 24*60*60*1000).getTime()/timeUnit).toString()
        // startTime = 0
        interval = '5m'
        break;
    }

    const { list:list1 } = (await reqKlineList({
      symbol: name,
      interval,
      closeTime: +new Date(),
      openTime: startTime,
      page: 1,
      pageSize: 200
    })).data.data;
    const { list:list2 } = (await reqKlineList({
      symbol: name,
      interval,
      closeTime: +new Date(),
      openTime: startTime,
      page: 2,
      pageSize: 200
    })).data.data;

    const { list:list3 } = (await reqKlineList({
      symbol: name,
      interval,
      closeTime: +new Date(),
      openTime: startTime,
      page: 3,
      pageSize: 200
    })).data.data;
    let list =[...list1, ...(list2||[]), ...(list3||[])]
    setList(list)
    // if(timeValue == 1) {
    //   setPriceData([list[0], list[list.length-1]])
    // }

    let xaxis = []
    let yaxis = []
    let ConstList = [];

    ConstList = list || [{
      close: '0',
      open: '0',
      low: '0',
      high: '0'
    }]
    console.log(ConstList)
    ConstList.map((item) => {
      xaxis.push((item.openTime))
      ChartType === 'line' ? yaxis.push(Number(item.close).toFixed(4)) :
        yaxis.push([Number(item.open).toFixed(4), Number(item.close).toFixed(4), Number(item.low).toFixed(4), Number(item.high).toFixed(4)])
    })


    let today = formatTime(new Date().getTime()/1000).split(' ')[0]
    let lastday = formatTime(new Date().getTime()/1000 - 24*60*60).split(' ')[0]
    console.log(new Date(today).getTime(), new Date(lastday).getTime())
    let EightPrice = ConstList.find((item) => {
      return item.openTime*1 == (new Date().getTime()*1 < new Date(today).getTime() ? new Date(lastday).getTime():new Date(today).getTime())*1
    })
    console.log(EightPrice)
    if(!EightPrice?.openTime) {
      EightPrice = ConstList[0]
    }
    setOptions(ChartType === 'line' ?
      showLine(xaxis, yaxis, echarts, timeValue, ConstList[list.length-1].close*1 > EightPrice.open*1 ? 'green':ConstList[list.length-1].close*1 < EightPrice.open*1?'red':'grey', ConstList) :
      showK(xaxis, yaxis, timeValue, ConstList))
    // getOption(close, time)
    setLoading(false)
  }
  useEffect(() => {
    getChart(timeType, ChartType, tokenPair.replace('/', ''))
    getPrice(tokenPair.replace('/', ''))
    let timer = setInterval(() => {
      getPrice(tokenPair.replace('/', ''))
    }, 10000)
    return () => {
      clearInterval(timer)
    }
  }, [timeType, ChartType, tokenPair])

  return (
      <div className="swap-left bgf">
        {/* <div className='swap-left-pro'>
          <span>Pro</span>
          <img className='m-l-4' src={question} alt="" />
        </div> */}
        <Select defaultValue="HARW/USDC" onChange={tokenChange} className='my-select token-select flex flex-middle flex-center' style={{ width: 120 }} suffixIcon={
                          <img src={bottomIcon}></img>
                      } >
                          {
                              tokenSelect.map(item => {
                                  return <Select.Option value={item.value}>
                                      <img className='coin-left' src={getTokenByName(item.label.split('/')[0]).icon} alt="" />
                                      <img className='coin-right' src={getTokenByName(item.label.split('/')[1]).icon} alt="" />
                                      <span className='c2b fz-14 fwb '>{item.label}</span>
                                    </Select.Option>
                              })
                          }
        </Select>
        {/* <div className='swap-left-header flex flex-center pointer'>
          <img className='coin-left' src={getTokenByName(props.inputToken).icon} alt="" />
          <img className='coin-right' src={getTokenByName(props.outToken).icon} alt="" />
          <span className='c2b fz-14 fwb m-l-50'>{props.inputToken}/{props.outToken}</span>
          <img className='switch-icon m-l-12' src={bottomIcon} alt="" />
        </div> */}
        <div className='swap-left-unit flex flex-between flex-center m-t-24'>
          <div className='flex flex-column c2b'>
            <div className='flex flex-center '>
              <div className='flex flex-center title-num'>
                <span className='flex flex-center'>
                  {
                    loading ? <Skeleton.Button active size={'small'} />:
                    <span className='fz-24 fwb'>{price}</span>
                  }
                  <span className='fz-16 fwb m-l-8'>USD</span>
                </span>
                <span>
                {
                  priceData.length ? (
                    <>
                    <span className={'fwb fz-14 price_change m-l-20 ' + 
                        (priceData[1]?.close*1 > priceData[0]?.open*1 ? 'blue': priceData[1]?.close*1 < priceData[0]?.open*1 ?'red':'grey')
                      }>
                          {
                            (priceData[1]?.close*1 >= priceData[0]?.open*1 ? '+': '') +
                            toFixed((priceData[1]?.close - priceData[0]?.open)*100/priceData[0]?.open,2) +'%'
                          }
                      </span>
                      <span className='c05 fz-12 m-l-5'>
                        24h
                      </span>
                    </>
                    
                  ):''
                }
                </span>
                
              </div>
              {/* <span className='c00c fz-16 fwb m-l-8'>+1.22 (+0.36%)</span> */}
            </div>
            {/* <span className='fz-14 c2b'>{loading ? '':formatTime(priceData[1]?.openTime/1000)}</span> */}
            <span className='fz-14 c2b'>{loading ? '':formatTime(new Date().getTime()/1000)}</span>
          </div>
          <div className='flex flex-center k-and-line'>
            {/* <Radio.Group
              className='dex-radio-card'
              options={optionsData}
              onChange={onChange}
              value={timeValue}
              optionType="button"
              buttonStyle="solid"/> */}
              <div className="range-group flex">
                      {
                        ChartType === 'line' ?
                        <>
                          <div className={classNames(["range-item pointer", {"active": timeType === 1}])} onClick={ev => {setTimeType(1)}}>1D</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 2}])} onClick={ev => {setTimeType(2)}}>1W</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 3}])} onClick={ev => {setTimeType(3)}}>1M</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 4}])} onClick={ev => {setTimeType(4)}}>All</div>
                        </>
                        :
                        <>
                          <div className={classNames(["range-item pointer", {"active": timeType === 5}])} onClick={ev => {setTimeType(5)}}>5m</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 6}])} onClick={ev => {setTimeType(6)}}>15m</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 7}])} onClick={ev => {setTimeType(7)}}>30m</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 8}])} onClick={ev => {setTimeType(8)}}>1H</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 9}])} onClick={ev => {setTimeType(9)}}>4H</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 10}])} onClick={ev => {setTimeType(10)}}>1D</div>
                          <div className={classNames(["range-item pointer", {"active": timeType === 11}])} onClick={ev => {setTimeType(11)}}>1W</div>
                        </>
                      }
                    </div>
            <div className='ant-radio-group ant-radio-group-solid dex-radio-card chart-operation'>
              <div onClick={() => {setChartType('line');setTimeType(1)}} className={classNames('ant-radio-button-wrapper chart-btn', {
                'ant-radio-button-wrapper-checked': ChartType === 'line'
              })}>
                <img className='chart-icon' src={Line} alt="" />
              </div>
              <div onClick={() => {setChartType('k');setTimeType(5)}} className={classNames('ant-radio-button-wrapper chart-btn', {
                'ant-radio-button-wrapper-checked': ChartType === 'k'
              })}>
                <img className='chart-icon' src={Column} alt="" />
              </div>
            </div>
          </div>
        </div>
        {
          loading ? <div className="flex flex-center flex-middle" style={{width: '100%',height:'263px'}}>
           <Loading/>
          </div>
          :<ReactECharts className='react-echarts' option={options} style={{width: '100%',height:'263px'}}/>
        }
        {/* <div className='c2b fz-14 lh-16 fwb m-t-36'>O=0.061608  H=0.061608  L=0.061608  C=0.061608</div> */}
        
      </div>
  )
}

export default Swap;