import { NavLink } from 'react-router-dom';
import './collection.scss';
import { useEffect, useState } from 'react';
import {connect as reducxConnect} from 'react-redux'
import { SearchOutlined } from '@ant-design/icons';
import { getTokenByName } from '../Dex/components/list';
import { fromUnit, toFixed } from '../../lib/util';
import { Button, Select} from 'antd';
import Banner from '../../assets/image/marketplace/banner-collection.png'
import Logo from '../../assets/image/marketplace/logo.png'
import star from '../../assets/image/marketplace/star.svg'
import Nft from './components/Nft';
const optionsSelect = [
    {
        label: 'Price: Low to High',
        value: '1'
    },
    {
        label: 'Price: High to Low',
        value: '2'
    }
]
function Collection(props) {
  let [list, setList] = useState([])
  useEffect(async () => {
    let l = await fetch('https://api-v1.topaz.so/api/listing-view-p?collection_id=0xf932dcb9835e681b21d2f411ef99f4f5e577e6ac299eebee2272a39fb348f702%3A%3AAptos%20Monkeys&from=0&to=49&sort_mode=PRICE_LOW_TO_HIGH&buy_now=false&page=0&min_price=undefined&max_price=null&filters={}&search=null')
    let data = (await l.json())
    setList(data.data)
    console.log('////////////')
    console.log(data.data)
  }, [])
  return (
    <div className="collection">
         <img src={Banner} alt="" className='banner'/>
         <div className="collection-content">
            <div className="collection-info p-t-64 ">
                <img src={Logo} alt="" className='logo'/>
                <div className="collection-name">
                   <span className="fz-32 c2b fwb">
                    Aptos Monkeys
                   <img src={require('../../assets/image/marketplace/verify.svg').default} className="m-l-16" />
                   </span>
                </div>
                <div className="c23 fz-16 m-t-10">
                   <span>Create by 0x1234...1234</span> <span className='m-l-20 m-r-20'>·</span><span>5.6% Creator Royalty</span>
                </div>
                <div className="c236 fz-16 m-t-24">
                Ghost Buddy is a collection of 5555 unique NFTs. Each Buddy has their unique vibe and power to match with your personality.
                </div>
                <div className="flex m-t-32">
                    <div className="p-l-42 p-r-42 p-t-12 p-b-12 info-box flex flex-column flex-center m-r-12">
                         <span className="fwb c2b fz-18">403 APT</span>
                         <span className='c236 fz-14'>total volume</span>
                    </div>
                    <div className="p-l-42 p-r-42 p-t-12 p-b-12 info-box flex flex-column flex-center m-r-12">
                         <span className="fwb c2b fz-18">0.145 APT</span>
                         <span className='c236 fz-14'>volume (24hours)</span>
                    </div>
                    <div className="p-l-42 p-r-42 p-t-12 p-b-12 info-box flex flex-column flex-center m-r-12">
                         <span className="fwb c2b fz-18">345.6 APT</span>
                         <span className='c236 fz-14'>best offer</span>
                    </div>
                    <div className="p-l-42 p-r-42 p-t-12 p-b-12 info-box flex flex-column flex-center m-r-12">
                         <span className="fwb c2b fz-18">13%</span>
                         <span className='c236 fz-14'>listed</span>
                    </div>
                    <div className="p-l-42 p-r-42 p-t-12 p-b-12 info-box flex flex-column flex-center m-r-12">
                         <span className="fwb c2b fz-18">1,832</span>
                         <span className='c236 fz-14'>owners</span>
                    </div>
                </div>
            </div>
            <div className="search-area flex m-t-24">
                <div className="search-input flex flex-center flex-1 m-r-16">
                    <input type="text" className='flex-1' placeholder='Search'/>
                    <div className='p-r-13'>
                        <SearchOutlined style={{fontSize: 20}}/>
                    </div>
                </div>

                <div className="temp-btn flex flex-center flex-middle m-r-16 c2b fwb pointer">
                    Price Range
                </div>
                <div className="temp-btn flex flex-center flex-middle m-r-16 c2b fwb pointer">
                    <img src={star} alt="" className='m-r-5'/>
                    Collection Offer
                </div>

                <Select defaultValue="1" className='my-select flex flex-middle flex-center' style={{ width: 120 }} suffixIcon={
                          <img src={require('../../assets/image/common/down.svg').default}></img>
                      } >
                          {
                              optionsSelect.map(item => {
                                  return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                              })
                          }
                </Select>

            </div>
            <div className="flex flex-wrap gap-30 m-t-36 flex-start">
                {
                    list.map(item => {
                        return <Nft key={item.token_id} info={item}/>
                    })
                }

            </div>
         </div>
        
    </div>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Collection
);