import '../index.scss';
import { Button, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { getTokenByName } from '../../Dex/components/list';
import { getLpToken } from '../../../methods/farm';
import { findAddressByName, fromUnit, OpenNotification, testInput, toFixed, toUnit } from '../../../lib/util';
import { explorerUrl, getAddress, getNodeUrl } from '../../../contract';
import { useSubmitTransiction } from '../../../methods/submit';
import { getBalance } from '../../../methods/client.ts';
import Activenumber from '../../../components/common/Activenumber';
import farmlist from '../../../contract/farmlist';
import { getPendingReward } from '../../../methods/farm';
import useInterval from '@use-it/interval';
import { refresh_interval } from '../../../global';
import { getAvailabePoints, queryDepositAmount } from '../../../methods/unlocker';
const decimal = 6

const Reward = (props) => {
   const [bal, setBal] = useState(0)
   const [unlockInfo, setUnlockerInfo] = useState(0)
   const [inputValue, setInputValue] = useState('')
   const [pendingReward, setPendingReward] = useState(0)
   const [activePercent, setActivePercent] = useState(0)
   const [loading, setLoading] = useState(false)
   const [loadingData, setLoadingData] = useState(false)
   const [balanceLoading, setBalanceLoading] = useState(false)
   const [balanceRefresh, setBalanceRefresh] = useState(1)
   const [refreshTrigger, setRefreshTrigger] = useState(1)
   const [showStake, setShowStake] = useState(false)
   const [showUnStake, setShowUnStake] = useState(false)
   const { submitTransiction } = useSubmitTransiction()
   const toPercent = (percent) => {
      setActivePercent(percent)
      console.log(unlockInfo.deposit_amount)
      if(showStake?bal>0:unlockInfo.deposit_amount>0) {
        setInputValue(toFixed((showStake?bal:fromUnit(unlockInfo.deposit_amount))*percent/100,4))
      }
    }
   const toClaim = async () => {
      setLoading(true)
      let payload = {
         type: "script_function_payload",
         function: `${getAddress().farm.flexible.address}::harvest_script`,
         type_arguments: [findAddressByName('HARW'), findAddressByName('HARW'), findAddressByName('HARW')],
         arguments: [],
      };
      submitTransiction(payload, () => {
         setLoading(false)
         setInputValue('')
         setBalanceRefresh(balanceRefresh + 1)
         props.onSuccess()
      }, () => {
         setLoading(false)
      })
   }
   const toDeposit = async () => {
      setLoading(true)
      let payload = {
         type: "script_function_payload",
         function: `${getAddress().farm.unlocker.address}::deposit_script`,
         type_arguments: [getLpToken(findAddressByName('HARW'), findAddressByName('USDC'))],
         arguments: [toUnit(inputValue)],
      };
      submitTransiction(payload, () => {
         setLoading(false)
         setInputValue('')
         setBalanceRefresh(balanceRefresh + 1)
         props.onSuccess()
      }, () => {
         setLoading(false)
      })
   }
   const toUnstake = async () => {
      setLoading(true)
      let payload = {
         type: "script_function_payload",
         function: `${getAddress().farm.unlocker.address}::withdraw_script`,
         type_arguments: [getLpToken(findAddressByName('HARW'), findAddressByName('USDC'))],
         arguments: [toUnit(inputValue)],
      };
      submitTransiction(payload, () => {
         setLoading(false)
         setInputValue('')
         setBalanceRefresh(balanceRefresh + 1)
         props.onSuccess()
      }, () => {
         setLoading(false)
      })
   }
   useEffect(async () => {
      if (props.account) {
         setLoadingData(true)
         // 获取总质押奖励数量
         let pending_p = []
         farmlist.map(item => {
            let depositToken = item.lptype == 'LPToken' ? (getLpToken(findAddressByName(item.token1), findAddressByName(item.token2))) : findAddressByName(item.token)
            item.type == 'flexible' && item.lptype == 'single' && pending_p.push(getPendingReward(props.account, depositToken, findAddressByName(item.rewardToken)))
         })
         let result = await Promise.all(pending_p)
         if(result.length == 0) {
            setPendingReward(0)
         } else {
            setPendingReward(result.reduce((old, now) => {
               return old * 1 + now * 1
            }, 0))
         }
         

         let unlocker_info = await getAvailabePoints(props.account)
         setUnlockerInfo(unlocker_info)
         setLoadingData(false)
      } else {
         setPendingReward(0)
      }

   }, [props.info, props.account, refreshTrigger])
   useEffect(async () => {
      if (props.account) {
         setBalanceLoading(true)
         let bal = await getBalance(props.account, getLpToken(findAddressByName('HARW'), findAddressByName('USDC')))
         setBal(toFixed(fromUnit(bal), 4))
         setBalanceLoading(false)
      }
   }, [props.info, props.account, balanceRefresh])
   useInterval(() => {
      setRefreshTrigger(refreshTrigger + 1);
   }, refresh_interval);
   return (
      <div className='w100 reward-modal p-t-24'>
         <div className="fz-12 fwb c2b ta">Reward Amount</div>
         <div className="fz-24 fwb c2b ta m-t-5"><Activenumber value={fromUnit(pendingReward)} decimals={4} /> {props.info.rewardToken}</div>
         <div className="staked w100 p-t-18 p-b-18 p-l-16 p-r-16 m-t-24">
            <div className="flex flex-between flex-center">
               <span className="c236 fz-14">Staked</span>
               <span className='fz-14 fwb c2b'>
                  <img src={getTokenByName('HARW').icon} alt="" className='token-icon' />
                  <img src={getTokenByName('USDC').icon} alt="" className='token-icon right-icon' />
                  <span className='m-l-5'>
                  <Activenumber value={fromUnit(unlockInfo.deposit_amount)} decimals={4} /> HARW-USDC LP
                  </span>
               </span>
            </div>
            <div className="flex flex-between flex-center m-t-20">
               <span className="c236 fz-14">Unlock Speed</span>
               <span className='fz-14 fwb c2b'>
                  {(unlockInfo.unlock_speed)} HARW/block
               </span>
            </div>
            <div className="btns flex gap-20 m-t-20">
               <Button className="unstake-btn btn  flex-1" loading={loading} onClick={()=>{setShowStake(false);setShowUnStake(true);setInputValue('')}}>
                  <span className='color-font'>Unstake</span>
               </Button>
               <Button className="stake-btn color btn flex-1 fwb c2b" loading={loading} onClick={()=>{setShowUnStake(false);setShowStake(true);setInputValue('')}}>Stake</Button>
            </div>
            {
               showStake && (
                  <>
                     <div className="flex flex-between m-t-28">
                        <span className='fz-14 c236'>
                           <img src={getTokenByName('HARW').icon} alt="" className='token-icon' />
                           <img src={getTokenByName('USDC').icon} alt="" className='token-icon right-icon' />
                           <span className='m-l-5'>HARW-USDC LP</span>
                        </span>
                        <span className="fz-14 c236">Balance: {balanceLoading ? <Skeleton.Button active size={'small'} /> : (bal || '0')}</span>
                     </div>
                     <div className="bgf w100 p-l-16 p-r-16 p-t-8 p-b-8 bdr-16 m-t-12">
                        <div className="w100 flex flex-center fwb">
                           <input type="text" placeholder='0' className='flex-1 com_input p-8 fz-20' value={inputValue} onChange={
                              (e) => {
                                 if (testInput(e.target.value)) {
                                 return
                                 }
                                 setActivePercent(0)
                                 setInputValue(e.target.value)
                              }
                           }/>
                           <span className='fz-14'>LP</span>
                        </div>
                        <div className="flex flex-last">
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 25 ? 'active':'')} onClick={()=>toPercent(25)}>25%</span>
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 50 ? 'active':'')} onClick={()=>toPercent(50)}>50%</span>
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 75 ? 'active':'')} onClick={()=>toPercent(75)}>75%</span>
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 100 ? 'active':'')} onClick={()=>toPercent(100)}>MAX</span>
                        </div>
                     </div>
                     <div className="btns flex gap-20 m-t-20">
                        <Button className="stake-btn color btn flex-1 fwb c2b" loading={loading} disabled={inputValue <=0 || inputValue > bal*1} onClick={toDeposit}>Confirm Stake</Button>
                     </div></>
               )
            }
            {
               showUnStake && (
                  <>
                     <div className="flex flex-between m-t-28">
                        <span className='fz-14 c236'>
                           <img src={getTokenByName('HARW').icon} alt="" className='token-icon' />
                           <img src={getTokenByName('USDC').icon} alt="" className='token-icon right-icon' />
                           <span className='m-l-5'>HARW-USDC LP</span>
                        </span>
                        <span className="fz-14 c236">Staked: {balanceLoading ? <Skeleton.Button active size={'small'} /> : (fromUnit(unlockInfo.deposit_amount) || '0')}</span>
                     </div>
                     <div className="bgf w100 p-l-16 p-r-16 p-t-8 p-b-8 bdr-16 m-t-12">
                        <div className="w100 flex flex-center fwb">
                        <input type="text" placeholder='0' className='flex-1 com_input p-8 fz-20' value={inputValue} onChange={
                              (e) => {
                                 if (testInput(e.target.value)) {
                                 return
                                 }
                                 setActivePercent(0)
                                 setInputValue(e.target.value)
                              }
                           }/>
                           <span className='fz-14'>LP</span>
                        </div>
                        <div className="flex flex-last">
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 25 ? 'active':'')} onClick={()=>toPercent(25)}>25%</span>
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 50 ? 'active':'')} onClick={()=>toPercent(50)}>50%</span>
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 75 ? 'active':'')} onClick={()=>toPercent(75)}>75%</span>
                           <span className={'pointer fz-12 c236 m-l-16 '+ (activePercent == 100 ? 'active':'')} onClick={()=>toPercent(100)}>MAX</span>
                        </div>
                     </div>
                     <div className="btns flex gap-20 m-t-20">
                        <Button className="stake-btn color btn flex-1 fwb c2b" loading={loading} disabled={inputValue <=0 || inputValue > fromUnit(unlockInfo.deposit_amount)*1} onClick={toUnstake}>Confirm Unstake</Button>
                     </div></>
               )
            }




         </div>
         <div className="info-item flex flex-between w100 m-t-24">
            <span className="fz-13 c236">Unlocked HARW</span>
            <span className="c2b fwb">
               {
                  loadingData ? <Skeleton.Button active size={'small'} /> : 
                  Number(unlockInfo.points) > Number(pendingReward) ? fromUnit(pendingReward||0):fromUnit(unlockInfo.points)
               } HARW
               <span className={"color-font fz-14 fwb m-l-12 pointer "+ (unlockInfo.points < 0 ? 'disabled':'')} onClick={toClaim}>Claim</span>
            </span>
         </div>
         <div className="info-item flex flex-between w100 m-t-5">
            <span className="fz-13 c236">Locked HARW</span>
            <span className="c2b fwb">
            <Activenumber value={Number(unlockInfo.points) > Number(pendingReward) ?0:fromUnit(pendingReward-unlockInfo.points)} decimals={4} /> HARW
            </span>
         </div>

      </div>
   )
}


export default Reward